<template>
  <woot-modal :show="showKanbanModal" :on-close="() => $emit('close-kanban-modal')" class="kanban-selector--modal">
    <woot-modal-header :header-title="$t('CRM_MGMT.SELECTOR.HEADER')"
      :header-content="$t('CRM_MGMT.SELECTOR.DESC')" />
    <input 
      type="text" 
      ref="refSearchInput"           
      :class="{ 'focused': isFocused, 'blur': !isFocused , 'kanban--search': true}" 
      :placeholder="$t('CRM_MGMT.SELECTOR.SEARCH')"
      :value="searchQuery" 
      @input="debounceSearch"
      @focus="handleFocusSearch"
      @blur="handleBlurSearch"
    >
    
    <div class="account-selector--wrap">
      <div v-for="kanban in kanbanData" :key="kanban.id" class="account-selector">
        <button class="button expanded clear link" @click="onChangeKanban(kanban.id)">
          <span class="button__content">
            <label :for="kanban.name" class="account-details--wrap">
              <div class="account--name">{{ kanban.name }}</div>
            </label>
          </span>
          <fluent-icon v-show="kanban.id === kanbanSelect.id" class="selected--account" icon="checkmark-circle" type="solid"
            size="24" />
        </button>
      </div>
    </div>

    <div class="modal-footer delete-item">
      <button class="button success large expanded nice" @click="redirectCrm">
        {{ $t('CRM_MGMT.SELECTOR.NEW_KANBAN') }}
      </button>
    </div>
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'KanbanSelector',
  props: {
    kanbanSelect: {
      type: Object,
      default: () => {},
    },
    kanbanList: {
      type: Array,
      default: () => [],
    },
    showKanbanModal: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      searchQuery: '',
      kanbanData: this.kanbanList,
      isFocused: false 
    }
  },

  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      globalConfig: 'globalConfig/get',
      currentAccountsUser: 'getUserAccounts',
    }),
  },

  methods: {
    onChangeKanban(id) {
      this.$emit('set-kanban', id)
    },

    redirectCrm(){
      const accountUrl = `/app/accounts/${this.accountId}/settings/crm`;
      window.location.href = accountUrl;
    },
    searchAccountByName(term) {
      return this.kanbanList?.filter((item) => {
        const valueFormated = item?.name?.toUpperCase();
        return valueFormated.includes(term);
      });
    },

    debounceSearch(e) {
      const valuesFilter = e.target.value.toUpperCase();
      this.searchQuery = e.target.value;
      this.kanbanData = this.searchAccountByName(valuesFilter);
    },

    handleFocusSearch() {
      this.isFocused = true;
    },

    handleBlurSearch() {
      this.isFocused = false;
    }
  },
};

</script>

<style lang="scss" scoped>
.kanban--search {
  width: 80%;
  margin: 20px auto;
}

.account-selector--wrap {
  margin-top: var(--space-normal);
}

.focused {
  border-color: var(--g-20);
}

.blur {
  color: var(--blur)!important;
}
.blur::placeholder {
  color: var(--blur)!important;
}

input:focus {
  box-shadow: none !important;
}

.account-selector {
  padding-top: 0;
  padding-bottom: 0;

  .button {
    display: flex;
    justify-content: space-between;
    padding: var(--space-one) var(--space-normal);

    .account-details--wrap {
      text-align: left;

      .account--name {
        cursor: pointer;
        font-size: var(--font-size-medium);
        font-weight: var(--font-weight-medium);
        line-height: 1;
      }

      .account--role {
        cursor: pointer;
        font-size: var(--font-size-mini);
        text-transform: capitalize;
      }
    }
  }
}
</style>
