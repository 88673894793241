<template>
    <main class="main">
    <iframe 
        src="https://interativa.gitbook.io/omnidesk/" 
        style="border:0px #ffffff none;" 
        name="myiFrame" 
        frameborder="1" 
        marginheight="0px" 
        marginwidth="0px" 
        height="100%" 
        width="100%" 
        allowfullscreen
    >
  </iframe>
    </main>
</template>
  
<script>
import { frontendURL } from '../../helper/URLHelper';
import auth from '../../api/auth';

    export default {
        name: "Manual",
        methods: {
            validateSession(){
                setInterval(() =>{
                    const session = localStorage.getItem("session");
                    if(!session){
                        auth.logout();
                        window.location = frontendURL("login");
                    }
                },10000)
            }
        },
        mounted(){
            this.validateSession();
        }
        
    };
</script>
  
<style scoped>

* {
    margin: 0;
    padding: 0;
}
.main {
    height: 100%;
    width: 100%;
}

</style>