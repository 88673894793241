<template>
  <div class="card">
    <div class="card-header">
      <slot name="header">
        <div class="card-header--title-area">
          <select @change="handleSelectChange">
            <option value="inbox">
              {{ this.$t('DASHBOARD.INBOX.INBOX') }}
            </option>
            <option value="team">{{ this.$t('DASHBOARD.TEAM') }}</option>
          </select>
        </div>
        <slot name="control" />
      </slot>
    </div>
    <report-filters
      :type="type"
      :filter-items-list="filterItemsList"
      :group-by-filter-items-list="groupByfilterItemsList"
      :selected-group-by-filter="selectedGroupByFilter"
      @date-range-change="onDateRangeChange"
      @filter-change="onFilterChange"
      @group-by-filter-change="onGroupByFilterChange"
      @business-hours-toggle="onBusinessHoursToggle"
    />
    <div v-if="!isLoading" class="card-body row">
      <slot />
    </div>
    <div v-else-if="isLoading" class="conversation-metric-loader">
      <spinner />
      <span>{{ loadingMessage }}</span>
    </div>

    <div id="chart"></div>
  </div>
</template>

<script>
import Spinner from 'shared/components/Spinner.vue';
import ReportFilters from './ReportFilters.vue';
import { GROUP_BY_FILTER, METRIC_CHART } from '../constants';
import { REPORTS_EVENTS } from '../../../../helper/AnalyticsHelper/events';
import reportMixin from '../../../../mixins/reportMixin';
import { mapGetters } from 'vuex';

import fromUnixTime from 'date-fns/fromUnixTime';
import format from 'date-fns/format';

const REPORTS_KEYS = {
  CONVERSATIONS: 'conversations_count',
  INCOMING_MESSAGES: 'incoming_messages_count',
  OUTGOING_MESSAGES: 'outgoing_messages_count',
  FIRST_RESPONSE_TIME: 'avg_first_response_time',
  RESOLUTION_TIME: 'avg_resolution_time',
  RESOLUTION_COUNT: 'resolutions_count',
  HANDLING_TIME: 'avg_handling_time',
};

export default {
  name: 'MetricCardInbox',
  components: {
    Spinner,
    ReportFilters,
  },
  mixins: [reportMixin],
  props: {
    header: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    loadingMessage: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'inbox',
    },
    getterKey: {
      type: String,
      default: '',
    },
    actionKey: {
      type: String,
      default: '',
    },
    selectHandler: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      from: 0,
      to: 0,
      currentSelection: 0,
      showGraphy: false,
      a: false,
      selectedFilter: null,
      groupBy: GROUP_BY_FILTER[1],
      groupByfilterItemsList: this.$t('REPORT.GROUP_BY_DAY_OPTIONS'),
      selectedGroupByFilter: null,
      businessHours: false,
    };
  },
  computed: {
    ...mapGetters({
      accountReport: 'getAccountReports',
    }),
    filterItemsList() {
      return this.$store.getters[this.getterKey] || [];
    },
    accountSummary() {
      return this.$store.getters.getAccountInboxSummary || [];
    },

    metrics() {
      let reportKeys = ['CONVERSATIONS'];
      if (this.type !== 'agent') {
        reportKeys.push('INCOMING_MESSAGES');
      }
      reportKeys = [
        ...reportKeys,
        'OUTGOING_MESSAGES',
        'FIRST_RESPONSE_TIME',
        'HANDLING_TIME',
        'RESOLUTION_TIME',
        'RESOLUTION_COUNT',
      ];
      const infoText = {
        FIRST_RESPONSE_TIME: this.$t(
          `REPORT.METRICS.FIRST_RESPONSE_TIME.INFO_TEXT`
        ),
        RESOLUTION_TIME: this.$t(`REPORT.METRICS.RESOLUTION_TIME.INFO_TEXT`),
        HANDLING_TIME: this.$t(`REPORT.METRICS.HANDLING_TIME.INFO_TEXT`),
      };
      return reportKeys.map(key => ({
        NAME: this.$t(`REPORT.METRICS.${key}.NAME`),
        KEY: REPORTS_KEYS[key],
        DESC: this.$t(`REPORT.METRICS.${key}.DESC`),
        INFO_TEXT: infoText[key],
        TOOLTIP_TEXT: `REPORT.METRICS.${key}.TOOLTIP_TEXT`,
      }));
    },
  },
  watch: {
    accountReport: {
      deep: true,
      handler(newAccountReport) {
        if (newAccountReport.data) {
          this.collection(newAccountReport.data, newAccountReport.isFetching);
        }
      },
    },
  },

  mounted() {
    this.$store.dispatch(this.actionKey);
  },

  methods: {
    collection(listReport, validate) {
      if (validate) {
        return {};
      }
      if (!listReport.length) return {};

      const labels = listReport?.map(element => {
        if (this.groupBy.period === GROUP_BY_FILTER[2].period) {
          let week_date = new Date(fromUnixTime(element.timestamp));
          const first_day = week_date.getDate() - week_date.getDay();
          const last_day = first_day + 6;

          const week_first_date = new Date(week_date.setDate(first_day));
          const week_last_date = new Date(week_date.setDate(last_day));

          return `${format(week_first_date, 'dd/MM/yy')} - ${format(
            week_last_date,
            'dd/MM/yy'
          )}`;
        }
        if (this.groupBy.period === GROUP_BY_FILTER[3].period) {
          return format(fromUnixTime(element.timestamp), 'MMM-yyyy');
        }
        if (this.groupBy.period === GROUP_BY_FILTER[4].period) {
          return format(fromUnixTime(element.timestamp), 'yyyy');
        }
        return format(fromUnixTime(element.timestamp), 'dd-MMM-yyyy');
      });

      const datasets = METRIC_CHART[
        this.metrics[this.currentSelection].KEY
      ].datasets.map(dataset => {
        switch (dataset.type) {
          case 'bar':
            return {
              ...dataset,
              yAxisID: 'y-left',
              label: this.metrics[this.currentSelection].NAME,
              data: listReport?.map(element => element.value),
            };
          case 'line':
            return {
              ...dataset,
              yAxisID: 'y-right',
              label: this.metrics[0].NAME,
              data: listReport?.map(element => element.count),
            };
          default:
            return dataset;
        }
      });

      const newData = datasets[0]?.data;
      const options = {
        chart: {
          type: 'area',
          height: 300,
          width: '100%',
        },
        series: [
          {
            name: this.selectedFilter?.name,
            data: newData,
          },
        ],
        xaxis: {
          categories: labels,
        },
      };

      const chart = this.getCharts(options);

      chart.render();
      chart.updateSeries([
        {
          data: newData,
          name: this.selectedFilter?.name,
        },
      ]);

      chart.updateOptions({
        xaxis: {
          categories: labels,
        },
      });

      return {};
    },
    getCharts(options) {
      const chartElement = document.getElementById('chart');
      if (chartElement) {
        const chart = new ApexCharts(chartElement, options);
        return chart;
      }
      return;
    },

    fetchAllData() {
      if (this.selectedFilter) {
        const { from, to, groupBy, businessHours } = this;
        this.$store.dispatch('fetchAccountSummary', {
          from,
          to,
          type: this.type,
          id: this.selectedFilter.id,
          groupBy: groupBy.period,
          businessHours,
          route: true,
        });
        this.fetchChartData();
      }
    },
    fetchChartData() {
      const { from, to, groupBy, businessHours } = this;
      this.$store.dispatch('fetchAccountReport', {
        metric: 'conversations_count',
        from,
        to,
        type: this.type,
        id: this.selectedFilter.id,
        groupBy: groupBy.period,
        businessHours,
      });
    },
    handleSelectChange(event) {
      const selectedValue = event.target.value;
      this.selectHandler(selectedValue);
    },
    changeSelection(index) {
      this.currentSelection = index;
    },
    onDateRangeChange({ from, to, groupBy }) {
      // do not track filter change on inital load
      if (this.from !== 0 && this.to !== 0) {
        this.$track(REPORTS_EVENTS.FILTER_REPORT, {
          filterType: 'date',
          reportType: this.type,
        });
      }

      this.from = from;
      this.to = to;
      this.groupByfilterItemsList = this.fetchFilterItems(groupBy);
      const filterItems = this.groupByfilterItemsList.filter(
        item => item.id === this.groupBy.id
      );
      if (filterItems.length > 0) {
        this.selectedGroupByFilter = filterItems[0];
      } else {
        this.selectedGroupByFilter = this.groupByfilterItemsList[0];
        this.groupBy = GROUP_BY_FILTER[this.selectedGroupByFilter.id];
      }
      this.fetchAllData();
    },
    onFilterChange(payload) {
      if (payload) {
        this.selectedFilter = payload;
        this.fetchAllData();
      }
    },
    onGroupByFilterChange(payload) {
      this.groupBy = GROUP_BY_FILTER[payload.id];
      this.fetchAllData();

      this.$track(REPORTS_EVENTS.FILTER_REPORT, {
        filterType: 'groupBy',
        filterValue: this.groupBy?.period,
        reportType: this.type,
      });
    },
    fetchFilterItems(groupBy) {
      switch (groupBy) {
        case GROUP_BY_FILTER[2].period:
          return this.$t('REPORT.GROUP_BY_WEEK_OPTIONS');
        case GROUP_BY_FILTER[3].period:
          return this.$t('REPORT.GROUP_BY_MONTH_OPTIONS');
        case GROUP_BY_FILTER[4].period:
          return this.$t('REPORT.GROUP_BY_YEAR_OPTIONS');
        default:
          return this.$t('REPORT.GROUP_BY_DAY_OPTIONS');
      }
    },
    onBusinessHoursToggle(value) {
      this.businessHours = value;
      this.fetchAllData();

      this.$track(REPORTS_EVENTS.FILTER_REPORT, {
        filterType: 'businessHours',
        filterValue: value,
        reportType: this.type,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  margin: var(--space-small) !important;
  overflow: visible;

  .card-header--control-area {
    opacity: 0.2;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover {
    .card-header--control-area {
      opacity: 1;
    }
  }
}

.card-header {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(max-content, 50%));
  gap: var(--space-small) 0px;
  flex-grow: 1;
  width: 100%;
  margin-bottom: var(--space-medium);

  .card-header--title-area {
    display: flex;
    flex-direction: row;
    align-items: center;

    h5 {
      margin-bottom: var(--zero);
    }

    select {
      width: 50%;
      font-weight: 500;
      font-size: 2rem;
      margin: 0;
    }

    select option {
      font-size: 18px;
    }

    .live {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: var(--space-small);
      padding-left: var(--space-small);
      margin: var(--space-smaller);
      background: rgba(37, 211, 102, 0.1);
      color: var(--g-400);
      font-size: var(--font-size-mini);

      .ellipse {
        background-color: var(--g-400);
        height: var(--space-smaller);
        width: var(--space-smaller);
        border-radius: var(--border-radius-rounded);
        margin-right: var(--space-smaller);
      }
    }
  }

  .card-header--control-area {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    gap: var(--space-small);
  }
}

.card-body {
  gap: 10px;
  margin-bottom: 15px;
  .metric-content {
    padding-bottom: var(--space-small);
    .heading {
      font-size: 3rem;
      text-align: center;
    }
    .metric {
      color: var(--w-800);
      text-align: center;
      font-size: 1.3rem;
      margin-bottom: var(--zero);
      margin-top: var(--space-smaller);
    }
  }
}

.conversation-metric-loader {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-large);
}
</style>
