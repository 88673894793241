<template>
  <div style="height: 100%">
    <div class="container-fluid main-container scrollable-div">
      <div class="board-wrapper">
        <TaskList
          v-for="(listItem, index) in lists"
          :key="index"
          :index="index"
          :board="getBoard"
          :kanban-id="kanbanId"
          :list="listItem"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TaskList from "./TaskList";

export default {
  name: "TaskBoard",
  props: ["columns", "kanbanId"],
  components: {
    TaskList,
  },
  computed: {
    getDragOptions() {
      return {
        animation: "200",
        ghostClass: "ghost",
        handle: ".board-header",
        group: "kanban-board-lists",
      };
    },
    getBoard() {
      return this.columns.sort((a, b) => a.column.index - b.column.index);
    },
    lists() {
      return this.getBoard;
    }
  },
  methods: {
  }
};
</script>

<style>

.draggable-container {
  display: flex;
}
.board-details .project-name {
  display: flex;
  align-items: center;
}
.board-details .project-name:hover .name-edit-icon {
  display: block;
}
.board-details .name-edit-icon {
  display: none;
  width: 50px;
  text-align: center;
  cursor: pointer;
}
.board-details .project-name-input,
.board-details .project-desc-input {
  padding: 0;
  font-size: 24px;
  color: #525f7f;
  border: 1px solid transparent;
  background: transparent;
  width: 50%;
  padding-left: 10px;
}
.board-details .project-name-input:hover,
.board-details .project-desc-input:hover {
  border: 1px solid #cad1d7;
}
.board-details .project-name-input:focus,
.board-details .project-desc-input:focus {
  border: 1px solid #98a8fb;
}
.board-details .project-desc-input {
  font-size: 15px;
  height: 30px;
}


ul, li {
  list-style: none;
  padding: 0;
}
.main-container {
    padding-bottom: 0;
    background: #eef2f9;
    min-height: 100%;
}


.search-task{
    width: 30%;
    background: none;
    border: none;
    color: #fff;
}
.search-task:focus{background: none;color: #fff}
.search-task.form-control::-webkit-input-placeholder { color: white; }
.bg-gradient-primary-custom {
    background: linear-gradient(89deg, #98a8fb 0, #71b3f8 100%)!important
}

.board-wrapper {
    display: flex;
    height: 100%;
}

.board-wrapper .task-board {
    background: #f6f8fc;
    height: auto;
    margin: 15px;
    padding: 15px;
    width: 385px;
    position: relative;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(134, 134, 162, 0.21);
    -moz-box-shadow: 0px 0px 4px 0px rgba(134, 134, 162, 0.21);
    box-shadow: 0px 0px 4px 0px rgba(134, 134, 162, 0.21);
}

.task-board:before {
    content: '';
    width: 100%;
    height: 4px;
    background: linear-gradient(89deg, #c875e8 0, #dd6883 100%);
    position: absolute;
    margin-left: -15px;
    border-radius: 5px;
    top: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.task-board.todo-board:before {
    background: linear-gradient(89deg, #c875e8 0, #dd6883 100%);
}

.task-board.inprogress-board:before {
    background: linear-gradient(89deg, #feb143 0, #fe9279 100%);
}

.task-board.review-board:before {
    background: linear-gradient(89deg, #8a92cc 0, #66aef7 100%);
}

.task-board.done-board:before {
    background: linear-gradient(89deg, #75d2a8 0, #78d2ea 100%);
}

.task-board[data-board-name="primary"]:before, .task-board[data-board-name="4"]:before {
    background: linear-gradient(89deg, #c875e8 0, #dd6883 100%);
}

.task-board[data-board-name="secondary"]:before, .task-board[data-board-name="5"]:before  {
    background: linear-gradient(89deg, #feb143 0, #fe9279 100%);
}

.task-board[data-board-name="tertiary"]:before, .task-board[data-board-name="6"]:before  {
    background: linear-gradient(89deg, #8a92cc 0, #66aef7 100%);
}

.task-board[data-board-name="quaternary"]:before, .task-board[data-board-name="7"]:before  {
    background: linear-gradient(89deg, #75d2a8 0, #78d2ea 100%);
}

.board-header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    margin-bottom: 12px;
}

.board-header .board-name {
    font-size: 18px;
    font-weight: 600;
}

.task-item {
    background: #fff;
    padding: 12px;
    border-radius: 5px;
    margin-bottom: 18px;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(134, 134, 162, 0.31);
    -moz-box-shadow: 0px 0px 4px 0px rgba(134, 134, 162, 0.31);
    box-shadow: 0px 0px 4px 0px rgba(134, 134, 162, 0.31);
}

.task-item-header {
  margin-bottom: 12px;
  align-items: center;
  display: flex;
  gap: 1rem;
}
.task-item-header p {
 margin: 0;
 font-size: 1.6rem;
 font-weight: 500;
}

.task-item-body .task-priority {
    display: inline;
    color: #fff;
    font-size: 13px;
    padding: 3px 18px;
    border-radius: 3px;
    font-weight: 600;
    -webkit-box-shadow: 0px 0px 5px -2px rgba(134, 134, 162, 1);
    -moz-box-shadow: 0px 0px 5px -2px rgba(134, 134, 162, 1);
    box-shadow: 0px 0px 5px -2px rgba(134, 134, 162, 1);
}

.task-priority.low {
    background: linear-gradient(89deg, #66a7f8 0, #8ea7fb 100%);
}

.task-priority.medium {
    background: linear-gradient(89deg, #79d3b9 0, #81d5de 100%);
}

.task-priority.high {
    background: linear-gradient(89deg, #cd74d1 0, #db78a3 100%);
}

.task-item-body {
    margin-bottom: 25px;
}

.task-item-body .task-title {
    color: #8686a2;
    font-weight: bold;
}

.task-item-footer, .assigned-users, .comments-attachments {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.comments-attachments {
    color: #8686a2;
    font-size: 12px;
}

.comments-attachments i {
    font-size: 12px;
    margin-right: 5px;
}

.comments {
    margin-right: 15px;
}

.user-avatar img {
    border-radius: 50%;
    width: 30px;
}

.assigned-users .add-icon {
    height: 30px;
    width: 30px;
    background: transparent;
    border: 1px solid #8686a2;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    font-weight: 300;
    margin-right: 10px;
    color: #8686a2;
}

.board-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}

.board-footer .add-task-btn {
    color: #8686a2;
    cursor: pointer;
}
.scrollable-div{
    overflow-x: scroll;
}
.card-text{
    color: #525f7f;
    font-weight: 400;
}

.task-item-body .task-title:hover {
    text-decoration: underline;
    cursor: pointer;
}

</style>
