<template>
  <div class="container">
    <div class="main" v-if="!post.link">
      <div class="box box-text" v-if="post.type == 'text'">
        <h4>{{ post.title }}</h4>
        <p v-if="post.description">{{ post.description }}</p>
      </div>
      <div class=" box box-img" v-if="post.type == 'image' || post.type == 'gif'">
        <h4>{{ post.title }}</h4>
        <img :src="post.file_url" />
        <p v-if="post.description">{{ post.description }}</p>
      </div>
      <div class="box box-video video" v-if="post.type == 'video'">
        <h4>{{ post.title }}</h4>
        <video
          :src="post.file_url"
          controls
          playsInline
          class="modal-video skip-context-menu"
        />
        <p v-if="post.description">{{ post.description }}</p>
      </div>
    </div>
    <a class="main" target="_blank" v-if="post.link" :href="post.link">
      <div class="box box-text" v-if="post.type == 'text'">
        <h4>{{ post.title }}</h4>
        <p v-if="post.description">{{ post.description }}</p>
      </div>
      <div class=" box box-img" v-if="post.type == 'image' || post.type == 'gif'">
        <h4>{{ post.title }}</h4>
        <img :src="post.file_url" />
        <p v-if="post.description">{{ post.description }}</p>
      </div>
      <div class="box box-video" v-if="post.type == 'video'">
        <h4>{{ post.title }}</h4>
        <video
          :src="post.file_url"
          controls
          playsInline
          class="modal-video skip-context-menu"
        />
        <p v-if="post.description">{{ post.description }}</p>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'Post',
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  gap: 1rem;
}

.main {
  width: 100%;
}
.box {
  padding:var(--space-normal);
  width: 100%;
  border-radius: 1rem;
  border: 1px solid #eaeaea;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  h4 {
    font-weight: 700;
    font-size: 1.7rem;
    line-height: 160%;
    margin-top: 0.5rem;
    color: #01c6d8;
  }
  
  p {
    margin-bottom: 1rem;
    font-size: 1.6rem;
    color: var(--s-700);
  }

  img {
    border-radius: 8px;
    margin-bottom: 1rem;
    max-width: 100%; 
    height: auto;
    object-fit: cover;
  }
  video {
    max-height: 320px;
    max-width: 100%;
    height: 100%;
    border-radius: 8px;
    margin-bottom: 1rem;
  }
}
</style>
