<template>
  <div class="modal-mask" @click="closePanel">
    <div class="panel-wrap" @click.stop>
      <div class="header-wrap w-full flex-space-between">
        <div class="flex-view">
          <woot-button
            color-scheme="secondary"
            variant="link"
            size="tiny"
            icon="dismiss"
            @click="$emit('close')"
          />
        </div>
      </div>
      <div class="body">
        <tour-component
          @close="closePanel"
          :steps="steps"
          :theme="theme"
          :text="text"
        />
        <div 
          v-if="closeTutorial" 
          class="not-see"
        >
          <span @click="setNotDisplay">{{ $t('GUIDE.TUTORIAL.DONOTSEE_POPUP') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import TourComponent from './TourComponent.vue';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';

export default {
  name: 'ModalTour',
  components: { TourComponent },
  mixins: [uiSettingsMixin],
  props: {
    steps: {
      type: Array,
      default: () => {
        return [];
      },
    },
    closeTutorial: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
    theme: {
      color: '#01c6d8',
      radius: '2px',
    },
    blurEl: '.can-tour-blur',
    }
  },
  computed:{
    text(){
      return {
        next_cta: this.$t('GUIDE.TUTORIAL.NEXT'),
        prev_cta: this.$t('GUIDE.TUTORIAL.PREVIOUS'),
        restart_cta: this.$t('GUIDE.TUTORIAL.FINISH'),
      }
    }
  },
  methods: {
    closePanel() {
      this.$emit('close');
    },

    setNotDisplay(){
      this.updateUISettings({
        show_main_tutorial: false,
      });
      this.closePanel();
    }
  },
};
</script>
  
  <style lang="scss" scoped>
.panel-wrap {
  flex-direction: column;
  width: 50%;
  background-color: var(--white);
  border-radius: var(--border-radius-medium);
  position: absolute;
  margin: var(--space-small);
}

.body {
  padding: 2rem !important;
}

.not-see {
  margin-top: 0.5rem;
}
.not-see span{
  color: var(--w-950);
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}
.not-see span:hover {
  color: #01c6d8;
}

.flex-view {
  display: flex;
}

.flex-space-between {
  display: flex;
  justify-content: end;
}

.header-wrap {
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--s-50);
  padding: 1rem;

  .header-title--wrap {
    align-items: center;
  }

  .header-title {
    font-size: var(--font-size-two);
    font-weight: var(--font-weight-black);
  }
  .action-button {
    margin-right: var(--space-small);
  }
}

.tab--chat-type {
  ::v-deep {
    .tabs {
      padding: 0;
    }
  }
}

.not-see {
  display: flex;
  flex-direction: row-reverse;
}

.body {
  padding: var(--space-small);
}
</style>
  