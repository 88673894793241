<template>
  <div>
    <div class="video message-text__wrap">
      <video :src="url" muted playsInline @click="onClick" />
      <woot-modal :show.sync="show" :on-close="onClose">
        <video
          :src="url"
          controls
          playsInline
          class="modal-video skip-context-menu"
        />
      </woot-modal>
    </div>
    <div v-if="!!caption" class="caption-content message-text__wrap" >
      <div class="text-content">
        {{ caption }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    caption: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    onClose() {
      this.show = false;
    },
    onClick() {
      this.show = true;
    },
  },
};
</script>
