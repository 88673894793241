<template>
  <div class="flex-container actions--container">
    <div v-if="!currentChat.group">
      <woot-button
        v-if="!currentChat.muted"
        v-tooltip="$t('CONTACT_PANEL.MUTE_CONTACT')"
        variant="clear"
        color-scheme="secondary"
        icon="speaker-mute"
        @click="mute"
      />
      <woot-button
        v-else
        v-tooltip.left="$t('CONTACT_PANEL.UNMUTE_CONTACT')"
        variant="clear"
        color-scheme="secondary"
        icon="speaker-1"
        @click="unmute"
      />
    </div>
    <woot-button
      v-tooltip="$t('CONTACT_PANEL.SEND_TRANSCRIPT')"
      variant="clear"
      color-scheme="secondary"
      icon="share"
      @click="toggleEmailActionsModal"
    />
    <resolve-action
      v-if="!currentChat.group"
      :conversation-id="currentChat.id"
      :status="currentChat.status"
      @modal-status="handleModal"
    />
    <email-transcript-modal
      v-if="showEmailActionsModal"
      :show="showEmailActionsModal"
      :current-chat="currentChat"
      @cancel="toggleEmailActionsModal"
    />
    <woot-snoozed-modal
      v-if="showSnoozedModal"
      :show="showSnoozedModal"
      :scheduledAt="scheduledAt"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import alertMixin from 'shared/mixins/alertMixin';
import EmailTranscriptModal from './EmailTranscriptModal';
import ResolveAction from '../../buttons/ResolveAction';
import WootSnoozedModal from './WootSnoozedModal';
import wootConstants from 'dashboard/constants/globals';

import {
  CMD_MUTE_CONVERSATION,
  CMD_SEND_TRANSCRIPT,
  CMD_UNMUTE_CONVERSATION,
} from '../../../routes/dashboard/commands/commandBarBusEvents';
import WootButton from '../../ui/WootButton.vue';

export default {
  components: {
    EmailTranscriptModal,
    ResolveAction,
    WootButton,
    WootSnoozedModal
  },
  mixins: [alertMixin, clickaway],
  data() {
    return {
      showSnoozedModal: false,
      showEmailActionsModal: false,
      STATUS_TYPE: wootConstants.STATUS_TYPE,
      scheduledAt: null,
    };
  },
  computed: {
    ...mapGetters({ 
      currentChat: 'getSelectedChat',
      activeInbox: 'getSelectedInbox',
      accountId: 'getCurrentAccountId'
    }),
  },
  mounted() {
    bus.$on(CMD_MUTE_CONVERSATION, this.mute);
    bus.$on(CMD_UNMUTE_CONVERSATION, this.unmute);
    bus.$on(CMD_SEND_TRANSCRIPT, this.toggleEmailActionsModal);
  },
  destroyed() {
    bus.$off(CMD_MUTE_CONVERSATION, this.mute);
    bus.$off(CMD_UNMUTE_CONVERSATION, this.unmute);
    bus.$off(CMD_SEND_TRANSCRIPT, this.toggleEmailActionsModal);
  },
  methods: {
    closeModal() {
      this.showSnoozedModal = false;
    },
    handleModal(value) {
      this.showSnoozedModal = value;
    },
    mute() {
      this.$store.dispatch('muteConversation', this.currentChat.id);
      this.showAlert(this.$t('CONTACT_PANEL.MUTED_SUCCESS'));
    },
    unmute() {
      this.$store.dispatch('unmuteConversation', this.currentChat.id);
      this.showAlert(this.$t('CONTACT_PANEL.UNMUTED_SUCCESS'));
    },
    toggleEmailActionsModal() {
      this.showEmailActionsModal = !this.showEmailActionsModal;
    },
  },
};
</script>

<style scoped lang="scss">
.actions--container {
  align-items: center;
  position: relative;
}

.more--button {
  align-items: center;
  display: flex;
  margin-left: var(--space-small);
}

.dropdown-pane {
  right: var(--space-minus-small);
  top: 48px;
}

.icon {
  margin-right: var(--space-smaller);
  min-width: var(--space-normal);
}
</style>
