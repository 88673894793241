<template>
  <div class="preview-item__wrap">
    <div v-if="attachment" class="preview-item">
      <div class="preview-item__wrap_item">
        <div class="icon-wrap">
          <fluent-icon icon="location" class="file--icon" size="24" />
        </div>
        <div>
          <p>
            {{
              attachment.route ||
                attachment.administrative_area_level_2 +
                  ', ' +
                  attachment.administrative_area_level_1
            }}
          </p>
        </div>
      </div>
      <woot-button
        v-if="attachment"
        class="remove--attachment clear secondary"
        icon="dismiss"
        @click="() => onRemoveAttachment()"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    attachment: {
      type: Object,
      default: () => {},
    },
    removeAttachment: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    onRemoveAttachment() {
      this.removeAttachment();
    },
  },
};
</script>
<style lang="scss" scoped>
.preview-item__wrap {
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 20rem;
}

.preview-item__wrap_p {
  display: flex;
  flex-direction: row;
  overflow: auto;
  max-height: 20rem;
}

.preview-item__wrap_item {
  display: flex;
  padding: var(--space-slab) 0 0;
  gap: 5px;
  background: var(--color-background-light);
  background: var(--b-50);
  border-radius: var(--border-radius-normal);
  flex-direction: row;
  width: 24rem;
  padding: var(--space-smaller);
  justify-content: space-between;
  align-items: center;

  p {
    margin-bottom: 0;
  }
}

.preview-item {
  display: flex;
  padding: var(--space-slab) 0 0;
  background: var(--color-background-light);
  background: var(--b-50);
  border-radius: var(--border-radius-normal);
  width: 24rem;
  padding: var(--space-smaller);
  justify-content: space-between;
  align-items: center;

  p {
    margin-bottom: 0;
  }
}

.thumb-wrap {
  max-width: var(--space-jumbo);
  flex-shrink: 0;
  width: var(--space-medium);
  display: flex;
  align-items: center;
}

.image-thumb {
  width: var(--space-medium);
  height: var(--space-medium);
  object-fit: cover;
  border-radius: var(--border-radius-small);
}

.attachment-thumb {
  width: var(--space-medium);
  height: var(--space-medium);
  font-size: var(--font-size-medium);
  text-align: center;
  position: relative;
  top: -1px;
  text-align: left;
}

.file-name-wrap,
.file-size-wrap {
  display: flex;
  align-items: center;
  padding: 0 var(--space-smaller);

  > .item {
    margin: 0;
    overflow: hidden;
    font-size: var(--font-size-mini);
    font-weight: var(--font-weight-medium);
  }
}

.preview-header {
  padding: var(--space-slab) var(--space-slab) 0 var(--space-slab);
}

.file-name-wrap {
  max-width: 60%;
  min-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: var(--space-small);

  .item {
    height: var(--space-normal);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.file-size-wrap {
  width: 30%;
  justify-content: center;
}

.remove-file-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove--attachment {
  width: var(--space-medium);
  height: var(--space-medium);
  border-radius: var(--space-medium);
  font-size: var(--font-size-small);
  cursor: pointer;

  &:hover {
    background: var(--color-background);
  }
}
</style>
