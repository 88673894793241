<template>
  <div class="modal-mask">

    <form class="panel-wrap" @submit.prevent="handleSubmit">
      <div class="flex-view">
        <h2 class="page-title">
          {{ $t('GROUP_PARTICIPANT.ADD_TITLE') }}
        </h2>
        <woot-button color-scheme="secondary" variant="link" size="tiny" icon="dismiss" @click="$emit('close')" />
      </div>

      <div class="content-add-participant">
        <woot-phone-input 
          style="width: 430px;" 
          v-model="phoneNumber" 
          :value="phoneNumber" 
          :error="isPhoneNumberNotValid"
          :success="isWhatsappNotValid" 
          :placeholder="$t('CONTACT_FORM.FORM.PHONE_NUMBER.PLACEHOLDER')"
          @input="onPhoneNumberInputChange" 
          @setCode="setPhoneCode" 
        />
        <span v-if="isWhatsappNotValid && !isPhoneNumberNotValid" class="successMessage">
          {{ whatsappError }}
        </span>
      </div>
      <div class="button-submit">
        <woot-submit-button :disabled="phoneNumber.length < 9" button-text="Adicionar">
          {{ $t('GROUP_PARTICIPANT.BUTTON_ADD') }}
        </woot-submit-button>
      </div>
    </form>

  </div>
</template>

<script>
import WootButton from '../../../../components/ui/WootButton.vue';
import ContactInfo from './ContactInfo.vue';
import { isPhoneNumberValid } from 'shared/helpers/Validators';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import parsePhoneNumber from 'libphonenumber-js';

export default {
  components: { WootButton, ContactInfo },
  mixins: [alertMixin],
  name: 'ModalParticipantsGroup',
  data() {
    return {
      setPage: 1,
      phoneNumber: '',
      warning: '',
      activeDialCode: '',
    };
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    conversationInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.setDialCode()
  },
  methods: {
     async handleSubmit(){
      try {
        await this.addParticipant(this.setPhoneNumber + '@s.whatsapp.net')
        this.$emit('close')
        this.showAlert(this.$t('GROUP_PARTICIPANT.ADD_PARTICIPANT'))
      }catch (error) {
        this.showAlert(this.$t('GROUP_PARTICIPANT.ERROR_PARTICIPANT'))
      }
    },
    async addParticipant(id) {
      const account = this.getAccount(this.currentAccountId);
      let participantsList = []
      participantsList.push(id)
      const payload = {
        url: account?.url_whatsapp_web,
        jid: this.conversationInfo?.group?.jid,
        type: "add",
        session_id: this.inbox?.session_id,
        participants: participantsList,
      }
      try {
            await this.$store.dispatch('groupParticipantsConfig', payload);
        } catch (error) {
            throw new Error('Erro ao adicionar participante'); 
        }
    },
    setPhoneCode(code) {
      if (this.phoneNumber !== '' && this.parsePhoneNumber) {
        const dialCode = this.parsePhoneNumber.countryCallingCode;
        if (dialCode === code) {
          return;
        }
        this.activeDialCode = `+${dialCode}`;
        const newPhoneNumber = this.phoneNumber.replace(
          `+${dialCode}`,
          `${code}`
        );
        this.phoneNumber = newPhoneNumber;
      } else {
        this.activeDialCode = code;
      }
    },
    setDialCode() {
      if (
        this.phoneNumber !== '' &&
        this.parsePhoneNumber &&
        this.parsePhoneNumber.countryCallingCode
      ) {
        const dialCode = this.parsePhoneNumber.countryCallingCode;
        this.activeDialCode = `+${dialCode}`;
      }
    },
    onPhoneNumberInputChange(value, code) {
      this.activeDialCode = code;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        if (this.phoneNumber.length > 8) {
          this.checkUser();
        }
      }, 1000)
    },
    async checkUser() {
      try{
        const account = this.getAccount(this.currentAccountId);
        this.warning = await this.$store.dispatch('contacts/verifyUser', {
        url: account.url_whatsapp_web + 'api/v1/sessions',
        phone: this.phoneNumber,
      })
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      currentAccountId: 'getCurrentAccountId',
      inboxes: 'inboxes/getInboxes',
    }),
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.conversationInfo?.inbox_id);
    },
    setPhoneNumber() {
      if (this.parsePhoneNumber && this.parsePhoneNumber.countryCallingCode) {
        return this.phoneNumber;
      }
      if (this.phoneNumber === '' && this.activeDialCode !== '') {
        return '';
      }
      this.activeDialCode = this.activeDialCode.replace('+','')
      return this.activeDialCode
        ? `${this.activeDialCode}${this.phoneNumber}`
        : '';
    },
    isPhoneNumberNotValid() {
      if (this.phoneNumber !== '') {
        return (
          !isPhoneNumberValid(this.phoneNumber, this.activeDialCode) ||
          (this.phoneNumber !== '' ? this.activeDialCode === '' : false)
        );
      }
      return false;
    },
    isWhatsappNotValid() {
      if (this.warning === 'true') {
        return true;
      }
      return false;
    },
    parsePhoneNumber() {
      return parsePhoneNumber(this.phoneNumber);
    },
    whatsappError() {
      if (this.warning === 'true') {
        return this.$t('CONTACT_FORM.FORM.PHONE_NUMBER.VALID_WHATSAPP');
      }
      return '';
    }
  }
}

</script>

<style lang="scss" scoped>
.panel-wrap {
  flex-direction: column;
  width: 450px;
  height: 250px;
  background-color: var(--white);
  border-radius: var(--border-radius-medium);
  position: absolute;
  margin: var(--space-small);
}
.button-submit{
  float: right;
  padding: 15px;
}

.flex-view {
  display: flex;
  height: 80px;
  justify-content: space-between;
  padding: 0 30px;
  align-items: center;
}

.page-title{
  font-size: 2.2rem;
  word-wrap: break-word;
  line-height: 1.4;
  margin-bottom: 0 !important;
}

.body {
  padding: var(--space-small);
}

.content-add-participant {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.successMessage {
  color: var(--g-600);
  display: block;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-normal);
  margin-bottom: var(--space-one);
  margin-top: -15px;
  width: 430px;
}
</style>