<template>
    <woot-modal :show="show" :on-close="closeModal" size="tiny">
      <div class="calendar-modal">
        <woot-modal-header :header-title="$t('CONVERSATION.RESOLVE_DROPDOWN.SNOOZE.TITLE')" />
        <woot-date-time-picker
          :value="scheduledAt"
          :confirm-text="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.CONFIRM')"
          :placeholder="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.PLACEHOLDER')"
          @change="onChange"
        />
        <woot-button class="modal-button" @click="() => sendSnooze(STATUS_TYPE.SNOOZED, scheduledAt)">
          {{$t('CONTACT_PANEL.SEND_BUTTON')}}
        </woot-button>
      </div>
    </woot-modal>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  import WootDateTimePicker from 'dashboard/components/ui/DateTimePicker.vue';
  import WootModal from '../../Modal.vue';
  import { mixin as clickaway } from 'vue-clickaway';
  import wootConstants from 'dashboard/constants/globals';
  import alertMixin from 'shared/mixins/alertMixin';
  import { closeConversation } from '../../../helper/URLHelper';
  import router from '../../../routes';
  import WootModalHeader from '../../ModalHeader.vue';
  export default {
    mixins: [alertMixin, clickaway],
    data() {
    return {
      scheduledAt: null,
      STATUS_TYPE: wootConstants.STATUS_TYPE,
    };
  },
    computed: {
    ...mapGetters({ 
      currentChat: 'getSelectedChat',
      activeInbox: 'getSelectedInbox',
      accountId: 'getCurrentAccountId'
    }),
  },
    components: {
      WootDateTimePicker,
      WootModal,
      WootModalHeader,
    },
    props: {
      show: Boolean,
    },
    methods: {
    sendSnooze(status, snoozedUntil) {
      this.isLoading = true;
      const timestampDate = Date.parse(snoozedUntil) / 1000;
      this.$store
        .dispatch('toggleStatus', {
          conversationId: this.currentChat.id,
          status,
          snoozedUntil: timestampDate,
        })
        .then(() => {
          this.$store.dispatch('inboxes/get');
          this.$store.dispatch('contactConversations/get', this.currentChat.meta.sender.id);
          this.showAlert(this.$t('CONVERSATION.CHANGE_STATUS'));
          this.isLoading = false;
          if (status !== 'open') {
            closeConversation(this.accountId, router, 0, null, this.activeInbox);
          }
        });
    },
    onChange(value) {
      this.scheduledAt = value;
    },
      closeModal() {
        this.$emit('close');
      }
    }
  };
  </script>
  
  <style scoped lang="scss">
  .calendar-modal {
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .modal-button {
    margin-bottom: 30px;
  }
  </style>
  