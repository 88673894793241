<template>
  <div>
    <li class="task-item">
      <div class="task-item-header">
        <p class="task-title cursor-pointer" @click="handleShowEditModal">
          #{{ localItem.id }} {{ truncatedTitle(localItem.text, 40) }}
        </p>
      </div>
      <div class="task-item-body">
        <p class="money-card"  v-if="isFunnelSale">{{ formatCurrency(localItem.sale_value) }}</p>

        <span v-if="!showEditDescription" @click="handleShowEditDescription()" class="description cursor-pointer">
          {{ truncatedTitle(localItem.description, 70) }}</span>

        <div v-if="showEditDescription">
          <textarea 
            v-model="newDescription" 
            class="form-control" 
            rows="2" 
            @blur="handleHideEditDescription"
            >
            </textarea>
          <woot-button size="tiny" color-scheme="primary" icon="save" @click="handleUpdateTexts">
            {{ this.$t('TICKET.FORM.TEXT.SAVE') }}
          </woot-button>
        </div>
      </div>
      <div class="task-item-body card-select-default">
        <div class="task-priority" :class="localItem.priority" v-if="showTaskPriority" @click="changePriority">
          {{ this.$t('TICKET.FORM.PRIORITY.LABEL_CARD') }}
          {{ priorityTranslate }}
        </div>
        <v-select 
          v-if="showTaskPriorityDropdown" 
          v-model="localItem.priority" 
          ref="vueDropdown"
          :options="priorityOptions.map(option => ({ label: option.name, value: option.value }))"
          @search:blur="setNewPriority" 
          :clearable="false" 
          :closeOnSelect="true"
          :reduce="option => option.value"
          class="custom-v-select">
        </v-select>

      </div>
      <div>
        <div>
          <v-select v-if="showAgentDropdown" v-model="assignedAgent" :options="agentList" label="name"
            :get-option-label="option => option.name" @input="setNewAgent" :clearable="false" ref="agentDropdown"
            class="custom-v-select" 
          />
          <woot-button v-if="showAgentDropdown" size="tiny" color-scheme="primary" icon="save" @click="changeAgent">
            {{ this.$t('TICKET.FORM.TEXT.SAVE') }}
          </woot-button>
        </div>

        <div>
          <multiselect
            v-if="showLabelDropdown"
            v-model="selectedLabels"
            :options="labels"
            track-by="id"
            label="title"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :hide-selected="true"
            placeholder="Pick some"
            selected-label
            :select-label="$t('FORMS.MULTISELECT.CLICK_TO_SELECT')"
            :deselect-label="$t('FORMS.MULTISELECT.CLICK_TO_REMOVE')"
            @input="setNewLabels"
          />
          <woot-button v-if="showLabelDropdown" size="tiny" color-scheme="primary" icon="save" @click="saveLabels">
              {{ this.$t('TICKET.FORM.TEXT.SAVE') }}
            </woot-button>
        </div>
      </div>
      <div class="task-item-footer">
        <woot-button
          v-if="labelsSelected.length < 1" 
          icon="add"
          variant="smooth"
          size="small"
          @click="toggleLabelDropdown"
        >
          {{ this.$t('TICKET.FORM.LABEL.ADD') }}
        </woot-button>
        <div class="flex">
          <div class="flex" @click="toggleLabelDropdown">
            <woot-label 
              v-for="label in filteredLabels" 
              :title="label.title" 
              :color="label.color" 
              :key="label.id"
              variant="smooth" 
              small
              class="cursor-pointer"
            />
          </div>
          <woot-button
            v-if="validateLabels >= 22"
            :title="$t('CONVERSATION.CARD.SHOW_LABELS')"
            class="show-more--button"
            color-scheme="secondary"
            variant="hollow"
            icon='chevron-right'
            size="tiny"
            @click="handleShowEditModal"
          />
        </div>
        <div class="box-thumbnail">
          <div class="cursor-pointer box-thumbnail">
            <div @click="changeAgent">
              <thumbnail v-if="assignedAgent" :src="assignedAgent.thumbnail" :username="assignedAgent.name" size="34px"
                v-tooltip.top-end="assignedAgent.name" />
            </div>
            <div @click="redirectContact">
              <thumbnail :src="item.contact.avatar_url" :username="item.contact.name" size="34px"
                v-tooltip.top-end="item.contact.name" />
            </div>
          </div>
        </div>
      </div>
    </li>

    <modal-update-ticket-contact
      :show="showEditModal"
      :ticket-selected="localItem"
      :column="list"
      @cancel="closeUpdateTicketModal"
      @updateTicket="updateTicket"
    />
  </div>

</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Thumbnail from '../../../../../components/widgets/Thumbnail.vue';
import SettingsSection from "../../../../../components/SettingsSection.vue";
import ModalUpdateTicketContact from "../../../conversation/contact/ModalUpdateTicketContact.vue";
import { mapGetters } from 'vuex';
import router from "../../../..";
import { frontendURL } from "../../../../../helper/URLHelper";
import{ formatCurrency } from '../../../../../helper/formatCurrency';

export default {
  name: "TaskItem",
  props: ["item", "list", "board", "kanbanId", 'isFunnelSale'],
  components: {
    "v-select": vSelect,
    Thumbnail,
    SettingsSection,
    ModalUpdateTicketContact
  },
  data() {
    return {
      showTaskPriorityDropdown: false,
      showTaskPriority: true,
      showEditModal: false,
      newText: this.item.text,
      showEditDescription: false,
      newDescription: this.item.description,
      localItem: this.item,
      showAgentDropdown: false,
      showLabelDropdown: false,
      selectedLabels: this.labelsSelected 
    };
  },
  computed: {
    ...mapGetters({
      agentList: 'agents/getAgents',
      labels: 'labels/getLabels',
      accountId: 'getCurrentAccountId',
    }),

    priorityTranslate(){
      const key = this.localItem.priority.toUpperCase();
      return this.$t(`TICKET.FORM.PRIORITY.OPTIONS.${key}`)
    },

    priorityOptions() {
      return [
        { name: this.$t('TICKET.FORM.PRIORITY.OPTIONS.LOW'), value: 'low' },
        { name: this.$t('TICKET.FORM.PRIORITY.OPTIONS.MEDIUM'), value: 'medium' },
        { name: this.$t('TICKET.FORM.PRIORITY.OPTIONS.HIGH'), value: 'high' }
      ];
    },

    labelsSelected() {
      if (!this.localItem?.labels_ids.length) return [];
      const labels = this.labels?.filter((label) => this.localItem?.labels_ids.includes(label.id));
      this.selectedLabels = labels;
      return labels
    },

    filteredLabels() {
      let totalCharacters = 0;

      return this.labelsSelected.filter((label) => {
        const titleLength = label.title.length;

        if (titleLength >= 22) return false;

        if (totalCharacters + titleLength <= 22) {
          totalCharacters += titleLength;
          return true;
        }
        
        return false;
      });
    },


    validateLabels(){
      const selectedLabels = this.labelsSelected
      const totalCharacters = selectedLabels.reduce((acc, label) => {
        return acc + (label.title ? label.title.length : 0);
      }, 0);
      return totalCharacters;
    },

    assignedAgent() {
      if (!this.agentList.length) {
        return null
      }
      return this.agentList?.find(agent => agent.id === this.localItem.user_id);
    }
  },
  watch: {
    item: {
      handler(newItem) {
        this.localItem = { ...newItem };
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    formatCurrency,
    toggleLabelDropdown() {
      this.showLabelDropdown = !this.showLabelDropdown;
    },

    async saveLabels(){
      const labelIds = this.selectedLabels.map(label => label.id);
      this.item.labels_ids = labelIds;
      this.localItem.labels_ids = labelIds;
      await this.$store.dispatch('updateTicket',  this.localItem);
      this.toggleLabelDropdown();
    },

    handleHideEditDescription() {
      setTimeout(() => this.showEditDescription = false, 200);
    },

    redirectContact(){
      const path = frontendURL(`accounts/${this.accountId}/contacts/${this.item.contact.id}`);
      router.push({ path });
    },

    async setNewLabels(newLabels) {
      this.selectedLabels = newLabels;
    },

    changePriority() {
      this.showTaskPriorityDropdown = !this.showTaskPriorityDropdown;
      this.showTaskPriority = !this.showTaskPriority;
      this.$nextTick(() => {
        const input = this.$refs.vueDropdown.$el.querySelector("input");
        input.focus();
      });
    },
    async setNewPriority(e) {
      this.showTaskPriorityDropdown = !this.showTaskPriorityDropdown;
      this.showTaskPriority = !this.showTaskPriority;
      await this.$store.dispatch('updateTicket', this.localItem);
    },

    changeAgent() {
      this.showAgentDropdown = !this.showAgentDropdown;
      this.$nextTick(() => {
        if (this.showAgentDropdown) {
          const input = this.$refs.agentDropdown.$el.querySelector("input");
          input.focus();
        }
      });
    },

    async setNewAgent(agent) {
      this.item.user_id = agent.id;
      await this.$store.dispatch('updateTicket', this.item);
      this.showAgentDropdown = false;
    },

    handleShowEditModal() {
      this.showEditModal = !this.showEditModal;
    },
    handleShowEditDescription() {
      this.showEditDescription = !this.showEditDescription;
    },

    toggleUpdateTicketModal(){
      this.showEditModal = !this.showEditModal;
    },

    closeUpdateTicketModal(){
      this.showEditModal = false;
    },

    async handleUpdateTexts() {
      this.item.text = this.newText;
      this.item.description = this.newDescription;
      await this.$store.dispatch('updateTicket', this.item);
      this.showEditDescription = false;
      this.showEditModal = false;
    },

    truncatedTitle(text, maxCharacters = 30) {
      return text.length > maxCharacters ? text.slice(0, maxCharacters) + '...' : text;
    },

    updateTicket(ticket){
      this.localItem = ticket;
    }
  }
};
</script>

<style>


.task-item {
  cursor: grab;
}

.money-card {
  color: #d0d0d0;
  font-weight: 500;
  font-size: 1.1rem;
}
.flex {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.task-title:hover{
  text-decoration: underline;  
}

.description {
  font-size: 1.4rem;
}

.assigned-users .user-avatar {
  margin-right: -15px;
}

.id-ticket {
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer;
}

.card-select-default {
  width: 50%;
}

.assigned-users .add-icon {
  margin-left: 20px;
  cursor: pointer;
}

.custom-v-select {
  font-size: 14px;
}

.assignee-selection .dropdown-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.4rem 0.8rem;
}

.assignee-selection .dropdown-item .user-avatar {
  margin-right: 15px;
}

.assignee-selection .dropdown-item .user-name {
  font-size: 14px;
  font-weight: 400;
  color: rgb(45, 45, 82);
}

.box-thumbnail {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
</style>