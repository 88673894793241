import { FEATURE_FLAGS } from '../../../../featureFlags';
import { frontendURL } from '../../../../helper/URLHelper';

const primaryMenuItems = accountId => [
  {
    icon: 'fi fi-sr-layout-fluid',
    key: 'dashboard',
    label: 'DASHBOARD',
    toState: frontendURL(`accounts/${accountId}/dashboard`),
    toStateName: 'home',
    roles: ['administrator'],
  },
  {
    icon: 'fi fi-sr-messages',
    key: 'conversations',
    label: 'CONVERSATIONS',
    toState: frontendURL(`accounts/${accountId}/conversation`),
    toStateName: 'home',
    roles: ['administrator', 'agent'],
  },
  {
    icon: 'fi fi-sr-ballot',
    key: 'contacts',
    label: 'CONTACTS',
    featureFlag: FEATURE_FLAGS.CRM,
    toState: frontendURL(`accounts/${accountId}/contacts`),
    toStateName: 'contacts_dashboard',
    roles: ['administrator', 'agent'],
  },
  {
    icon: 'fi fi-sr-dashboard',
    key: 'crm',
    label: 'CRM',
    featureFlag: FEATURE_FLAGS.CRM,
    toState: frontendURL(`accounts/${accountId}/crm`),
    toStateName: 'kanban',
    roles: ['administrator', 'agent'],
  },
  {
    icon: 'fi fi-sr-megaphone',
    key: 'campaigns',
    label: 'CAMPAIGNS',
    featureFlag: FEATURE_FLAGS.CAMPAIGNS,
    toState: frontendURL(`accounts/${accountId}/campaigns`),
    toStateName: 'settings_account_campaigns',
    roles: ['administrator'],
  },
  {
    icon: 'fi fi-sr-search-alt',
    key: 'helpcenter',
    label: 'HELP_CENTER.TITLE',
    featureFlag: FEATURE_FLAGS.HELP_CENTER,
    toState: frontendURL(`accounts/${accountId}/portals`),
    toStateName: 'default_portal_articles',
    roles: ['administrator'],
  },
  {
    icon: 'fi fi-sr-chart-simple',
    key: 'reports',
    label: 'REPORTS',
    toState: frontendURL(`accounts/${accountId}/reports/overview`),
    toStateName: 'settings_home',
    roles: ['administrator'],
  },
];

export default primaryMenuItems;