<template>
  <div
    class="small-12 medium-3 bg-white contact--panel"
    :class="{ 'border-left': showAvatar }"
  >
    <contact-info
      :show-close-button="showCloseButton"
      :show-avatar="showAvatar"
      :contact="contact"
      close-icon-name="dismiss"
      :conversation-id="conversationId"
      :on-send="onSend"
      :inbox="inbox"
      :message="message"
      :toggle-contact="toggleContact"
      :ticketSelected="ticket"
      :is-ticket="isTicket"
      @panel-close="onClose"
      @toggle-panel="onClose"
      @close-modal-ticket="closeModalTicket"
      @toggle-contacts-view="toggleContactsView"
      @updateTicket="updateTicket"
    />
    
    <draggable
      v-if="!isTicket"
      :list="contactSidebarItems"
      :disabled="!dragEnabled"
      class="list-group"
      ghost-class="ghost"
      @start="dragging = true"
      @end="onDragEnd"
    >
      <transition-group>
        <div
          v-for="element in contactSidebarItems"
          :key="element.name"
          class="list-group-item"
        >
          <div v-if="element.name === 'contact_attributes'">
            <accordion-item
              :title="$t('CONVERSATION_SIDEBAR.ACCORDION.CONTACT_ATTRIBUTES')"
              :is-open="isContactSidebarItemOpen('is_ct_custom_attr_open')"
              compact
              @click="
                value => toggleSidebarUIState('is_ct_custom_attr_open', value)
              "
            >
              <custom-attributes
                :contact-id="contact.id"
                attribute-type="contact_attribute"
                attribute-class="conversation--attribute"
                :custom-attributes="contact.custom_attributes"
                class="even"
              />
              <custom-attribute-selector
                attribute-type="contact_attribute"
                :contact-id="contact.id"
              />
            </accordion-item>
          </div>
          <div
            v-else-if="element.name === 'contact_wallet'"
            class="conversation--actions"
          >
            <accordion-item
              :title="$t('CONVERSATION_SIDEBAR.ACCORDION.CONTACT_WALLET')"
              :is-open="isContactSidebarItemOpen('is_contact_wallet_open')"
              @click="
                value => toggleSidebarUIState('is_contact_wallet_open', value)
              "
            >
              <contact-wallet :contact="contact" />
            </accordion-item>
          </div>
          <div v-if="element.name === 'contact_labels'">
            <accordion-item
              :title="$t('CONTACT_PANEL.SIDEBAR_SECTIONS.CONTACT_LABELS')"
              :is-open="isContactSidebarItemOpen('is_ct_labels_open')"
              @click="value => toggleSidebarUIState('is_ct_labels_open', value)"
            >
              <contact-label :contact-id="contact.id" class="contact-labels" />
            </accordion-item>
          </div>
          <div v-if="element.name === 'previous_conversation'">
            <accordion-item
              :title="
                $t('CONTACT_PANEL.SIDEBAR_SECTIONS.PREVIOUS_CONVERSATIONS')
              "
              :is-open="isContactSidebarItemOpen('is_ct_prev_conv_open')"
              @click="
                value => toggleSidebarUIState('is_ct_prev_conv_open', value)
              "
            >
              <contact-conversations
                v-if="contact.id"
                :contact-id="contact.id"
                conversation-id=""
              />
            </accordion-item>
          </div>
          <div
            v-else-if="element.name === 'tickets'"
          >
            <accordion-item
              v-if="contact.id"
              :title="$t('TICKET.TITLE')"
              :is-open="isContactSidebarItemOpen('is_previous_tickets_open')"
              @click="
                value => toggleSidebarUIState('is_previous_tickets_open', value)
              "
            >
              <list-tickets :contact="contact"/>
              
            </accordion-item>
          </div>
        </div>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import AccordionItem from 'dashboard/components/Accordion/AccordionItem';
import ContactConversations from 'dashboard/routes/dashboard/conversation/ContactConversations';
import ContactInfo from 'dashboard/routes/dashboard/conversation/contact/ContactInfo';
import ContactLabel from 'dashboard/routes/dashboard/contacts/components/ContactLabels.vue';
import CustomAttributes from 'dashboard/routes/dashboard/conversation/customAttributes/CustomAttributes.vue';
import CustomAttributeSelector from 'dashboard/routes/dashboard/conversation/customAttributes/CustomAttributeSelector.vue';
import draggable from 'vuedraggable';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import ContactWallet from 'dashboard/routes/dashboard/conversation/ContactWallet.vue';
import ListTickets from '../../conversation/contact/ListTickets.vue';

export default {
  components: {
    AccordionItem,
    ContactConversations,
    ContactInfo,
    ContactLabel,
    CustomAttributes,
    CustomAttributeSelector,
    draggable,
    ContactWallet,
    ListTickets
  },
  mixins: [uiSettingsMixin],
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    showAvatar: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    conversationId: {
      type: String,
      default: undefined,
    },
    onSend: {
      type: Function,
      default: () => {},
    },
    inbox: {
      type: Object,
      default: () => ({}),
    },
    message: {
      type: String,
      default: '',
    },
    toggleContact: {
      type: Function,
      default: () => {},
    },
    ticketSelected: {
      type: Object,
      default: () => ({}),
    },
    isTicket: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      dragEnabled: true,
      contactSidebarItems: [],
      dragging: false,
      ticket: this.ticketSelected
    };
  },
  computed: {
    hasContactAttributes() {
      const { custom_attributes: customAttributes } = this.contact;
      return customAttributes && Object.keys(customAttributes).length;
    },
  },
  mounted() {
    this.contactSidebarItems = this.contactSidebarItemsOrder;
  },
  methods: {
    closeModalTicket(isDelete){
      this.$emit('close-modal-ticket', isDelete);
    },

    updateTicket(updatedTicket){
      this.ticket = {...this.ticket, ...updatedTicket};
      this.$emit('updateTicket', updatedTicket);
    },
    onDragEnd() {
      this.dragging = false;
      this.updateUISettings({
        contact_sidebar_items_order: this.contactSidebarItems,
      });
    },
    toggleContactsView() {
      this.$emit('toggle-contacts-view');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .contact--profile {
    padding-bottom: var(--space-slab);
    margin-bottom: var(--space-normal);
  }
}
.contact--panel {
  height: 100%;
  background: white;
  font-size: var(--font-size-small);
  overflow-y: auto;
  overflow: auto;
  position: relative;
  border-right: 1px solid var(--color-border);
}

.list-group {
  .list-group-item {
    background-color: var(--white);
  }
}

.conversation--details {
  padding: 0 var(--space-normal);
}

.contact--mute {
  color: var(--r-400);
  display: block;
  text-align: left;
}

.contact--actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.medium-3 {
  flex: 0 0 25%;
  max-width: 100%;
}
</style>
