import axios from 'axios';
const {
  WEBMASTER_URL: webmasterUrl,
  API_KEY_WM: apiKeyVm,
} = window.globalConfig || {};

const instance = axios.create({
  baseURL: `${webmasterUrl}`,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'x-access-token': `${apiKeyVm}`,
  },
});

export default instance;
