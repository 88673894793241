import Manual from './Manual';
import { frontendURL } from '../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/manual'),
      name: 'manual',
      component: Manual,
      roles: ['administrator', 'agent'],
    },
    
  ],
};
