<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header
        :header-title="$t('CANNED_MGMT.ADD.TITLE')"
        :header-content="$t('CANNED_MGMT.ADD.DESC')"
        :info-button="$t('CANNED_MGMT.HINT')"
      />
      <form class="row" @submit.prevent="addCannedResponse()">
        <div >
          <suggestion-buttons :suggestions="suggestions" @customVariable="customVariable"/>
        </div>
        <div class="medium-12 columns">
          <label :class="{ error: $v.shortCode.$error }">
            {{ $t('CANNED_MGMT.ADD.FORM.SHORT_CODE.LABEL') }}
            <input
              v-model.trim="shortCode"
              type="text"
              :placeholder="$t('CANNED_MGMT.ADD.FORM.SHORT_CODE.PLACEHOLDER')"
              @input="$v.shortCode.$touch"
            />
          </label>
        </div>

        <div class="medium-12 columns">
          <label :class="{ error: $v.content.$error }">
            {{ $t('CANNED_MGMT.ADD.FORM.CONTENT.LABEL') }}
          </label>
          <div class="editor-wrap">
            <woot-message-editor
              v-model="content"
              class="message-editor"
              :class="{ editor_warning: $v.content.$error }"
              :enable-variables="true"
              :enable-canned-responses="false"
              :placeholder="$t('CANNED_MGMT.ADD.FORM.CONTENT.PLACEHOLDER')"
            />
          </div>
          <multiselect
        v-model="currentSelectedFilter"
        :placeholder="$t('LABEL_REPORTS.FILTER_DROPDOWN_LABEL')"
        label="title"
        track-by="id"
        :options="labelList"
        :option-height="24"
        :show-labels="false"
      ><template slot="singleLabel" slot-scope="props">
        <div class="reports-option__wrap">
          <div
            :style="{ backgroundColor: props.option.color }"
            class="reports-option__rounded--item"
          />
          <span class="reports-option__desc">
            <span class="reports-option__title">
              {{ props.option.title }}
            </span>
          </span>
        </div>
      </template>
      <template slot="option" slot-scope="props">
        <div class="reports-option__wrap">
          <div
            :style="{ backgroundColor: props.option.color }"
            class="
                reports-option__rounded--item
                reports-option__item
                reports-option__label--swatch
              "
          />
          <span class="reports-option__desc">
            <span class="reports-option__title">
              {{ props.option.title }}
            </span>
          </span>
        </div>
      </template>
    </multiselect>
        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="
                $v.content.$invalid ||
                  $v.shortCode.$invalid ||
                  addCanned.showLoading
              "
              :button-text="$t('CANNED_MGMT.ADD.FORM.SUBMIT')"
              :loading="addCanned.showLoading"
              class="bg-primary"
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('CANNED_MGMT.ADD.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, minLength } from 'vuelidate/lib/validators';

import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import { MESSAGE_VARIABLES } from 'shared/constants/messages';
import Modal from '../../../../components/Modal';
import SuggestionButtons from './SuggestionButtons';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    WootSubmitButton,
    Modal,
    SuggestionButtons,
    WootMessageEditor,
  },
  mixins: [alertMixin],
  props: {
    responseContent: {
      type: String,
      default: '',
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      suggestions: [],
      currentSelectedFilter: null,
      shortCode: '',
      content: this.responseContent || '',
      addCanned: {
        showLoading: false,
        message: '',
      },
      show: true,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      labelList: 'labels/getLabels',
    }),
  },
  validations: {
    shortCode: {
      required,
      minLength: minLength(2),
    },
    content: {
      required,
    },
  },
  mounted(){
    this.suggestions = MESSAGE_VARIABLES.filter((obj) => obj?.label == "Greeting" || obj?.label?.includes("Contact"));
  },
  methods: {
    resetForm() {
      this.shortCode = '';
      this.content = '';
      this.$v.shortCode.$reset();
      this.$v.content.$reset();
    },
    customVariable(value){
      this.content = this.content += ` {{${value}}}&nbsp;`;
    },
    addCannedResponse() {
      // Show loading on button
      this.addCanned.showLoading = true;
      // Make API Calls

      const payload = {
        short_code: this.shortCode,
        content: this.content,
        user_id: this.currentUser.id,
        tag_id: this.currentSelectedFilter?.id ? this.currentSelectedFilter?.id : null
      }
      
      this.$store
        .dispatch('createCannedResponse', payload)
        .then(() => {
          // Reset Form, Show success message
          this.addCanned.showLoading = false;
          this.showAlert(this.$t('CANNED_MGMT.ADD.API.SUCCESS_MESSAGE'));
          this.resetForm();
          this.onClose();
        })
        .catch(error => {
          this.addCanned.showLoading = false;
          const errorMessage =
            error?.message || this.$t('CANNED_MGMT.ADD.API.ERROR_MESSAGE');
          this.showAlert(errorMessage);
        });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .ProseMirror-menubar {
    display: none;
  }

  .ProseMirror-woot-style {
    min-height: 20rem;

    p {
      font-size: var(--font-size-default);
    }
  }

  .message-editor {
    border: 1px solid var(--s-200);
  }
}
</style>
