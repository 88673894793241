import ApiClient from './ApiClient';

class ticketApi extends ApiClient {
    constructor() {
        super('', { accountScoped: true });
    }

    async getTickets( { kanbanfunnel_id, page = 1, per_page = 10 }) {
        return await axios.get(`${this.url}tickets?kanbanfunnel_id=${kanbanfunnel_id}&page=${page}&per_page=${per_page}`);
    }

    async getTicketsByColumns( { kanbanfunnel_id, columns_id, page = 1, per_page = 10 }) {
        return await axios.get(`${this.url}tickets/by_column?kanbanfunnel_id=${kanbanfunnel_id}&columns_id=${columns_id}&page=${page}&per_page=${per_page}`);
    }

    async getTicketsByContact(contact_id){
        return await axios.get(`${this.url}tickets/by_contact/${contact_id}`);
    }

    async createTicket(payload) {
        return await axios.post(`${this.url}tickets`, payload);
    }

    async updateTicket(id, payload) {
        return await axios.put(`${this.url}tickets/${id}`, payload);
    }

    async deleteTicket(id) {
        return await axios.delete(`${this.url}tickets/${id}`);
    }

}

export default new ticketApi();