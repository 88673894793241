import ticketApi from '../../api/ticket';
import types from '../mutation-types';

const state = {
    tickets: [],
    updateSaleValueTicket: {}
};

export const getters = {
    getTickets(_state) {
      return _state.tickets;
    },
    getTicketSalueValueChange(_state){
        return _state.updateSaleValueTicket
    }
};

export const actions = {
    async createTicket({ commit }, payload){
        try {
            const response = await ticketApi.createTicket(payload);
            commit(types.ADD_TICKET, response.data);
            return response.data;
        } catch (err) {
            throw new Error(err);
        }
    },
    
    async getTicketsByKanban({ commit }, payload){
        try {
            commit(types.CLEAN_TICKETS);
            const response = await ticketApi.getTickets(payload);
            commit(types.SET_TICKETS, response.data?.columns);
            return response.data;
        } catch (err) {
            throw new Error(err);
        }
    },

    async getTicketsByColumnColumns({ commit }, payload){
        try {
            commit(types.CLEAN_TICKETS);
            const response = await ticketApi.getTicketsByColumns(payload);
            return response.data;
        } catch (err) {
            throw new Error(err);
        }
    },

    async getTicketsByContactId({ commit }, contact_id) {
        try {
            commit(types.CLEAN_TICKETS);
            const response = await ticketApi.getTicketsByContact(contact_id);
            commit(types.SET_TICKETS, response.data);
        } catch (err) {
            throw new Error(err);
        }
    },

    async updateTicket({ commit }, payload){
        try {
            const response = await ticketApi.updateTicket(payload.id,payload);
            commit(types.UPDATE_TICKET, response.data);
            return response.data;
        } catch (err) {
            throw new Error(err);
        }
    },
    
    async deleteTicket({ commit }, ticket_id) {
        try {
            const response = await ticketApi.deleteTicket(ticket_id);
            commit(types.REMOVE_TICKET, ticket_id);
            return response.data;
        } catch (err) {
            throw new Error(err);
        }
    },

    changeSaleValueTicket({ commit }, data){
        commit(types.UPDATE_SALUE_VALUE_TICKET, data);
    }
    

}

export const mutations = {
    [types.SET_TICKETS](state, data) {
        state.tickets = data.reverse();
    },

    [types.ADD_TICKET](state, data) {
        state.tickets.unshift(data);
    },
    [types.CLEAN_TICKETS](state) {
        state.tickets = []
    },
    [types.REMOVE_TICKET](state, ticket_id) {
        state.tickets = state.tickets.filter(kf => kf.id !== ticket_id);
    },
    [types.UPDATE_TICKET](state, data) {
        const index = state.tickets.findIndex(
          ticket => ticket.id === data.id
        );
        if (index !== -1) {
          state.tickets.splice(index, 1, data);
        }
    },
    [types.UPDATE_SALUE_VALUE_TICKET](state, data) {
        state.updateSaleValueTicket = data;
    },   
}

export default {
    actions,
    state,
    getters,
    mutations,
};