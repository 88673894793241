<template>
  <div class="contacts-page row">
    <div class="left-wrap" >
      <kanban-header
        header-title="Funil"
      />

      <h2>Souza Funnel</h2>
    </div>

   
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import KanbanHeader from './Header';

export default {
    name: "ListFunnelView",
    components: {
        KanbanHeader
    },
  
};
</script>

<style lang="scss" scoped>
.contacts-page {
  width: 100%;
}

.left-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
