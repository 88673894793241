<template>
  <div class="contact--group">
    <div class="content-left">
      <fluent-icon icon="person" class="file--icon" size="32" />
      <div class="meta">
        <p class="text-truncate margin-bottom-0">
          {{ name || phoneNumber }}
        </p>
      </div>
    </div>
    <div v-if="formattedPhoneNumber" class="link-wrap">
      <woot-button
        variant="clear"
        size="small"
        :disabled="isLoading"
        @click="handleConversations"
      >
        {{ $t('CONVERSATION.TITLE') }}
      </woot-button>
      <woot-button
        v-if="!isOutgoing"
        variant="clear"
        size="small"
        :disabled="isLoading"
        @click.prevent="addContact"
      >
        {{ $t('CONVERSATION.SAVE_CONTACT') }}
      </woot-button>
    </div>

    <new-conversation
      v-if="contact.id"
      :show="showConversationModal"
      :contact-conversations="conversations"
      :contact="contact"
      @success="closeConversationModal"
      @cancel="toggleConversationModal"
    />

    <create-contact
      :show="showCreateModal"
      :default-values="{ name: name, phoneNumber: phoneNumber }"
      @cancel="onToggleCreate"
    />
  </div>
</template>

<script>
import {
  DuplicateContactException,
  ExceptionWithMessage,
} from 'shared/helpers/CustomErrors';
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';
import NewConversation from 'dashboard/routes/dashboard/conversation/contact/NewConversation';
import CreateContact from 'dashboard/routes/dashboard/conversation/contact/CreateContact';
import router from '../../../../routes';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  components: {
    NewConversation,
    CreateContact,
  },
  mixins: [alertMixin],
  props: {
    name: {
      type: String,
      default: '',
    },
    phoneNumber: {
      type: String,
      default: '',
    },
    isOutgoing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showCreateModal: false,
      showEditModal: false,
      showConversationModal: false,
      showMergeModal: false,
      showDeleteModal: false,
      acceptChannelTypes: ['Channel::Api'],
      contact: {},
      conversations: [],
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      records: 'contacts/getContacts',
      inboxes: 'inboxes/getInboxes',
      currentUser: 'getCurrentUser',
      getAppliedContactFilters: 'contacts/getAppliedContactFilters',
      currentAccountId: 'getCurrentAccountId',
      currentChat: 'getSelectedChat',
    }),
    inbox() {
      if (this.inboxes.length === 1) return this.inboxes[0];

      return false;
    },
    currentInboxId() {
      if (this.inbox) return this.inbox?.id;

      return this.$route.params.inbox_id;
    },
    formattedPhoneNumber() {
      return this.phoneNumber.replace(/\s|-|[A-Za-z]/g, '');
    },
    rawPhoneNumber() {
      return this.phoneNumber.replace(/\D/g, '');
    },
  },

  methods: {
    async contactConversations(contact) {
      await this.$store.dispatch('contactConversations/get', contact.id);
      const responseConversations = await this.$store.getters[
        'contactConversations/getConversations'
      ];

      const itensSelecionados = [];

      Object.keys(responseConversations).forEach(key => {
        if (+key === +contact.id) {
          itensSelecionados.push(...responseConversations[key]);
        }
      });

      this.conversations = itensSelecionados;
      return itensSelecionados;
    },
    async openConversations(contact) {
      const contactConversations = await this.contactConversations(contact);
      if (this.currentInboxId) {
        return contactConversations.filter(
          conversation =>
            +conversation.inbox_id === +this?.currentChat?.inbox_id &&
            conversation.status !== 'resolved'
        );
      }

      return contactConversations.filter(
        conversation => conversation.status !== 'resolved'
      );
    },
    onToggleCreate() {
      this.showCreateModal = !this.showCreateModal;
    },
    closeConversationModal() {
      this.toggleConversationModal();

      this.$emit('toggle-contacts-view');
    },

    async addContact() {
      this.isLoading = true;
      try {
        let contact = await this.filterContactByNumber(this.rawPhoneNumber);
        if (!contact) {
          contact = await this.$store.dispatch(
            'contacts/create',
            this.getContactObject()
          );
          this.showAlert(this.$t('CONTACT_FORM.SUCCESS_MESSAGE'));
        }
        this.openContactNewTab(contact.id);
      } catch (error) {
        if (error instanceof DuplicateContactException) {
          if (error.data.includes('phone_number')) {
            this.showAlert(this.$t('CONTACT_FORM.FORM.PHONE_NUMBER.DUPLICATE'));
          }
        } else if (error instanceof ExceptionWithMessage) {
          this.showAlert(error.data);
        } else {
          this.showAlert(this.$t('CONTACT_FORM.ERROR_MESSAGE'));
        }
      }
      this.isLoading = false;
    },

    async selectedContact() {
      const contact = await this.filterContactByNumber(this.rawPhoneNumber);
      return contact;
    },

    async createConversation(payload) {
      try {
        const data = await this.onSubmit(payload);

        this.$router.push(
          `/app/accounts/${data.account_id}/conversations/${data.id}`
        );
      } catch (error) {
        if (error instanceof ExceptionWithMessage) {
          this.showAlert(error.data);
        } else {
          this.showAlert(this.$t('NEW_CONVERSATION.FORM.ERROR_MESSAGE'));
        }
      }
    },
    async onSubmit(contactItem) {
      const data = await this.$store.dispatch(
        'contactConversations/create',
        contactItem
      );
      return data;
    },

    isSingleNonOfficialWhatsappInbox() {
      return (
        this.inbox &&
        this.inbox?.is_whatsapp &&
        this.acceptChannelTypes.includes(this?.inbox?.channel_type)
      );
    },
    async handleConversations() {
      this.isLoading = true;

      const selectedContact = await this.selectedContact();
      if (!selectedContact) {
        this.onToggleCreate();
        this.isLoading = false;
        return;
      }
      await this.$store.dispatch(
        'contacts/fetchContactableInboxView',
        selectedContact
      );
      const contact = await this.$store.getters['contacts/getContactView'](
        selectedContact.id
      );
      this.contact = contact;
      const openConversations = await this.openConversations(selectedContact);
      if (
        openConversations.length &&
        (this.currentChat || this.currentInboxId)
      ) {
        const path = frontendURL(
          `accounts/${this.currentAccountId}/inbox/${this?.currentChat
            ?.inbox_id || this.currentInboxId}/conversations/${
            openConversations[0].id
          }`
        );
        router.push({ path });
      } else if (
        this.isSingleNonOfficialWhatsappInbox() &&
        this.currentInboxId
      ) {
        switch (openConversations.length) {
          case 0: {
            this.createConversation({
              inboxId: this.inbox.id,
              contactId: this.contact.id,
              assigneeId: this.currentUser.id,
            });
            break;
          }
          case 1:
            this.showAlert(this.$t('CONTACT_PANEL.ALREADY_OPEN_CONVERSATION'));
            break;
          default:
            this.showAlert(this.$t('CONTACT_PANEL.ALREADY_OPEN_CONVERSATIONS'));
            break;
        }
      } else {
        this.toggleConversationModal();
      }
      this.isLoading = false;
    },
    toggleConversationModal() {
      this.showConversationModal = !this.showConversationModal;
    },
    getContactObject() {
      const contactItem = {
        name: this.name,
        phone_number: `+${this.rawPhoneNumber}`,
      };
      return contactItem;
    },
    async filterContactByNumber(phoneNumber) {
      const query = {
        attribute_key: 'phone_number',
        filter_operator: 'equal_to',
        values: [phoneNumber],
        attribute_model: 'standard',
        custom_attribute_type: '',
      };

      const queryPayload = { payload: [query] };
      const contacts = await this.$store.dispatch('contacts/filter', {
        queryPayload,
        resetState: false,
      });
      return contacts.shift();
    },
    openContactNewTab(contactId) {
      const accountId = window.location.pathname.split('/')[3];
      const url = `/app/accounts/${accountId}/contacts/${contactId}`;
      window.open(url, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.content-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.contact--group {
  align-items: center;
  flex-direction: column;
  display: flex;
  margin-top: var(--space-smaller);

  .meta {
    flex: 1;
    margin-left: var(--space-small);
  }

  .link-wrap {
    display: flex;
    flex-direction: row;
    margin-left: var(--space-small);
  }
}
</style>
