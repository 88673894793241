<template>
  <div class="modal-mask">
    <div class="panel-wrap" @click.stop>
        <div class="flex-view">
          <h2 class="page-title">
            {{$t('GROUP_PARTICIPANT.MANAGE_TITLE')}}
          </h2>
          <woot-button color-scheme="secondary" variant="link" size="tiny" icon="dismiss" @click="$emit('close')" />
        </div>
      <div style="height: 100%;">
        <ve-table row-key-field-name="phone_number" :checkbox-option="checkboxOption" :max-height="5000" :columns="columns" :table-data="tableData" :border-around="true" />
        <table-footer :current-page="Number(setPage)"
        :total-count="groupParticipantsMeta.total_count" :page-size="groupParticipantsMeta.per_page" @page-change="onPageChange" />
        <div class="page-footer">
          <woot-button 
             class="button-footer" 
             size="small" @click="refreshTable"
              >
              {{$t('GROUP_PARTICIPANT.BUTTON_REFRESH')}}
          </woot-button>
          <woot-button 
             class="button-delete button-footer" 
             size="small" @click="toggleCheckbox"
              >
              {{!showCheckbox ? $t('GROUP_PARTICIPANT.BUTTON_DELETE'): $t('GROUP_PARTICIPANT.BUTTON_CANCEL')}}
          </woot-button>
          <woot-button v-if="showCheckbox" 
             class="button-delete button-footer" 
             size="small" @click="deleteParticipant"
              >
              {{$t('GROUP_PARTICIPANT.BUTTON_DELETE')}}
          </woot-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WootButton from '../../../../components/ui/WootButton.vue';
import TableFooter from 'dashboard/components/widgets/TableFooter';
import { VeTable } from 'vue-easytable';
import { mapGetters } from 'vuex';
export default {
  components: { WootButton, VeTable, TableFooter },
  name: 'ModalParticipantGroup',
  data() {
    return {
      setPage: 1,
      usersToBeDeleted: [],
      showCheckbox: false,
      checkboxOption: {
        selectedRowChange: ({ selectedRowKeys }) => {
          this.usersToBeDeleted = selectedRowKeys
        },
        selectedAllChange: ({ selectedRowKeys }) => {
          this.usersToBeDeleted = selectedRowKeys
        },
      },
    };
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    conversationInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
  },
  methods: {
    closePanel() {
      this.$emit('close');
    },
    toggleCheckbox() {
      this.showCheckbox = !this.showCheckbox;
    },
    handleCheckboxChange(row) {
      row.selected = !row.selected;
    },
    onPageChange(page) {
      this.setPage = page
      this.fetchDataGroup(this.setPage);
    },

    refreshTable(){
      this.fetchDataGroup()
      this.$emit('fetchData')
    },
    async fetchDataGroup(page) {
      if (this.conversationInfo?.group) {
        const group = this.conversationInfo?.group;
        const payload = {
          groupId: group.id,
          accountId: group.account_id,
          page: page,
        }
        await this.$store.dispatch('fetchGroupParticipants', payload);
      }
    },


    async deleteParticipant(){
      const account = this.getAccount(this.currentAccountId);
      const payload = {
        url: account?.url_whatsapp_web,
        jid: this.conversationInfo?.group?.jid,
        type: "remove",
        session_id: this.inbox?.session_id,
        participants: this.usersToBeDeleted,
      }
      await this.$store.dispatch('groupParticipantsConfig', payload);
      this.showCheckbox = false;
      this.refreshTable;
    },

    async promoteParticipant(id) {
      const account = this.getAccount(this.currentAccountId);
      let participantsList = []
      participantsList.push(id)
      const payload = {
        url: account?.url_whatsapp_web,
        jid: this.conversationInfo?.group?.jid,
        type: "promote",
        session_id: this.inbox?.session_id,
        participants: participantsList,
      }
      await this.$store.dispatch('groupParticipantsConfig', payload);
    },

    async demoteParticipant(id) {
      const account = this.getAccount(this.currentAccountId);
      let participantsList = []
      participantsList.push(id)
      const payload = {
        url: account?.url_whatsapp_web,
        jid: this.conversationInfo?.group?.jid,
        type: "demote",
        session_id: this.inbox?.session_id,
        participants: participantsList,
      }
      await this.$store.dispatch('groupParticipantsConfig', payload);
    },

    async addParticipant(id) {
      const account = this.getAccount(this.currentAccountId);
      let participantsList = []
      participantsList.push(id)
      const payload = {
        url: account?.url_whatsapp_web,
        jid: this.conversationInfo?.group?.jid,
        type: "add",
        session_id: this.inbox?.session_id,
        participants: participantsList,
      }
      await this.$store.dispatch('groupParticipantsConfig', payload);
      await this.$store.dispatch('fetchGroupParticipants', payload);
    },

  },
  computed: {
    ...mapGetters({
      activeInbox: 'getSelectedInbox',
      currentUser: 'getCurrentUser',
      getAccount: 'accounts/getAccount',
      currentAccountId: 'getCurrentAccountId',
      inboxes: 'inboxes/getInboxes',
      groupParticipants: 'getGroupParticipants',
      groupParticipantsMeta: 'getGroupParticipantsMeta'
    }),
    currentPage(){
      return this.groupParticipantsMeta?.current_page
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.conversationInfo?.inbox_id);
    },
    columns() {
      const baseColumns = [
      {
          field: 'name',
          key: 'name',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.NAME'),
          align: this.isRTLView ? 'right' : 'left',
          width:70,
        },
        {
          field: 'actions',
          key: 'actions',
          fixed: 'right',
          width: 60,
          title:'Administrador',
          align: 'center',
          renderBodyCell: ({ row }) => (
            <div>
              {row.is_admin && (
                <woot-button
                  onClick={() => this.demoteParticipant(row.phone_number)}
                  icon="delete"
                  class="button-delete"
                  size="tiny"
               / >
              )}
              {!row.is_admin && (
                <woot-button
                  onClick={() => this.promoteParticipant(row.phone_number)}
                  color-scheme="success"
                  icon="add-circle"
                  size="tiny"
                  />
              )}
            </div>
          ),
        },
      ];

      if (this.showCheckbox) {
        return [
          {            
            key: 'phone_number',
            title: '',
            type: 'checkbox',
            width: '50px',
          },
          ...baseColumns,
        ];
      }

      return baseColumns;

    },
    tableData() {
      if (this.isLoading) {
        return [];
      }
      return this.data.map(item => {
        return {
          ...item,
          name: item.name || '---',
          phone_number: item.phone_number || '---',
          isAdmin: item?.is_admin ,
          id: item?.id
        };
      })
    },
  }

};

</script>

<style lang="scss" scoped>
.panel-wrap {
  flex-direction: column;
  width: 25vw;
  height: fit-content;
  padding: 10px;
  background-color: var(--white);
  border-radius: var(--border-radius-medium);
  position: absolute;
  margin: var(--space-small);
}
.page-title{
  font-size: 2.2rem;
  word-wrap: break-word;
  padding: 20px 0px;
  line-height: 1.4;
  margin-bottom: 0 !important;
}

.page-footer{
  padding: 15px 0px;
}

.body {
  padding: 2rem !important;
}

.not-see {
  margin-top: 0.5rem;
}

.not-see span {
  color: var(--w-950);
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

.not-see span:hover {
  color: #01c6d8;
}

.button-delete{
  background-color: var(--r-700) !important;
  color: white !important;
} 

.button-footer{
  margin-top: -8px;
  margin-left: 16px;
}

.flex-view {
  display: flex;
  height: 9%;
  justify-content: space-between;
  padding: 0 30px;
  align-items: center;
}

.flex-space-between {
  display: flex;
  justify-content: end;
}

.header-wrap {
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--s-50);
  padding: 1rem;

  .header-title--wrap {
    align-items: center;
  }

  .header-title {
    font-size: var(--font-size-two);
    font-weight: var(--font-weight-black);
  }

  .action-button {
    margin-right: var(--space-small);
  }
}

.tab--chat-type {
  ::v-deep {
    .tabs {
      padding: 0;
    }
  }
}

.not-see {
  display: flex;
  flex-direction: row-reverse;
}

.body {
  padding: var(--space-small);
}
</style>