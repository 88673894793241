<template>
  <div class="modal-mask" @click="closePanel">
    <div class="panel-wrap" @click.stop>
      <div class="header-wrap w-full flex-space-between">
        <div class="header-title--wrap flex-view">
          <chat-type-tabs
            :items="assigneeTabItems"
            :active-tab="activeAssigneeTab"
            class="tab--chat-type"
            @chatTabChange="updateAssigneeTab"
          />
        </div>
        <div class="flex-view">
          <woot-button
            color-scheme="secondary"
            variant="link"
            size="tiny"
            icon="dismiss"
            @click="$emit('close')"
          />
        </div>
      </div>
      <div class="body">
        <div v-if="activeAssigneeTab == '1' && posts.length > 0">
          <post v-for="post in posts" :key="post._id" :post="post" />
        </div>
        <div v-if="activeAssigneeTab == '0'">
          <tutorial />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChatTypeTabs from '../../../../components/widgets/ChatTypeTabs.vue';
import Post from './Post.vue';
import Tutorial from '../../../../components/Tutorial.vue';

export default {
  name: 'NewsAndTutorialsPanel',
  components: { ChatTypeTabs, Post, Tutorial },
  data() {
    return {
      activeAssigneeTab: '0',
    };
  },
  computed: {
    posts() {
      return this.$store.getters.getPosts;
    },

    assigneeTabItems(){
      return [
        { count: null, key: '0', name: this.$t('GUIDE.TAB.START'), showBadge: false},
        { count: this.posts.length || 0, key: '1', name: this.$t('GUIDE.TAB.NEWS')}
      ]
    }
  },
  methods: {
    closePanel(event) {
      if (!event.target.closest('.panel-wrap')) {
        this.$emit('close'); 
      }
    },
    updateAssigneeTab(selectedTab) {
      this.activeAssigneeTab = selectedTab;
    },
  },

  mounted(){
    this.$store.dispatch('getPosts');
  }
};
</script>

<style lang="scss" scoped>
.panel-wrap {
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  width: 52rem;
  background-color: var(--white);
  border-radius: var(--border-radius-medium);
  position: absolute;
  margin: var(--space-small);
  right: var(--space-minus-smaller);
  top: 5rem;
  padding-bottom: 5rem;
}

.flex-view {
  display: flex;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.header-wrap {
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--s-50);
  padding: var(--space-two) var(--space-medium) var(--space-slab)
    var(--space-medium);

  .header-title--wrap {
    align-items: center;
  }

  .header-title {
    font-size: var(--font-size-two);
    font-weight: var(--font-weight-black);
  }
  .action-button {
    margin-right: var(--space-small);
  }
}

.tab--chat-type {
  ::v-deep {
    .tabs {
      padding: 0;
    }
  }
}

.body {
  padding: var(--space-small);
}
</style>
