import types from '../mutation-types';
import ConversationApi from '../../api/inbox/conversation';

const state = {
  mineCount: 0,
  unAssignedCount: 0,
  allCount: 0,
  unReadIds: [],
};

export const getters = {
  getStats: $state => ({
    mineCount: $state.mineCount,
    unAssignedCount: $state.unAssignedCount,
    allCount: $state.allCount
  }),

  getUnreadCount: $state => $state.unReadIds.length,
};

export const actions = {
  get: async ({ commit }, params) => {
    try {
      const response = await ConversationApi.meta(params);
      const {
        data: { meta },
      } = response;
      commit(types.SET_CONV_TAB_META, meta);
    } catch (error) {
      // Ignore error
    }
  },
  set({ commit }, meta) {
    commit(types.SET_CONV_TAB_META, meta);
  },
  setCountUnreadConversations: async ({ commit }) => {
    const { data } = await ConversationApi.getUnreadCount();
    commit(types.SET_COUNT_UNREAD, { data: data.unread_conversations_ids })
  },
};

export const mutations = {
  [types.SET_CONV_TAB_META](
    $state,
    { mine_count, unassigned_count, all_count }
  ) {
    $state.mineCount = mine_count
    $state.unAssignedCount = unassigned_count
    $state.allCount = all_count
  },
  [types.SET_COUNT_UNREAD](
    $state,
    { data, increment, id }
  ) {
    if (data) {
      $state.unReadIds = data;
    } else {
      const include_id = $state.unReadIds.includes(id)
      if (increment) {
        // If decrement is not true and the id is not in the unReadIds array, add the id to the array
        if (include_id) return;
        $state.unReadIds = [...$state.unReadIds, id];
      } else {
        // If decrement is true, remove the id from the unReadIds array
        if (include_id)
          $state.unReadIds = $state.unReadIds.filter(item => item !== id);
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
