<template>
  <div class="tutorial">
    <!-- <div class="card">
      <h4>{{ $t('GUIDE.TITLE_VIDEO_MAIN') }}</h4>
      <video
        src="https://api-dev.interativa.app/2024-03-06T12-57-31.338ZWiseNotification_Interativa-GoogleChrome2024-03-0609-37-28.mp4"
        controls
        playsInline
        class="modal-video skip-context-menu"
      />

      <p> {{ $t('GUIDE.DESCRIPTION_VIDEO_MAIN') }}</p>
    </div> -->

    <div class="card">
      <h4> {{ $t('GUIDE.TUTORIAL.TITLE') }}</h4>
      <button class="bg-primary btn" @click="showModalTutorial(1)">
        {{ $t('GUIDE.TUTORIAL.BTN_GUIDE_SETTINGS') }}
        <fluent-icon  icon="chevron-right" />
      </button>
      <button class="bg-primary btn" @click="showModalTutorial(2)">
        {{ $t('GUIDE.TUTORIAL.BTN_GUIDE_DASHBOARD') }}
        <fluent-icon  icon="chevron-right" />
      </button>
      <button class="bg-primary btn" @click="showModalTutorial(3)">
        {{ $t('GUIDE.TUTORIAL.BTN_GUIDE_STATUS_AGENT') }}
        <fluent-icon  icon="chevron-right" />
      </button>
      <button class="bg-primary btn" @click="showModalTutorial(4)">
        {{ $t('GUIDE.TUTORIAL.BTN_GUIDE_NOTIFICATIONS') }}
        <fluent-icon  icon="chevron-right" />
      </button>
      <button class="bg-primary btn" @click="showModalTutorial(5)">
        {{ $t('GUIDE.TUTORIAL.BTN_GUIDE_CHATBOARD') }}
        <fluent-icon  icon="chevron-right" />
      </button>
      <button class="bg-primary btn" @click="showModalTutorial(6)">
        {{ $t('GUIDE.TUTORIAL.BTN_GUIDE_PORTALS') }}
        <fluent-icon  icon="chevron-right" />
      </button>
    </div>

    <div class="card center">
      <h4>{{ $t('GUIDE.MANUAL.TITLE') }}</h4>
      <div class="manual">
        <a :href="'/app/accounts/' + this.accountId + '/manual'" target="_blank">
          <fluent-icon size="22" icon="chat-help" class="fluent-icon" />
          <p>
            {{ $t('GUIDE.MANUAL.HELP_CENTER') }}
          </p>
        </a>
        <a href="https://academy.interativa.ai/login" target="_blank">
          <fluent-icon size="22" icon="book" class="fluent-icon" />
          <p>
            {{ $t('GUIDE.MANUAL.ACADEMY') }}
          </p>
        </a>
        <a href="https://interativa.simplybook.me/v2" target="_blank">
          <fluent-icon size="22" icon="person" class="fluent-icon" />
          <p>
            {{ $t('GUIDE.MANUAL.SPECIALIST_SCHEDULE') }}
          </p>
        </a>
        <a href="https://wa.me/5531996825829" target="_blank">
          <fluent-icon size="22" icon="chat-help" class="fluent-icon" />
          <p>
            {{ $t('GUIDE.MANUAL.SUPPORT') }}
          </p>
        </a>
      </div>
    </div>

    <modal-tour  
      v-if="showTutorial"
      :steps="stepSelected"
      @close="closeModalTutorial"
    />
  </div>
</template>

<script>
import EmojiOrIcon from '../../shared/components/EmojiOrIcon.vue';
import ModalTour from '../routes/dashboard/notifications/components/ModalTour.vue'
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'Tutorial',
  mixins: [globalConfigMixin],
  components: { ModalTour, EmojiOrIcon },
  data: () => ({
    stepSelected: [],
    blurEl: '.can-tour-blur',
    debug: true,
    showTutorial: false
  }),

  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),

    steps(){
      const stepsApp = [
      [{
        preview: require('../assets/tutorials/guia-interativa.gif'),
        title: this.$t('GUIDE.TUTORIAL.GUIDE_INTERATIVA.TITLE'),
        description: this.$t('GUIDE.TUTORIAL.GUIDE_INTERATIVA.LABEL'),
      }],
      [{
        preview: require('../assets/tutorials/configuracao_sistema.gif'),
        title: this.$t('GUIDE.TUTORIAL.SETTINGS.TITLE'),
        description: this.$t('GUIDE.TUTORIAL.SETTINGS.LABEL'),
      }],
      [{
        preview: require('../assets/tutorials/dashboard.gif'),
        title: this.$t('GUIDE.TUTORIAL.DASHBOARD.TITLE'),
        description: this.$t('GUIDE.TUTORIAL.DASHBOARD.LABEL'),
      }],
      [{
        preview: require('../assets/tutorials/status_agente.gif'),
        title: this.$t('GUIDE.TUTORIAL.STATUS_AGENT.TITLE'),
        description: this.$t('GUIDE.TUTORIAL.STATUS_AGENT.LABEL'),
      }],
      [{
        preview: require('../assets/tutorials/notifications.gif'),
        title: this.$t('GUIDE.TUTORIAL.NOTIFICATIONS.TITLE'),
        description: this.$t('GUIDE.TUTORIAL.NOTIFICATIONS.LABEL'),
      }],
      [{
        preview: require('../assets/tutorials/chatboard2.gif'),
        title: this.$t('GUIDE.TUTORIAL.CHATBOARD.TITLE'),
        description: this.$t('GUIDE.TUTORIAL.CHATBOARD.LABEL'),
      }],
      [{
        preview: require('../assets/tutorials/portais.gif'),
        title: this.$t('GUIDE.TUTORIAL.PORTALS.TITLE'),
        description: this.$t('GUIDE.TUTORIAL.PORTALS.LABEL'),
      }]
    ]
      return stepsApp;
    }
    
  },

  methods: {
    showModalTutorial(index){
      this.stepSelected = this.steps[index];
      this.showTutorial = true;
    },

    closeModalTutorial(){
      this.showTutorial = false;
    }
  }
}
</script>

<style scoped>

.card {
  gap: 1rem;
  padding: var(--space-normal);
}

h4 {
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 160%;
  color: #01c6d8;
}
p {
  font-size: 1.6rem;
  color: var(--s-700);
}
.center {
  justify-content: center;
}

.manual {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.manual a {
  background: #01c6d8;
  color: #fff;
  padding: 1rem;
  height: 12rem;
  min-width: 48%;
  border-radius: 5px;
  display: flex;
  gap: 0.5rem;
}
.manual a:hover, .btn:hover {
  background: #019ead;
}

.manual p {
  color: #fff !important;
  font-size: 1.5rem !important;
}

.btn {
  font-size: 1.6rem;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 0.5rem;
}
</style>