<template>
  <woot-modal :show.sync="show" :on-close="onCancel">
    <div class="column content-box">
        <woot-modal-header
          :header-title="$t('TICKET.CREATE.TITLE')"
          :header-content="$t('TICKET.CREATE.DESC')"
        />
        <form class="roww" @submit.prevent="createTicket()">
          <div>
            <label :class="{ error: $v.text.$error }">
              {{ $t('TICKET.FORM.TEXT.LABEL') }}
              <input
                v-model.trim="text"
                type="text"
                :placeholder="$t('TICKET.FORM.TEXT.PLACEHOLDER')"
                @input="$v.text.$touch"
              />
            </label>
          </div>
          <div class="description">
            <label :class="{ error: $v.description.$error }">
              {{ $t('TICKET.FORM.DESCRIPTION.LABEL') }}
              <textarea
                rows="2"
                v-model.trim="description"
                :placeholder="$t('TICKET.FORM.DESCRIPTION.PLACEHOLDER')"
                @input="$v.description.$touch"
              />
            </label>
          </div>

          <div>
            <label :class="{ error: $v.kanbanfunnelSelected.$error }">
              {{ $t('TICKET.FORM.KANBAN.LABEL') }}
              <select v-model="kanbanfunnelSelected">
                <option value="" disabled>{{ this.$t('TICKET.FORM.KANBAN.PLACEHOLDER') }}</option>
                <option v-for="kanban in listKanbanActive" :key="kanban.id" :value="kanban">
                  {{ kanban.name }}
                </option>
              </select>
            </label>
          </div>

          <div v-if="kanbanfunnelSelected">
            <label :class="{ error: $v.columnSelected.$error }">
              {{ $t('TICKET.FORM.COLUMN.LABEL') }}
              <select v-model="columnSelected">
                <option value="" disabled>{{ this.$t('TICKET.FORM.COLUMN.PLACEHOLDER') }}</option>
                <option v-for="colum in kanbanfunnelSelected.columns" :key="colum.id" :value="colum">
                  {{ colum.name }}
                </option>
              </select>
            </label>
          </div>


          <div v-if="isFunnelSale">
            <label>
              {{ $t('TICKET.FORM.SALE_VALUE.LABEL') }}
              <input
                v-money="money"
                v-model="saleValue"
                type="text"
                class="input-margin-bottom"
                :placeholder="$t('TICKET.FORM.SALE_VALUE.PLACEHOLDER')"
              />
            </label>
          </div>

          <div>
            <label :class="{ error: $v.prioritySelected.$error }">
              {{ $t('TICKET.FORM.PRIORITY.LABEL') }}
              <select v-model="prioritySelected">
                <option value="" disabled>{{ this.$t('TICKET.FORM.PRIORITY.PLACEHOLDER') }}</option>
                <option v-for="(priority, index) in priorityOptions" :key="index" :value="priority.value">
                  {{ priority.name }}
                </option>
              </select>
            </label>
          </div>

          <div>
            <settings-section 
              class="settings-select" 
              :title="$t('TICKET.FORM.LABEL.LABEL')"
              :sub-title="$t('TICKET.FORM.LABEL.DESC')"
            >
              <multiselect v-model="labelsIds" :options="labels" track-by="id" label="title" :multiple="true"
                :close-on-select="false" :clear-on-select="false" :hide-selected="true" placeholder="Pick some" selected-label
                :select-label="$t('FORMS.MULTISELECT.CLICK_TO_SELECT')" :deselect-label="$t('FORMS.MULTISELECT.CLICK_TO_REMOVE')"
              />
            </settings-section>
  
          </div>

          <div class="modal-footer">
            <div class="medium-12 columns">
              <woot-submit-button
                :disabled="$v.$invalid || showLoading"
                :button-text="$t('CRM_MGMT.FORM.CREATE')"
                :loading="showLoading"
                class="bg-primary"
              />
              <button class="button clear" @click.prevent="onClose">
                {{ $t('CRM_MGMT.FORM.CANCEL') }}
              </button>
            </div>
          </div>
        </form>
      </div>
  </woot-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import { required, minLength } from 'vuelidate/lib/validators';
import SettingsSection from '../../../../components/SettingsSection.vue';

export default {
  name: 'ModalNewTicket',
  components: {
    SettingsSection
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Object,
      default: () => ({}),
    }
  },
  data(){
    return {
      text: '',
      description: '',
      prioritySelected: 'medium',
      kanbanfunnelSelected: {},
      columnSelected: {},
      labelsIds: [],
      labelSelected: null,
      showLoading: false,
      saleValue: null,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2
      }
    }
  },
  validations: {
    text: {
      required,
      minLength: minLength(2),
    },
    description: {},
    kanbanfunnelSelected: {
      required,
    },
    columnSelected: {
      required,
    },
    prioritySelected: {
      required
    }
  },
  computed: {
    ...mapGetters({
      labels: 'labels/getLabels',
      currentUser: 'getCurrentUser',
      kanbanList: 'getKanbanfunnels'
    }),

    isFunnelSale(){
      return this.kanbanfunnelSelected.enable_sales_funnel;
    },

    priorityOptions(){
      return [
        { name: this.$t('TICKET.FORM.PRIORITY.OPTIONS.LOW'), value: 'low'},
        { name: this.$t('TICKET.FORM.PRIORITY.OPTIONS.MEDIUM'), value: 'medium'},
        { name: this.$t('TICKET.FORM.PRIORITY.OPTIONS.HIGH'), value: 'high'} 
      ]
    },

    listKanbanActive(){
      return this.kanbanList?.filter((kanban) => kanban.active);
    }
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onSuccess() {
      this.$emit('success');
    },

    async createTicket(){
      if(!this.isFunnelSale){
        this.saleValue = null;
      }

      const numericSaleValue = this.saleValue 
        ? Number(this.saleValue.replace(/[^\d]/g, '')) / 100 
        : null;
      
      const payload = {
        user_id: this.currentUser.id,
        contact_id: this.contact.id,
        priority: this.prioritySelected,
        text: this.text,
        description: this.description,
        kanbanfunnel_id: this.kanbanfunnelSelected.id,
        columns_id: this.columnSelected.id,
        labels_ids: this.labelsIds?.map((label) => label.id),
        sale_value: numericSaleValue
      }

      await this.$store.dispatch('createTicket', payload);
      this.showAlert(this.$t('TICKET.CREATE.API.SUCCESS_MESSAGE'));
      this.onCancel();
    },

    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
  },

  watch: {
    kanbanfunnelSelected(newVal) {
      if (newVal) {
        this.columnSelected = {};
      }
    }
  }

};
</script>

<style scoped>
.form {
  padding: var(--space-normal) var(--space-large) var(--space-large);
}

.card-content {
  display: flex;
  gap: 2rem;
}

.input-margin-bottom {
  margin-bottom: 16px;
}

.description{
  min-width: 220px;
}
</style>