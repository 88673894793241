import { render, staticRenderFns } from "./BotReports.vue?vue&type=template&id=485c8f52&scoped=true&"
import script from "./BotReports.vue?vue&type=script&lang=js&"
export * from "./BotReports.vue?vue&type=script&lang=js&"
import style0 from "./BotReports.vue?vue&type=style&index=0&id=485c8f52&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "485c8f52",
  null
  
)

export default component.exports