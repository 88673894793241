import MessageFormatter from '../helpers/MessageFormatter';

export default {
  methods: {
    formatMessage(message, isATweet, isAPrivateNote) {
      const messageFormatter = new MessageFormatter(
        message,
        isATweet,
        isAPrivateNote
      );
      return messageFormatter.formattedMessage;
    },
    getPlainText(message, isATweet) {
      const messageFormatter = new MessageFormatter(message, isATweet);
      return messageFormatter.plainText;
    },
    truncateMessage(description = '') {
      if (description.length < 100) {
        return description;
      }

      return `${description.slice(0, 97)}...`;
    },

    greetingFormat() {
      const hourNow = new Date().getHours();

      if (hourNow >= 0 && hourNow < 12) {
        return this.$t('CONVERSATION.GREETING_MESSAGE.MORNING');
      } else if (hourNow >= 12 && hourNow < 18) {
        return this.$t('CONVERSATION.GREETING_MESSAGE.AFTERNOON');
      } else {
        return this.$t('CONVERSATION.GREETING_MESSAGE.EVENING');
      }
    },
  },
};
