<template>
  <div class="primary--sidebar">
    <nav class="menu vertical">
      <primary-nav-item
        v-for="menuItem in menuItems"
        :key="menuItem.toState"
        :icon="menuItem.icon"
        :name="menuItem.label"
        :to="menuItem.toState"
        :is-child-menu-active="menuItem.key === activeMenuItem"
      />
    </nav>
      <div 
        class="card-icon manual-icon" 
        v-tooltip.right="$t(`NOTIFICATIONS_PAGE.HELP`)"
        @click="opensPanelNewsAndTutorials"  
      >
        <img class="faq" :src="require('../../../assets/images/open-book.png')" alt="faq">
      </div>

    <news-and-tutorials-panel
      v-if="showPanelNewsAndTutorials"
      @close="closePanelNewsAndTutorials"
    />
    <modal-tour
      v-if="showTutorial"
      :closeTutorial="showTutorial" 
      :steps="steps"
      @close="closeModalTutorial"
    />
  </div>
</template>
<script>
import Logo from './Logo';
import PrimaryNavItem from './PrimaryNavItem';
import OptionsMenu from './OptionsMenu';
import AgentDetails from './AgentDetails';
import NotificationBell from './NotificationBell';
import NewsAndTutorialsPanel from '../../../routes/dashboard/notifications/components/NewsAndTutorialsPanel.vue';
import wootConstants from 'dashboard/constants/globals';
import { frontendURL } from 'dashboard/helper/URLHelper';
import ModalTour from '../../../routes/dashboard/notifications/components/ModalTour.vue';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';

export default {
  components: {
    Logo,
    PrimaryNavItem,
    OptionsMenu,
    AgentDetails,
    NotificationBell,
    NewsAndTutorialsPanel,
    ModalTour
  },
  mixins: [uiSettingsMixin],
  props: {
    isACustomBrandedInstance: {
      type: Boolean,
      default: false,
    },
    logoSource: {
      type: String,
      default: '',
    },
    installationName: {
      type: String,
      default: '',
    },
    accountId: {
      type: Number,
      default: 0,
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    activeMenuItem: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      helpDocsURL: wootConstants.DOCS_URL,
      showOptionsMenu: false,
      showPanelNewsAndTutorials: false,
      showTutorial: false
    };
  },

  computed: {
    steps(){
      const stepsApp = [
        {
          preview: require('../../../assets/tutorials/guiamain.gif'),
          title: this.$t('GUIDE.TUTORIAL.GUIDE_INTERATIVA.TITLE'),
          description: this.$t('GUIDE.TUTORIAL.GUIDE_INTERATIVA.LABEL'),
        }
      ]
      return stepsApp;
    }
  },
  mounted(){
    const showMainTutorial = this.uiSettings?.show_main_tutorial;
    if(showMainTutorial || showMainTutorial == undefined){
      setTimeout(() => this.showTutorial = true, 3000);
    }
  },
  methods: {
    frontendURL,
    toggleOptions() {
      this.showOptionsMenu = !this.showOptionsMenu;
    },
    toggleAccountModal() {
      this.$emit('toggle-accounts');
    },
    toggleSupportChatWindow() {
      window.$chatwoot.toggle();
    },
    openNotificationPanel() {
      this.$emit('open-notification-panel');
    },

    opensPanelNewsAndTutorials(){
      this.showPanelNewsAndTutorials = true;
    },

    closePanelNewsAndTutorials(){
      this.showPanelNewsAndTutorials = false;
    },
    closeModalTutorial(){
      this.showTutorial = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.primary--sidebar {
  display: flex;
  flex-direction: column;
  width: var(--space-jumbo);
  border-right: 1px solid var(--s-50);
  box-sizing: content-box;
  height: 100%;
  flex-shrink: 0;
}

.menu {
  align-items: center;
  // margin-top: var(--space-medium);
}

.user-menu {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
  margin-bottom: var(--space-normal);
}

.faq {
  height: 22px;
}
.manual-icon{
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 2rem;
  left: 1.2rem;
  background: var(--g-20) !important;
  border: 1px solid var(--g-20) !important;
}

.card-icon {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0.9rem;
  height: 40px;
  width: 40px;
  border: 1px solid transparent !important;
}

.card-icon:hover, .card-icon:active  {
  background: var(--g-20) !important;
  border: 1px solid var(--g-20) !important;
}
</style>
