<template>
  <div
    v-if="!authUIFlags.isFetching"
    id="app"
    class="app-wrapper app-root"
    :class="{ 'app-rtl--wrapper': isRTLView }"
  >
    <link
      rel="stylesheet"
      href="https://cdn-uicons.flaticon.com/uicons-solid-rounded/css/uicons-solid-rounded.css"
    />
    <link
      rel="stylesheet"
      href="https://cdn-uicons.flaticon.com/uicons-regular-straight/css/uicons-regular-straight.css"
    />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <add-account-modal
      :show="showAddAccountModal"
      :has-accounts="hasAccounts"
    />
    <woot-snackbar-box />
    <network-notification />
  </div>
  <loading-state v-else />
</template>

<script>
import { mapGetters } from 'vuex';
import AddAccountModal from '../dashboard/components/layout/sidebarComponents/AddAccountModal';
import LoadingState from './components/widgets/LoadingState.vue';
import NetworkNotification from './components/NetworkNotification';
import vueActionCable from './helper/actionCable';
import WootSnackbarBox from './components/SnackbarContainer';
import rtlMixin from 'shared/mixins/rtlMixin';
import {
  registerSubscription,
  verifyServiceWorkerExistence,
} from './helper/pushHelper';

export default {
  name: 'App',

  components: {
    AddAccountModal,
    LoadingState,
    NetworkNotification,
    WootSnackbarBox,
  },

  mixins: [rtlMixin],

  data() {
    return {
      showAddAccountModal: false,
      latestChatwootVersion: null,
    };
  },

  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
      authUIFlags: 'getAuthUIFlags',
      currentAccountId: 'getCurrentAccountId',
      getUnreadConversationsCount: 'conversationStats/getUnreadCount',
    }),
    hasAccounts() {
      const { accounts = [] } = this.currentUser || {};
      return accounts.length > 0;
    },
  },

  watch: {
    currentUser() {
      if (!this.hasAccounts) {
        this.showAddAccountModal = true;
      }
    },
    currentAccountId() {
      if (this.currentAccountId) {
        this.initializeAccount();
      }
    },
    getUnreadConversationsCount() {
      if (this.getUnreadConversationsCount) {
        document.title = `(${this.getUnreadConversationsCount}) Omnidesk`;
      } else {
        document.title = `Omnidesk`;
      }
    },
  },
  mounted() {
    this.setLocale(window.chatwootConfig.selectedLocale);
    this.$store.dispatch('conversationStats/setCountUnreadConversations');

    (function(d, s, id) {
      var js;
      var fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');

    window.fbAsyncInit = function() {
      FB.init({
        appId: window.chatwootConfig.wbAppId,
        autoLogAppEvents: true,
        xfbml: true,
        cookie: true,
        version: 'v20.0',
      });

      FB.AppEvents.logPageView();
    };
  },
  methods: {
    setLocale(locale) {
      this.$root.$i18n.locale = locale;
    },
    async initializeAccount() {
      await this.$store.dispatch('accounts/get');
      this.$store.dispatch('setActiveAccount', {
        accountId: this.currentAccountId,
      });
      const {
        locale,
        latest_chatwoot_version: latestChatwootVersion,
      } = this.getAccount(this.currentAccountId);
      const { pubsub_token: pubsubToken } = this.currentUser || {};
      this.setLocale(locale);
      this.updateRTLDirectionView(locale);
      this.latestChatwootVersion = latestChatwootVersion;
      vueActionCable.init(pubsubToken);

      verifyServiceWorkerExistence(registration =>
        registration.pushManager.getSubscription().then(subscription => {
          if (subscription) {
            registerSubscription();
          }
        })
      );
    },
  },
};
</script>

<style lang="scss">
@import './assets/scss/app';
</style>

<style>
.mt-50 {
  margin-top: 55px !important;
}
.bg-primary,
.button.success,
.nice {
  background-color: var(--g-20);
  color: var(--s-50);
}

.button[disabled].success,
.bg-primary[disabled] {
  background-color: var(--g-20) !important;
  color: var(--s-50) !important;
  opacity: 0.4 !important;
}

.button.success:hover,
.button.success:focus {
  background-color: var(--g-30) !important;
  color: var(--s-50) !important;
}

.btn-primary-create,
.btn-primary,
.clear,
.sub-block-title {
  color: var(--g-20) !important ;
}

input:focus,
textarea:focus {
  outline: none !important;
  border-color: var(--g-20) !important;
  box-shadow: 0 0 3px var(--g-20) !important;
}

.modal--close,
.button--only-icon {
  color: var(--s-700) !important;
}

.secondary {
  color: var(--s-700) !important;
}

.pac-container {
  z-index: 9999 !important;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
