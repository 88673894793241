import { render, staticRenderFns } from "./KanbanSelector.vue?vue&type=template&id=239f453b&scoped=true&"
import script from "./KanbanSelector.vue?vue&type=script&lang=js&"
export * from "./KanbanSelector.vue?vue&type=script&lang=js&"
import style0 from "./KanbanSelector.vue?vue&type=style&index=0&id=239f453b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "239f453b",
  null
  
)

export default component.exports