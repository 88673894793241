import webmasterAxios from './webmasterApi';

class PostsApi {
  constructor() {
    this.endpoint = '/posts';
  }

  getAll() {
    return webmasterAxios.get(this.endpoint);
  }

}

export default new PostsApi();
