<template>
    <transition name="menu-slide">
      <div
        v-if="show"
        v-on-clickaway="onClickAway"
        class="options-menu dropdown-pane"
        :class="{ 'dropdown-pane--open': show }"
      >
        <availability-status />

      </div>
    </transition>
  </template>
  
  <script>
  import { mixin as clickaway } from 'vue-clickaway';
  import { mapGetters } from 'vuex';
  import Auth from '../../../api/auth';
  import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem';
  import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu';
  import AvailabilityStatus from 'dashboard/components/layout/AvailabilityStatus';
  
  export default {
    name: "OptionsConfigUser",
    components: {
      WootDropdownMenu,
      WootDropdownItem,
      AvailabilityStatus,
    },
    mixins: [clickaway],
    props: {
      show: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters({
        currentUser: 'getCurrentUser',
        globalConfig: 'globalConfig/get',
        accountId: 'getCurrentAccountId',
      }),
      showChangeAccountOption() {
        if (this.globalConfig.createNewAccountFromDashboard) {
          return true;
        }
  
        const { accounts = [] } = this.currentUser;
        return accounts.length > 1;
      },
    },
    methods: {
      handleProfileSettingClick(e, navigate) {
        this.$emit('close');
        navigate(e);
      },
      handleKeyboardHelpClick() {
        this.$emit('key-shortcut-modal');
        this.$emit('close');
      },
      logout() {
        Auth.logout();
      },
      onClickAway() {
        if (this.show) this.$emit('close');
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .options-menu.dropdown-pane {
    left: unset;
    right: var(--space-slab);
    bottom: unset;
    top: var(--space-larger);
    min-width: var(--space-giga);
      position: absolute;
  }
  </style>
  