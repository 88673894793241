<template>
  <form class="contact--form" @submit.prevent="handleSubmit">
    <div class="row">
      <div class="columns">
        <woot-avatar-uploader
          :label="$t('CONTACT_FORM.FORM.AVATAR.LABEL')"
          :src="avatarUrl"
          :username-avatar="name"
          :delete-avatar="!!avatarUrl"
          class="settings-item"
          @change="handleImageUpload"
          @onAvatarDelete="handleAvatarDelete"
        />
      </div>
    </div>
    <div class="row">
      <div class="columns">
        <label :class="{ error: $v.name.$error }">
          {{ $t('CONTACT_FORM.FORM.NAME.LABEL') }}
          <input
            v-model.trim="name"
            type="text"
            :placeholder="$t('CONTACT_FORM.FORM.NAME.PLACEHOLDER')"
            @input="$v.name.$touch"
          />
        </label>

        <label :class="{ error: $v.email.$error }">
          {{ $t('CONTACT_FORM.FORM.EMAIL_ADDRESS.LABEL') }}
          <input
            v-model.trim="email"
            type="text"
            :placeholder="$t('CONTACT_FORM.FORM.EMAIL_ADDRESS.PLACEHOLDER')"
            @input="$v.email.$touch"
          />
          <span v-if="$v.email.$error" class="message">
            {{ $t('CONTACT_FORM.FORM.EMAIL_ADDRESS.ERROR') }}
          </span>
        </label>
      </div>
    </div>
    <div class="medium-12 columns">
      <label :class="{ error: $v.description.$error }">
        {{ $t('CONTACT_FORM.FORM.BIO.LABEL') }}
        <textarea
          v-model.trim="description"
          type="text"
          :placeholder="$t('CONTACT_FORM.FORM.BIO.PLACEHOLDER')"
          @input="$v.description.$touch"
        />
      </label>
    </div>
    <div class="row">
      <div class="medium-12 columns">
        <label
          :class="{
            error: isPhoneNumberNotValid,
          }"
        >
          {{ $t('CONTACT_FORM.FORM.PHONE_NUMBER.LABEL') }}
          <woot-phone-input
            v-model="phoneNumber"
            :value="phoneNumber"
            :error="isPhoneNumberNotValid"
            :success="isWhatsappNotValid"
            :placeholder="$t('CONTACT_FORM.FORM.PHONE_NUMBER.PLACEHOLDER')"
            @input="onPhoneNumberInputChange"
            @blur="$v.phoneNumber.$touch"
            @setCode="setPhoneCode"
          />
          <span v-if="isPhoneNumberNotValid" class="message">
            {{ phoneNumberError }}
          </span>
          <span v-if="isWhatsappNotValid && !isPhoneNumberNotValid" class="successMessage">
            {{ whatsappError }}
          </span>
        </label>
        <div
          v-if="isPhoneNumberNotValid || !phoneNumber"
          class="callout small warning"
        >
          {{ $t('CONTACT_FORM.FORM.PHONE_NUMBER.HELP') }}
        </div>
      </div>
    </div>
    <woot-input
      v-model.trim="companyName"
      class="columns"
      :label="$t('CONTACT_FORM.FORM.COMPANY_NAME.LABEL')"
      :placeholder="$t('CONTACT_FORM.FORM.COMPANY_NAME.PLACEHOLDER')"
    />
    <div class="row">
      <div class="medium-12 columns">
        <label>
          {{ $t('CONTACT_FORM.FORM.COUNTRY.LABEL') }}
        </label>
        <multiselect
          v-model="country"
          track-by="id"
          label="name"
          :placeholder="$t('CONTACT_FORM.FORM.COUNTRY.PLACEHOLDER')"
          selected-label
          :select-label="$t('CONTACT_FORM.FORM.COUNTRY.SELECT_PLACEHOLDER')"
          :deselect-label="$t('CONTACT_FORM.FORM.COUNTRY.REMOVE')"
          :custom-label="countryNameWithCode"
          :max-height="160"
          :options="countries"
          :allow-empty="true"
          :option-height="104"
        />
      </div>
    </div>
    <woot-input
      v-model="city"
      class="columns"
      :label="$t('CONTACT_FORM.FORM.CITY.LABEL')"
      :placeholder="$t('CONTACT_FORM.FORM.CITY.PLACEHOLDER')"
    />
    <div class="row">
      <div class="medium-12 columns">
        <label>
          {{ $t('CONTACT_FORM.FORM.WALLET.LABEL') }}
        </label>
        <select
          v-if="isAdmin || currentUser.id == contact.user_id"
          v-model="selectedAgent"
        >
          <option value="" selected>
            {{ $t('CONTACT_FORM.FORM.WALLET.NONE') }}
          </option>
          <option v-for="agent in agents" :key="agent.name" :value="agent">
            {{ agent.name }}
          </option>
        </select>
        <woot-button
          v-else-if="!selectedAgent"
          variant="smooth"
          size="tiny"
          icon="add"
          color-scheme="success"
          @click="attachWallet()"
        >
          {{ $t('CONTACT_FORM.FORM.WALLET.ADD_LABEL') }}
        </woot-button>
        <input
          v-else-if="selectedAgent"
          v-model.trim="selectedAgent.name"
          type="text"
          readonly
        />
      </div>
    </div>
    <div class="medium-12 columns">
      <label>
        {{ $t('CONTACT_FORM.FORM.SOCIAL_PROFILES.LABEL') }}
      </label>
      <div
        v-for="socialProfile in socialProfileKeys"
        :key="socialProfile.key"
        class="input-group"
      >
        <span class="input-group-label">{{ socialProfile.prefixURL }}</span>
        <input
          v-model="socialProfileUserNames[socialProfile.key]"
          class="input-group-field"
          type="text"
        />
      </div>
    </div>
    <div class="modal-footer">
      <div class="medium-12 columns">
        <woot-submit-button
          :loading="inProgress"
          :disabled="inProgress"
          class="bg-primary"
          :button-text="$t('CONTACT_FORM.FORM.SUBMIT')"
        />
        <button class="button clear btn-primary" @click.prevent="onCancel">
          {{ $t('CONTACT_FORM.FORM.CANCEL') }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import parsePhoneNumber from 'libphonenumber-js';
import countries from 'shared/constants/countries.js';
import {
  DuplicateContactException,
  ExceptionWithMessage,
} from 'shared/helpers/CustomErrors';
import { isPhoneNumberValid } from 'shared/helpers/Validators';
import alertMixin from 'shared/mixins/alertMixin';
import { email, required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import adminMixin from '../../../../mixins/isAdmin';

export default {
  mixins: [alertMixin, adminMixin],
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
    inProgress: {
      type: Boolean,
      default: false,
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
    defaultValues: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      countries: countries,
      companyName: '',
      description: '',
      email: '',
      name: '',
      phoneNumber: '',
      activeDialCode: '',
      warning: '',
      avatarFile: null,
      avatarUrl: '',
      selectedAgent: '',
      country: {
        id: '',
        name: '',
      },
      city: '',
      socialProfileUserNames: {
        facebook: '',
        twitter: '',
        linkedin: '',
        github: '',
      },
      socialProfileKeys: [
        { key: 'facebook', prefixURL: 'https://facebook.com/' },
        { key: 'twitter', prefixURL: 'https://twitter.com/' },
        { key: 'linkedin', prefixURL: 'https://linkedin.com/' },
        { key: 'github', prefixURL: 'https://github.com/' },
      ],
    };
  },
  validations: {
    name: {
      required,
    },
    description: {},
    email: {
      email,
    },
    companyName: {},
    phoneNumber: {},
    bio: {},
  },
  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      currentAccountId: 'getCurrentAccountId',
      currentUser: 'getCurrentUser',
      agents: 'agents/getAgents',
    }),
    parsePhoneNumber() {
      return parsePhoneNumber(this.phoneNumber);
    },
    isPhoneNumberNotValid() {
      if (this.phoneNumber !== '') {
        return (
          !isPhoneNumberValid(this.phoneNumber, this.activeDialCode) ||
          (this.phoneNumber !== '' ? this.activeDialCode === '' : false)
        );
      }
      return false;
    },
    isWhatsappNotValid() {
      if (this.warning === 'true') {
        return true;
      }
      return false;
    },
    phoneNumberError() {
      if (this.activeDialCode === '') {
        return this.$t('CONTACT_FORM.FORM.PHONE_NUMBER.DIAL_CODE_ERROR');
      }
      if (!isPhoneNumberValid(this.phoneNumber, this.activeDialCode)) {
        return this.$t('CONTACT_FORM.FORM.PHONE_NUMBER.ERROR');
      }
      return '';
    },
    whatsappError() {
      if (this.warning === 'true') {
        return this.$t('CONTACT_FORM.FORM.PHONE_NUMBER.VALID_WHATSAPP');
      }
      return '';
    },
    setPhoneNumber() {
      if (this.parsePhoneNumber && this.parsePhoneNumber.countryCallingCode) {
        return this.phoneNumber;
      }
      if (this.phoneNumber === '' && this.activeDialCode !== '') {
        return '';
      }
      return this.activeDialCode
        ? `${this.activeDialCode}${this.phoneNumber}`
        : '';
    },
  },
  watch: {
    contact() {
      this.contact = this.$store.getters['contacts/getContact'](
        this.contact.id
      );
      this.setContactObject();
    },
  },
  mounted() {
    this.setContactObject();
    this.setDialCode();
    this.getAgents();
  },
  methods: {
    async getAgents() {
      await this.$store.dispatch('agents/get').then(response => {
        this.agents = response.data;
      });
      this.setContactObject();
    },
    attachWallet() {
      this.selectedAgent = this.currentUser;
    },
    onCancel() {
      this.$emit('cancel');
    },
    async checkUser() {
     const account = this.getAccount(this.currentAccountId);
     await this.$store.dispatch('contacts/verifyUser', {
            url: account.url_whatsapp_web+'api/v1/sessions',
            phone: this.phoneNumber,
          }).then(response => {
            this.warning = response;
            this.inProgress = false;
          }).catch(error =>
            console.log(error)
          );
    },
    onSuccess() {
      this.$emit('success');
    },
    countryNameWithCode({ name, id }) {
      if (!id) return name;
      if (!name && !id) return '';
      return `${name} (${id})`;
    },
    setDialCode() {
      if (
        this.phoneNumber !== '' &&
        this.parsePhoneNumber &&
        this.parsePhoneNumber.countryCallingCode
      ) {
        const dialCode = this.parsePhoneNumber.countryCallingCode;
        this.activeDialCode = `+${dialCode}`;
      }
    },
    setContactObject() {
      const {
        email: emailAddress,
        phone_number: phoneNumber,
        name,
        user_id,
      } = this.contact;
      const additionalAttributes = this.contact.additional_attributes || {};

      this.name = name || this.defaultValues?.name || '';
      this.email = emailAddress || '';
      this.phoneNumber =
        phoneNumber ||
        this.defaultValues?.phoneNumber?.replace(/\s|-|[A-Za-z]/g, '') ||
        '';
      this.companyName = additionalAttributes.company_name || '';
      this.country = {
        id: additionalAttributes.country_code || '',
        name:
          additionalAttributes.country ||
          this.$t('CONTACT_FORM.FORM.COUNTRY.SELECT_COUNTRY'),
      };
      this.city = additionalAttributes.city || '';
      this.description = additionalAttributes.description || '';
      this.avatarUrl = this.contact.thumbnail || '';
      this.selectedAgent = this.filterAgent(user_id);
      const {
        social_profiles: socialProfiles = {},
        screen_name: twitterScreenName,
      } = additionalAttributes;
      this.socialProfileUserNames = {
        twitter: socialProfiles.twitter || twitterScreenName || '',
        facebook: socialProfiles.facebook || '',
        linkedin: socialProfiles.linkedin || '',
        github: socialProfiles.github || '',
        instagram: socialProfiles.instagram || '',
      };
    },
    filterAgent(user_id) {
      return this.agents.find(agent => agent.id === user_id) || '';
    },
    whatsappValid(status) {
      if (status === 'invalid'){
        return false;
      }
      if (status === 'disconnected'){
        return null;
      }
      return true;
    },
    getContactObject() {
      if (this.country === null) {
        this.country = {
          id: '',
          name: '',
        };
      }
      const contactObject = {
        id: this.contact.id,
        name: this.name,
        email: this.email,
        whatsapp_valid: this.whatsappValid(this.warning),
        phone_number: this.setPhoneNumber,
        user_id: this.selectedAgent?.id || '',
        additional_attributes: {
          ...this.contact.additional_attributes,
          description: this.description,
          company_name: this.companyName,
          country_code: this.country.id,
          country:
            this.country.name ===
            this.$t('CONTACT_FORM.FORM.COUNTRY.SELECT_COUNTRY')
              ? ''
              : this.country.name,
          city: this.city,
          social_profiles: this.socialProfileUserNames,
        },
      };
      if (this.avatarFile) {
        contactObject.avatar = this.avatarFile;
        contactObject.isFormData = true;
      }
      return contactObject;
    },
    onPhoneNumberInputChange(value, code) {
      this.activeDialCode = code;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        if (this.phoneNumber.length > 8){
          this.inProgress = true;
          this.checkUser();
        }
      },1000)
    },
    setPhoneCode(code) {
      if (this.phoneNumber !== '' && this.parsePhoneNumber) {
        const dialCode = this.parsePhoneNumber.countryCallingCode;
        if (dialCode === code) {
          return;
        }
        this.activeDialCode = `+${dialCode}`;
        const newPhoneNumber = this.phoneNumber.replace(
          `+${dialCode}`,
          `${code}`
        );
        this.phoneNumber = newPhoneNumber;
      } else {
        this.activeDialCode = code;
      }
    },
    async handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid || this.isPhoneNumberNotValid) {
        return;
      }
      try {
        await this.onSubmit(this.getContactObject());
        this.onSuccess();
        this.showAlert(this.$t('CONTACT_FORM.SUCCESS_MESSAGE'));
      } catch (error) {
        if (error instanceof DuplicateContactException) {
          if (error.data.includes('email')) {
            this.showAlert(
              this.$t('CONTACT_FORM.FORM.EMAIL_ADDRESS.DUPLICATE')
            );
          } else if (error.data.includes('phone_number')) {
            this.showAlert(this.$t('CONTACT_FORM.FORM.PHONE_NUMBER.DUPLICATE'));
          }
        } else if (error instanceof ExceptionWithMessage) {
          this.showAlert(error.data);
        } else {
          this.showAlert(this.$t('CONTACT_FORM.ERROR_MESSAGE'));
        }
      }
    },
    handleImageUpload({ file, url }) {
      this.avatarFile = file;
      this.avatarUrl = url;
    },
    async handleAvatarDelete() {
      try {
        if (this.contact && this.contact.id) {
          await this.$store.dispatch('contacts/deleteAvatar', this.contact.id);
          this.showAlert(
            this.$t('CONTACT_FORM.DELETE_AVATAR.API.SUCCESS_MESSAGE')
          );
        }
        this.avatarFile = null;
        this.avatarUrl = '';
        this.activeDialCode = '';
      } catch (error) {
        this.showAlert(
          error.message
            ? error.message
            : this.$t('CONTACT_FORM.DELETE_AVATAR.API.ERROR_MESSAGE')
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.contact--form {
  padding: var(--space-normal) var(--space-large) var(--space-large);

  .columns {
    padding: 0 var(--space-smaller);
  }
}

.input-group-label {
  font-size: var(--font-size-small);
}

::v-deep {
  .multiselect .multiselect__tags .multiselect__single {
    padding-left: 0;
  }

  .successMessage {
    color: var(--g-600);
    display: block;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-normal);
    margin-bottom: var(--space-one);
    margin-top: -15px;
    width: 100%;
  }
}
</style>
