<template>
  <div :key="`${attachment.data_url}?t=${Date.now()}`" class="message-text__wrap content-media" :class="attachmentTypeClasses">
    <img
      v-if="isImage && !isImageError"
      :src="currentImageUrl"
      @click="onClick"
      @error="onImgError()"
    />
    <video
      v-if="isVideo"
      :src="`${attachment.data_url}?t=${Date.now()}`"
      muted
      playsInline
      @click="onClick"
    />
    <audio v-else-if="isAudio" controls class="skip-context-menu">
      <source :src="`${attachment.data_url}?t=${Date.now()}`" />
    </audio>
    <gallery-view
      v-if="show"
      :show.sync="show"
      :attachment="attachment"
      :all-attachments="filteredCurrentChatAttachments"
      @error="onImgError()"
      @close="onClose"
    />
    <div v-if="!!caption" class="caption-content message-text__wrap" >
      <div class="text-content">
        {{ caption }}
      </div>
    </div>
  </div>
</template>

<script>
import { hasPressedCommand } from 'shared/helpers/KeyboardHelpers';
import { mapGetters } from 'vuex';

import GalleryView from '../components/GalleryView';

const ALLOWED_FILE_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio',
};

export default {
  components: {
    GalleryView,
  },
  props: {
    attachment: {
      type: Object,
      required: true,
    },
    caption: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      show: false,
      isImageError: false,
      currentImageUrl: '',
      retryCount: 0,
      maxRetries: 7,
      retryDelay: 500,
    };
  },
  computed: {
    ...mapGetters({
      currentChatAttachments: 'getSelectedChatAttachments',
    }),
    isImage() {
      return this.attachment.file_type === ALLOWED_FILE_TYPES.IMAGE;
    },
    isVideo() {
      return this.attachment.file_type === ALLOWED_FILE_TYPES.VIDEO;
    },
    isAudio() {
      return this.attachment.file_type === ALLOWED_FILE_TYPES.AUDIO;
    },
    attachmentTypeClasses() {
      return {
        image: this.isImage,
        video: this.isVideo,
      };
    },
    filteredCurrentChatAttachments() {
      const attachments = this.currentChatAttachments.filter(attachment =>
        ['image', 'video', 'audio'].includes(attachment.file_type)
      );
      return attachments;
    },
  },

  mounted(){
    if (this.isImage) {
      this.retryCount = 0;
      this.isImageError = false;
      this.currentImageUrl = `${this.attachment.data_url}?t=${Date.now()}`;
      this.retryLoadImage();
    }
  },
  watch: {
    attachment() {
      this.isImageError = false;
    },
  },
  methods: {
    retryLoadImage() {
      const image = new Image();
      image.src = `${this.attachment.data_url}?t=${Date.now()}`;
      image.onload = () => {
        this.isImageError = false;
        this.currentImageUrl = image.src;
      };
      image.onerror = () => {
        this.isImageError = true;
        if (this.retryCount < this.maxRetries) {
          this.retryCount += 1;
          this.retryDelay = this.retryDelay * 2;
          setTimeout(this.retryLoadImage, this.retryDelay);
        } else {
          this.$emit('error');
        }
      };
    },
    onClose() {
      this.show = false;
    },
    onClick(e) {
      if (hasPressedCommand(e)) {
        window.open(this.attachment.data_url, '_blank');
        return;
      }
      this.show = true;
    },
    onImgError() {
      this.isImageError = true;
      this.$emit('error');
    },
  },
};
</script>
