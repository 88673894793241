import {
  format,
  isSameYear,
  fromUnixTime,
  formatDistanceToNow,
} from 'date-fns';
import { ptBR, enUS, es, pt } from 'date-fns/locale';

export default {
  methods: {
    messageStamp(time, dateFormat = 'h:mm a') {
      const unixTime = fromUnixTime(time);
      return format(unixTime, dateFormat);
    },
    messageTimestamp(time, dateFormat = 'MMM d, yyyy') {
      const messageTime = fromUnixTime(time);
      const now = new Date();
      const messageDate = format(messageTime, dateFormat);
      if (!isSameYear(messageTime, now)) {
        return format(messageTime, 'LLL d y, h:mm a');
      }
      return messageDate;
    },
    dynamicTime(time) {
      const unixTime = fromUnixTime(time);
      const config = this.validateAndConfig();
      return formatDistanceToNow(unixTime, { addSuffix: true, locale: config.locale });
    },
    dateFormat(time, dateFormat = 'dd/MM/yyyy HH:mm') {
      const unixTime = fromUnixTime(time);
      let config = this.validateAndConfig();

      return format(unixTime, dateFormat, config);
    },
    shortTimestamp(time) {
      const convertToShortTime = time
        .replace(/about|over|almost|/g, '')
        .replace('less than a minute ago', 'now')
        .replace(' minute ago', 'm')
        .replace(' minutes ago', 'm')
        .replace('a minute ago', 'm')
        .replace('an hour ago', 'h')
        .replace(' hour ago', 'h')
        .replace(' hours ago', 'h')
        .replace(' day ago', 'd')
        .replace('a day ago', 'd')
        .replace(' days ago', 'd')
        .replace('a month ago', 'mo')
        .replace(' months ago', 'mo')
        .replace(' month ago', 'mo')
        .replace('a year ago', 'y')
        .replace(' year ago', 'y')
        .replace(' years ago', 'y');
      return convertToShortTime;
    },

    validateAndConfig(){
      const locale = this.$t('CONVERSATION.TIME.LOCALE');
      let config = { locale: enUS };
  
      if(locale == "pt-br"){
        config.locale = ptBR;
      }else if(locale == "pt"){
        config.locale = pt;
      }else if(locale == 'es'){
        config.locale = es;
      }
      return config;
    },

    formatIsoDate(isoDate){
      const date = new Date(isoDate);
      const optionsDate = { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit' 
      };
      const optionsTime = { 
        hour: '2-digit', 
        minute: '2-digit', 
        hour12: false 
      };
      const formattedDate = date.toLocaleDateString('pt-BR', optionsDate);
      const formattedTime = date.toLocaleTimeString('pt-BR', optionsTime);
      return `${formattedDate} ${formattedTime}`;
    }
  },
};
