import axios from 'axios';

class OnboardAPI {
  constructor() {}

  create(body) {
    return axios.post(`/onboard/create`, body);
  }
}

export default new OnboardAPI();
