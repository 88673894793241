<template>
  <modal :show.sync="show" :on-close="onClose" size="small">
    <div class="column content-box">
      <woot-modal-header :header-title="$t('IMPORT_CONTACTS.TITLE')">
        <p>
          {{ $t('IMPORT_CONTACTS.DESC')  + ' '}}
          <a class="btn-primary" :href="csvUrl" download="import-contacts-sample">CSV |</a>
          <a class="btn-primary" :href="xlsxUrl" download="import-contacts-sample">XLSX |</a>
          <a  class="btn-primary" :href="xlsUrl" download="import-contacts-sample">XLS</a>
        </p>
      </woot-modal-header>
      <div class="row modal-content">
        <div class="card-input-file">
          <span>{{ $t('IMPORT_CONTACTS.FORM.LABEL') + ' ' + 'CSV, XLSX, XLS' }}</span>
          <input
            ref="file"
            type="file"
            accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"  
            @change="handleFileUpload"
            class="input-file"
          />
        </div>
        <div class="medium-12 columns">
          <label>
            <span>{{ $t('IMPORT_CONTACTS.WALLET.LABEL') }}</span>
          </label>
          <multiselect-dropdown
            :options="agentsWallets"
            :selected-item="agentSelectedWallet"
            :multiselector-title="$t('IMPORT_CONTACTS.WALLET.MULTISELECTOR')"
            :multiselector-placeholder="$t('AGENT_MGMT.MULTI_SELECTOR.PLACEHOLDER')"
            :no-search-result="$t('AGENT_MGMT.MULTI_SELECTOR.SEARCH.NO_RESULTS.AGENT')"
            :input-placeholder="$t('AGENT_MGMT.MULTI_SELECTOR.SEARCH.PLACEHOLDER.AGENT')"
            @click="getAgentWalletId"
          />
        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">            
            <woot-button
              :disabled="uiFlags.isCreating || !file"
              :loading="uiFlags.isCreating"
              @click="uploadFile"
              class="bg-primary"
            >
              {{ $t('IMPORT_CONTACTS.FORM.SUBMIT') }}
            </woot-button>
            <button class="button clear btn-primary" @click.prevent="onClose">
              {{ $t('IMPORT_CONTACTS.FORM.CANCEL') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '../../../../components/Modal';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { CONTACTS_EVENTS } from '../../../../helper/AnalyticsHelper/events';
import MultiselectDropdown from 'shared/components/ui/MultiselectDropdown.vue';

export default {
  components: {
    Modal,
    MultiselectDropdown,
  },
  mixins: [alertMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      show: true,
      file: '',
      selectedWalletId: null,
      agentSelectedWallet: {
        confirmed: true,
        name: 'Nenhum',
        id: null,
        role: 'agent',
        account_id: 0,
        email: 'None',
        wallet: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
      agentList: 'agents/getAgents',
    }),
    agentsWallets() {
      if (this.agentList.length === 0)
        this.$store.dispatch('agents/get');
      
      const agents = this.agentList || [];
      return [
        ...([{
          confirmed: true,
          name: 'Nenhum',
          id: null,
          role: 'agent',
          account_id: 0,
          email: 'None',
          wallet: null,
        },]),
        ...agents,
      ];
    },
    csvUrl() {
      return '/downloads/import-contacts-csv-sample.csv';
    },
    xlsxUrl() {
      return '/downloads/import-contacts-xlsx-sample.xlsx';
    },
    xlsUrl() {
      return '/downloads/import-contacts-xls-sample.xls';
    },
  },
  mounted() {
    this.$track(CONTACTS_EVENTS.IMPORT_MODAL_OPEN);
  },
  methods: {
    async uploadFile() {
      try {
        if (!this.file) return;
        await this.$store.dispatch('contacts/import', {
          file: this.file,
          selectedWalletId: this.selectedWalletId,
        });
        this.onClose();
        this.showAlert(this.$t('IMPORT_CONTACTS.SUCCESS_MESSAGE'));
        this.$track(CONTACTS_EVENTS.IMPORT_SUCCESS);
      } catch (error) {
        this.showAlert(
          error.message || this.$t('IMPORT_CONTACTS.ERROR_MESSAGE')
        );
        this.$track(CONTACTS_EVENTS.IMPORT_FAILURE);
      }
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    getAgentWalletId(agent) {
      this.selectedWalletId = agent.id ? agent.id : null;
      this.agentSelectedWallet = agent;
    },
  },
};
</script>

<style scoped>

.content-box {
  overflow: unset;
}

.modal-footer {
  margin-top: 3rem;
}
.card-input-file{
  font-size: 1.4rem;
}
.card-input-file input {
  width: 80%;
}
</style>
