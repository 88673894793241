<template>
  <woot-modal :show.sync="show" :on-close="onCancel" size="modal-xl">
    <contact-manage-view 
      :contactId="contact.id" 
      :ticketSelected="ticketSelected" 
      @close-modal-ticket="updateListAndClose"
      @updateTicket="updateTicket"
    />
  </woot-modal>
</template>

<script>
import ContactManageView from '../../contacts/pages/ContactManageView.vue';
export default {
  name: 'ModalEditTicket',
  components: {
    ContactManageView
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    ticketSelected: {
      type: Object,
      default: () => ({}),
    },
    column: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      ticket: this.ticketSelected
    }
  },
  computed: {
    contact() {
      return this.ticket.contact;
    },

    isCrmPage() {
      return this.$route.name == 'crm';
    },

  },

  methods: {
    updateListAndClose(isDelete){
      if(isDelete){
        this.column.column.tickets = this.column.column.tickets.filter(obj => obj.id !== this.ticket.id)
        this.column.column.meta.total_count = this.column.column.meta.total_count - 1;
      }
      this.onCancel()
    },

    updateTicket(updatedTicket){
      this.ticket = updatedTicket;
      this.$emit('updateTicket', updatedTicket);
    },
  
    onCancel() {
      this.$emit('cancel');
    },
    onSuccess() {
      this.$emit('success');
    },
  },

};
</script>

<style scoped>
.card-content {
  display: flex;
  gap: 2rem;
}

.description {
  min-width: 220px;
}
</style>