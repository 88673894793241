import HomeView from './HomeView.vue'
import { frontendURL } from '../../../helper/URLHelper';

export default {
    routes: [
      {
        path: frontendURL('accounts/:accountId/dashboard'),
        name: 'dashboard',
        roles: ['administrator'],
        component: HomeView,
        props: () => {
          return { inboxId: 0 };
        },
      },
    
    ],
  };