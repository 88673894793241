import fromUnixTime from 'date-fns/fromUnixTime';
import format from 'date-fns/format';
import axios from 'axios';

export const downloadCsvFile = (fileName, content) => {
  const contentType = 'data:text/csv;charset=utf-8;';
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.setAttribute('download', fileName);
  link.setAttribute('href', url);
  link.click();
  return link;
};

export const downloadAllFile = async (fileUrl) => {
  try {
    const response = await axios.get(fileUrl, {
      responseType: 'blob',
    });
    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    const url = URL.createObjectURL(blob);
    const contentType = response.headers['content-type'];
    const fileExtension = contentType.split('/')[1];
    const timestamp = new Date();
    const formattedTimestamp = timestamp.toISOString().replace(/[-T]/g, '-').slice(0, 19).replace(' ', '-').replace(/:/g, '-');
    const fileName = `Omnidesk File ${formattedTimestamp}.${fileExtension}`;

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);

    link.click();

    URL.revokeObjectURL(url);
  } catch (err) {
    console.error('error downloading file:', err);
  }
};

export const generateFileName = ({ type, to, businessHours = false }) => {
  let name = `${type}-report-${format(fromUnixTime(to), 'dd-MM-yyyy')}`;
  if (businessHours) {
    name = `${name}-business-hours`;
  }
  return `${name}.csv`;
};