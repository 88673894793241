<template>
  <div>
    <form class="row" @submit.prevent="createChannel()">
      <div class="medium-8 columns">
        <label :class="{ error: $v.channelName.$error }">
          {{ $t('INBOX_MGMT.ADD.TWILIO.CHANNEL_NAME.LABEL') }}
          <input
            v-model.trim="channelName"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.TWILIO.CHANNEL_NAME.PLACEHOLDER')"
            @blur="$v.channelName.$touch"
          />
          <span v-if="$v.channelName.$error" class="message">{{
            $t('INBOX_MGMT.ADD.TWILIO.CHANNEL_NAME.ERROR')
          }}</span>
        </label>
      </div>

      <div class="medium-12 columns">
        <woot-submit-button
          :loading="uiFlags.isCreating"
          :disabled="openConnection"
          :button-text="$t('INBOX_MGMT.ADD.WHATSAPP.SUBMIT_BUTTON')"
        />
      </div>
    </form>
    <br />
    <div v-if="qrcodeWhatsapp" class="row">
      <div class="column text-center">
        <qrcode-vue :value="qrcodeWhatsapp" :size="300" level="H" />
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import alertMixin from 'shared/mixins/alertMixin';
import { reconnectSessionOnService } from 'shared/mixins/requestApiMixin';
import { io } from 'socket.io-client';
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import router from '../../../../index';

export default {
  components: {
    QrcodeVue,
  },
  mixins: [alertMixin],
  data() {
    return {
      channelName: '',
      qrcodeWhatsapp: null,
      openConnection: false,
    };
  },

  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
      getAccount: 'accounts/getAccount',
      currentAccountId: 'getCurrentAccountId',
    }),
  },
  validations: {
    channelName: { required },
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        const account = this.getAccount(this.currentAccountId);

        const apiChannel = await this.$store.dispatch('inboxes/createChannel', {
          name: this.channelName,
          channel: {
            type: 'api',
            webhook_url: account.url_webhook,
          },
          is_whatsapp: true,
        });

        this.connectQrWhatsappWeb(apiChannel, account);
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.ADD.API_CHANNEL.API.ERROR_MESSAGE'));
      }
    },
    connectQrWhatsappWeb(apiChannel, account) {
      this.openConnection = true;
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          account_gid: account.gid,
          session_id: apiChannel.inbox.session_id,
          inbox_identifier: apiChannel.inbox.inbox_identifier,
        }),
      };

      reconnectSessionOnService(account.url_whatsapp_web, requestOptions).then(
        ({ data }) => {
          this.showAlert(this.$t('INBOX_MGMT.SETTINGS_POPUP.QRCODE_MESSAGE'));
          const session_id = data?.session_id;
          this.qrcodeWhatsapp = data?.qr;

          if (session_id === '' || session_id === undefined) return;

          const socket = io(account.url_whatsapp_web);

          socket.on(`${session_id}-qrcode-generated`, qrcode => {
            this.qrcodeWhatsapp = qrcode;
          });

          socket.on(
            `${session_id}-client-connected`,

            () => {
              try {
                this.updateInboxStatusConnected(apiChannel.inbox.id);
                socket.disconnect();
              } catch (error) {
                this.showAlert(
                  this.$t('INBOX_MGMT.SETTINGS_POPUP.DEVICE_CONNECTED_ERROR')
                );
              }
            }
          );
        }
      );
    },
    updateInboxStatusConnected(inboxId) {
      this.$store
        .dispatch('inboxes/updateInbox', {
          id: inboxId,
          connected: true,
        })
        .then(() => {
          this.showAlert(this.$t('INBOX_MGMT.SETTINGS_POPUP.DEVICE_CONNECTED'));
          router.replace({
            name: 'settings_inboxes_add_agents',
            params: {
              page: 'new',
              inbox_id: inboxId,
            },
          });
        });
    },
  },
};
</script>
