<template>
  <div>
    <img :src="require('../../../assets/images/bell.svg')" alt="notification" />
    <span v-if="unreadCount" class="badge warning">{{ unreadCount }}</span>
</div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      notificationMetadata: 'notifications/getMeta',
    }),
    unreadCount() {
      if (!this.notificationMetadata.unreadCount) {
        return '';
      }

      return this.notificationMetadata.unreadCount < 100
        ? `${this.notificationMetadata.unreadCount}`
        : '99+';
    },
  },
  methods: {
    openNotificationPanel() {
      if (this.$route.name !== 'notifications_index') {
        this.$emit('open-notification-panel');
      }
    },
  },
};
</script>

<style scoped lang="scss">

img{
  height: 20px;
}

.badge {
  position: absolute;
  top: 6px;
  padding: 2px 4px;
}
.notifications-link--button {
  display: flex;
  position: relative;
  border-radius: var(--border-radius-large);
  border: 1px solid transparent;
  color: var(--s-600);
  margin: var(--space-small) 0;

  .fi {
    color: var(--s-50);
    font-size: 25px;
  }

  &:hover {
    background: var(--w-50);
    color: var(--s-600);
  }

  &:focus {
    border-color: var(--w-500);
  }

  &.is-active {
    background: var(--w-50);
    color: var(--w-950);
  }
}
</style>
