<template>
  <form @submit.prevent="updateKnowledgeBase()">
    <div class="form-container">
      <!-- Title -->
      <label>
        {{ $t("INTEGRATION_APPS.LIST.KNOWLEDGE_BASE.INPUT_TITLE") }}
        <input type="text" v-model="title" :placeholder="$t('INTEGRATION_APPS.LIST.KNOWLEDGE_BASE.INPUT_TITLE')"
          class="small-input" />
      <label>
      <!-- TextArea -->
      </label>
        {{ $t("INTEGRATION_APPS.LIST.KNOWLEDGE_BASE.INPUT_QUOTE") }}
        <textarea
          v-model="baseAI"
          :placeholder="$t('INTEGRATION_APPS.LIST.KNOWLEDGE_BASE.INPUT_QUOTE')"
          :maxlength="remainingCharacters"
          rows="5"
        />
        <p id="messageHelp" class="h2 total-characters" >
          {{ `${baseAI.length} / ${remainingCharacters}` }}
          {{ $t("INTEGRATION_APPS.LIST.KNOWLEDGE_BASE.CHARACTERS_LEFT") }}
        </p>
      </label>
      <!-- Actions -->
      <div class="buttons">
        <button :disabled="!baseAI" class="button bg-primary primary mt-2">
          <span class="button__content">
            {{ $t("INTEGRATION_APPS.LIST.KNOWLEDGE_BASE.UPDATE") }}
          </span>
        </button>
        <button class="button clear btn-primary" @click.prevent="onClose">
          {{ $t('INTEGRATION_APPS.ADD.FORM.CANCEL') }}
        </button>
      </div>
    </div>
  </form>
</template>
<script>
import alertMixin from 'shared/mixins/alertMixin';

export default {
  props: {
    knowledgeBase: {
      type: Object,
      required: true
    },
    baseAiUsedCharacters: {
      type: Number,
      required: true
    }
  },
  mixins: [alertMixin],
  data() {
    return {
      title: "",
      baseAI: "",
      maxCharacters: 10000,
      remainingCharacters: 0,
    }
  },
  computed: {
    knowledgeBasePayload() {
      return {
        ...this.knowledgeBase,
        title: this.title,
        baseAI: this.baseAI
      }
    },
  },
  mounted() {
    this.fetchKnowledgeBase();
  },
  methods: {
    onClose() {
      this.$emit("on-close");
    },
    fetchKnowledgeBase() {
      this.title = this.knowledgeBase.title;
      this.baseAI = this.knowledgeBase.baseAI;
      this.remainingCharacters = this.maxCharacters - this.baseAiUsedCharacters;
    },
    async updateKnowledgeBase() {
      try {
        await this.$store.dispatch('integrations/updateKnowledgeBase', this.knowledgeBasePayload).then(
          () => {
            this.showAlert(this.$t('Atualizado com sucesso!'));
            this.onClose();
          }
        );
      } catch (error) {
        throw (error)
      }
    },
  },
}
</script>
