export const getters = {
  getContacts($state) {
    return $state.sortOrder.map(contactId => $state.records[contactId]);
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getContact: $state => id => {
    const contact = $state.records[id];
    return contact || {};
  },
  getContactByAgentId: $state => agent_id => {
    const contactId = Object.keys($state.records).find(
      key => $state.records?.[key]?.agent_id == agent_id
    );
    return $state.records[contactId];
  },
  getMeta: $state => {
    return $state.meta;
  },
  getAppliedContactFilters: _state => {
    return _state.appliedFilters;
  },

  getContactsViews($state) {
    const firstRecord = $state.sortOrder[0];

    if ($state.recordsView?.[firstRecord]) {
      return $state.sortOrder.map(contactId => {
        return $state.recordsView?.[contactId];
      });
    }
    return getters.getContacts($state);
  },
  getContactView: $state => id => {
    const contact = $state.recordsView[id];
    return contact || {};
  },
};
