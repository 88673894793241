<template>
  <woot-modal :show="showAccountModal" :on-close="() => $emit('close-account-modal')" class="account-selector--modal">
    <woot-modal-header :header-title="$t('SIDEBAR_ITEMS.CHANGE_ACCOUNTS')"
      :header-content="$t('SIDEBAR_ITEMS.SELECTOR_SUBTITLE')" />
    <input 
        type="text" 
        ref="refSearchInput"           
        :class="{ 'focused': isFocused, 'blur': !isFocused , 'account--search': true}" 
        :placeholder="$t('CONVERSATION.SEARCH_MESSAGES_ACCOUNT')"
        :value="searchQuery" 
        @input="debounceSearch" 
        @focus="handleFocusSearch" 
        @blur="handleBlurSearch"
    >

    <div class="account-selector--wrap">
      <div v-for="account in accountData" :key="account.id" class="account-selector">
        <button class="button expanded clear link" @click="onChangeAccount(account.id)">
          <span class="button__content">
            <label :for="account.name" class="account-details--wrap">
              <div class="account--name">{{ account.name }}</div>
              <div class="account--role">{{ account.role }}</div>
            </label>
          </span>
          <fluent-icon v-show="account.id === accountId" class="selected--account" icon="checkmark-circle" type="solid"
            size="24" />
        </button>
      </div>
    </div>

    <div v-if="globalConfig.createNewAccountFromDashboard" class="modal-footer delete-item">
      <button class="button success large expanded nice" @click="$emit('show-create-account-modal')">
        {{ $t('CREATE_ACCOUNT.NEW_ACCOUNT') }}
      </button>
    </div>
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    showAccountModal: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      searchQuery: '',
      accountData: [],
      isFocused: false 
    }
  },

  mounted() {
    this.initState()
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      globalConfig: 'globalConfig/get',
      currentAccountsUser: 'getUserAccounts',
    }),
  },
  methods: {
    onChangeAccount(accountId) {
      const accountUrl = `/app/accounts/${accountId}/conversation`;
      window.location.href = accountUrl;
    },
    searchAccountByName(term) {
      return this.currentAccountsUser?.filter((item) => {
        const valueFormated = item?.name?.toUpperCase()
        return valueFormated.includes(term)
      })
    },

    debounceSearch(e) {
      const valuesFilter = e.target.value.toUpperCase()
      this.searchQuery = e.target.value
      this.$refs.refSearchInput.focus();
      this.accountData = this.searchAccountByName(valuesFilter)
    },

    initState(){
      this.accountData = this.currentAccountsUser
    },
    handleFocusSearch(){
      this.isFocused = true;
    },

    handleBlurSearch(){
      this.isFocused = false;
    }
  },
};
</script>
<style lang="scss" scoped>
.account--search {
  // color: var(--focused);
  width: 80%;
  margin: 20px auto;
}

.account-selector--wrap {
  margin-top: var(--space-normal);
}




.focused {
  border-color: var(--g-20);
}

.blur {
  color:var(--blur)!important;
}
.blur::placeholder {
  color:var(--blur)!important;
}

input:focus {
  box-shadow: none !important;
}

.account-selector {
  padding-top: 0;
  padding-bottom: 0;

  .button {
    display: flex;
    justify-content: space-between;
    padding: var(--space-one) var(--space-normal);

    .account-details--wrap {
      text-align: left;

      .account--name {
        cursor: pointer;
        font-size: var(--font-size-medium);
        font-weight: var(--font-weight-medium);
        line-height: 1;
      }

      .account--role {
        cursor: pointer;
        font-size: var(--font-size-mini);
        text-transform: capitalize;
      }
    }
  }
}
</style>