<template>
  <div class="suggestion-container">
    <div class="button-container" >
        <woot-button class="button-style" v-for="item in suggestions" @click="selectVariable(item)">{{item.label}}</woot-button>
    </div>
  </div>
  </template>
  
  <script>
  
  export default {
    name: "SuggestionButtons",
    components: {
  },
    props: {
    suggestions: {
      type: Array,
      default: [],
    },
  },methods: {
    selectVariable(data){
        this.$emit("customVariable",data.key);
        event.preventDefault();
    }

  },
  };
  </script>
<style>
    .button-style {
    margin-right: 10px;
    margin-bottom:10px;
  }
  .button-container {
  }
  .tooltip {
  z-index: 99999999;
}
  .suggestion-container{
    margin-bottom: 15px;
    position: relative;
  }

</style>