import ApiClient from './ApiClient';

class KanbanfunnelApi extends ApiClient {
    constructor() {
        super('', { accountScoped: true });
    }

    async getKanbanfunnels() {
        return await axios.get(`${this.url}kanbanfunnels`);
    }

    async createKanbanfunnels(payload) {
        return await axios.post(`${this.url}kanbanfunnels`, payload);
    }

    async updateKanbanfunnels(id, payload) {
        return await axios.put(`${this.url}kanbanfunnels/${id}`, payload);
    }

    async deleteKanbanfunnels(id) {
        return await axios.delete(`${this.url}kanbanfunnels/${id}`);
    }

}

export default new KanbanfunnelApi();