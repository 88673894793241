const validateLink = text => {
  const regex = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)|<((https?:\/\/[^\s]+))>|(https?:\/\/[^\s]+)/g;
  return text.replace(regex, (match, p1, p2, p3, p4) => {
    if (p2) return p2;
    if (p3) return p3;
    if (p4) return p4;
    return match;
  });
};

export default validateLink;
