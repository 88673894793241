<template>
  <div>
    <div class="task-board" :data-board-name="getBoardName(index)">
      <input
        type="text"
        class="form-control"
        :value="list.column.name"
        v-if="isEditing"
        @blur="saveTaskListName"
      />
      <div v-if="!isEditing" class="board-header">
        <p class="board-name">{{ list.column.name }}</p>
        <div class="card-header">

          <div class="card-data-tickets">
            <p class="total"> {{ list.column.meta.total_count}}&nbsp;</p>

            <p class="tickets" v-if="!isFunnelSale">{{ this.$t('TICKET.TITLE') }}</p>
            <p class="tickets" v-if="isFunnelSale">{{ this.$t('TICKET.TITLE') }}:&nbsp;</p>

            <p class="money" v-if="isFunnelSale">{{ formatCurrency(valueCollum)}}</p>
          </div>

        </div>
      </div>
      <div class="board-content" @scroll="onScroll">
        <ul class="task-list">
          <draggable
            v-model="list.column.tickets"
            v-bind="dragOptions"
            class="list-group"
            :empty-insert-threshold="5"
            @change="onTaskDrop"
          >
            <TaskItem
              v-for="item in list.column.tickets"
              :item="item"
              :list="list"
              :kanban-id="kanbanId"
              :board="board"
              :key="item.id"
              :isFunnelSale="isFunnelSale"
            />
            <li
              v-if="list.column.tickets.length === 0"
              class="list-group-item placeholder-item"
            >
              <span>{{ this.$t('TICKET.DRAG_TICKET') }}</span>
            </li>
          </draggable>
        </ul>
        <div v-if="loadingMore" class="text-center">
          <span class="spinner" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import TaskItem from "./TaskItem";
import { mapGetters } from 'vuex';
import{ formatCurrency } from '../../../../../helper/formatCurrency';

export default {
  components: {
    TaskItem,
    draggable,
  },
  props: ["board", "list", "index", "kanbanId"],
  data() {
    return {
      drag: false,
      isEditing: false,
      taskListName: this.list.name,
      colors: ["primary", "secondary", "tertiary", "quaternary"],
      loadingMore: false,
      itemsPerPage: 10,
      page: 1,
      allItemsLoaded: false,
      dragOptions: {
        group: "tasks",
        animation: 200,
        ghostClass: "sortable-ghost",
        chosenClass: "sortable-chosen",
        dragClass: "sortable-drag",
        emptyInsertThreshold: 5,
      },
    };
  },
  computed: {
    ...mapGetters({
      kanbanList: 'getKanbanfunnels'
    }),
    items() {
      return this.list.column.tickets;
    },
    isFunnelSale(){
      const kanban = this.kanbanList.find(obj => obj.id == this.kanbanId);
      return kanban.enable_sales_funnel;
    },

    valueCollum(){
      if(this.list.column.meta.total_count > 0 && this.list.column.meta.total_sales_value > 0 ){
        return this.list.column.meta.total_sales_value; 
      }
      return 0;
    }
  },
  methods: {
    formatCurrency,

    getBoardName(index) {
      return this.colors[index % this.colors.length];
    },
    saveTaskListName(e) {
      this.list.column.name = e.target.value;
      this.isEditing = !this.isEditing;
    },

    async onTaskDrop(event) {
    let movedTicket = null;
    let originColumn = null;
    let destinationColumn = null;

    if (event.added) {
      movedTicket = event.added.element;

      destinationColumn = this.board.find((column) =>
        column.column.tickets.includes(movedTicket)
      );

      originColumn = this.board.find((column) => 
        column.column.id === movedTicket.columns_id
      );

      movedTicket.columns_id = destinationColumn?.column.id;
      await this.$store.dispatch('updateTicket', movedTicket);

      if (destinationColumn) {
        destinationColumn.column.meta.total_count += 1;
        this.$set(destinationColumn.column.meta, 'total_sales_value', destinationColumn.column.meta.total_sales_value + Number(movedTicket.sale_value));
      }

      if (originColumn) {
        originColumn.column.meta.total_count -= 1;
        this.$set(originColumn.column.meta, 'total_sales_value', originColumn.column.meta.total_sales_value - Number(movedTicket.sale_value));
      }

    }
  },
    onScroll(event) {
      const bottomOfWindow =
        Math.ceil(event.target.scrollTop + event.target.clientHeight) >=
        event.target.scrollHeight;

      if (bottomOfWindow && !this.loadingMore && !this.allItemsLoaded) {
        this.loadMoreItems();
      }
    },
    async loadMoreItems() {
      this.loadingMore = true;

      try {
        this.page += 1;
        const newItems = await this.fetchMoreItems(this.page);
        if (newItems.length > 0) {
          this.list.column.tickets = [
            ...this.list.column.tickets,
            ...newItems,
          ];
        } else {
          this.allItemsLoaded = true;
        }
      } catch (error) {
        console.error(error);
        bus.$emit('newToastMessage', this.$t('TICKET.CREATE.API.ERROR_MESSAGE'));
      } finally {
        this.loadingMore = false;
      }
    },
    async fetchMoreItems(page) {
      if (page > this.list.column.meta.total_pages) {
        return [];
      }

      const params = {
        kanbanfunnel_id: this.kanbanId,
        columns_id: this.list.column.id,
        per_page: 10,
        page,
      };

      const resp = await this.$store.dispatch("getTicketsByColumnColumns", params);
      return resp.tickets;
    },
  },
};
</script>

<style>
.sortable-chosen.ghost .task-item {
  background: repeating-linear-gradient(
    145deg,
    transparent,
    transparent 5px,
    #e8eaf1 5px,
    #e8eaf1 10px
  );
  border: 2px solid #e2e2e2;
}


.card-data-tickets {
  display: flex;
  align-items: center;

}
.money, .total{
  font-weight: bold;
}
.money {
  color: #4CAF50;
}

.card-data-tickets p{
  margin: 0;
}

.flip-list-move {
  transition: transform 0.2s;
}

.no-move {
  transition: transform 0s;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}

.board-header {
  border-bottom: 1px solid var(--s-100);
}

.task-board .board-header .options {
  padding: 5px 5px;
  display: flex;
  justify-content: space-between;
}

.task-board .board-header .dropdown-menu {
  min-width: 10rem;
}

.task-board .board-content {
  max-height: 75vh;
  overflow-y: auto;
}

.placeholder-item {
  min-height: 50px;
  text-align: center;
  line-height: 50px;
  color: #999;
  border: 1px dashed #ccc;
}

.card-header {
  margin-bottom: 1rem;
}
</style>
