import { frontendURL } from '../../../../helper/URLHelper';

const crm = accountId => ({
  parentNav: 'crm',
  routes: [
    'crm',
    'funnel'
  ],
  menuItems: [
    {
      icon: 'contact-card-group',
      label: 'CRM',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/crm`),
      toStateName: 'crm',
    }
  ],
});

export default crm;
