<template>
    <div
      class="kanban-context--group"
      @mouseover="setShowSwitch"
      @mouseleave="resetShowSwitch"
    > 
      <p class="kanban-context--name text-ellipsis">
        {{ kanbanName }}
      </p>
      <transition name="fade">
        <div v-if="showSwitchButton" class="kanban-context--switch-group">
          <woot-button
            variant="clear btn-primary"
            size="tiny"
            icon="arrow-swap"
            class="switch-button"
            @click="$emit('toggle-kanban')"
          >
            {{ $t('SIDEBAR.SWITCH') }}
          </woot-button>
        </div>
      </transition>
    </div>
  </template>
  <script>
  export default {
    name: 'KanbanContext',
    props: {
      kanbanSelect: {
        type: Object,
        default: () => {},
      },
      countKanban: {
        type: Number,
        default: 0
      }
    },
    data() {
      return { showSwitchButton: false };
    },

    computed:{
      kanbanName(){
        return this.kanbanSelect?.name 
      }
    },
    methods: {
      setShowSwitch() {
        if(this.countKanban > 1){
          this.showSwitchButton = true;
        }
      },
      resetShowSwitch() {
        this.showSwitchButton = false;
      },
    },
  };
  </script>
  <style scoped lang="scss">
  .kanban-context--group {
    min-width: 80px;
    border-radius: var(--border-radius-normal);
    border: 1px solid var(--color-border);
    font-size: var(--font-size-mini);
    padding: 0.6rem;
    position: relative;
  
    &:hover {
      background: var(--b-100);
    }
  
    .kanban-context--name {
      font-weight: var(--font-weight-medium);
      margin-bottom: 0;
    }
  }
  
  .switch-button {
    margin: 0 var(--space-small);
  }
  
  .kanban-context--switch-group {
    --overlay-shadow: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 50%
    );
  
    align-items: center;
    background-image: var(--overlay-shadow);
    border-top-left-radius: 0;
    border-top-right-radius: var(--border-radius-normal);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: var(--border-radius-normal);
    display: flex;
    height: 100%;
    justify-content: flex-end;
    opacity: 1;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
  
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 300ms ease;
  }
  
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  </style>
  