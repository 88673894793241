<template>
  <div class="bottom-box" :class="wrapClass">
    <div class="left-wrap">
      <woot-button
        v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.TOOLS')"
        :title="$t('CONVERSATION.REPLYBOX.TOOLS')"
        icon="add"
        emoji="➕"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        @click="toggleOptionsStatus"
      />
      <transition name="menu-slide">
        <div
          v-if="show"
          v-on-clickaway="onClickAway"
          class="options-menu dropdown-pane"
          :class="{ 'dropdown-pane--open': show }"
        >
          <woot-dropdown-menu>
            <woot-dropdown-item>
              <woot-button
                v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.CONTACT')"
                :title="$t('CONVERSATION.REPLYBOX.CONTACT')"
                icon="person"
                emoji="😊"
                color-scheme="secondary"
                variant="smooth"
                size="small"
                :on-send="onSend"
                :inbox="inbox"
                @click="showModalNewConversation"
              />
            </woot-dropdown-item>

            <woot-dropdown-item>
              <file-upload
                ref="upload"
                v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')"
                :accept="allowedFileTypes"
                :multiple="enableMultipleFileUpload"
                :drop="true"
                :drop-directory="false"
                @input-filter="inputFilter"
                @input-file="onUpload"
              >
                <woot-button
                  class-names="button--upload"
                  :title="$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')"
                  icon="attach"
                  emoji="📎"
                  color-scheme="secondary"
                  variant="smooth"
                  size="small"
                />
              </file-upload>
            </woot-dropdown-item>

            <woot-dropdown-item>
              <woot-button
                v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.LOCATION')"
                :title="$t('CONVERSATION.REPLYBOX.LOCATION')"
                icon="location"
                emoji="😊"
                color-scheme="secondary"
                variant="smooth"
                size="small"
                @click="toggleLocationModal"
              />
            </woot-dropdown-item>
          </woot-dropdown-menu>
        </div>
      </transition>
      <woot-button
        v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.TIP_EMOJI_ICON')"
        :title="$t('CONVERSATION.REPLYBOX.TIP_EMOJI_ICON')"
        icon="emoji"
        emoji="😊"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        @click="toggleEmojiPicker"
      />
      <woot-button
        v-if="showAudioRecorderButton"
        v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.TIP_AUDIORECORDER_ICON')"
        :icon="!isRecordingAudio ? 'microphone' : 'microphone-off'"
        emoji="🎤"
        :color-scheme="!isRecordingAudio ? 'secondary' : 'alert'"
        variant="smooth"
        size="small"
        @click="toggleAudioRecorder"
      />
      <woot-button
        v-if="showEditorToggle"
        v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.TIP_FORMAT_ICON')"
        icon="quote"
        emoji="🖊️"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        @click="$emit('toggle-editor')"
      />
      <woot-button
        v-if="showAudioPlayStopButton"
        :icon="audioRecorderPlayStopIcon"
        emoji="🎤"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        @click="toggleAudioRecorderPlayPause"
      >
        <span>{{ recordingAudioDurationText }}</span>
      </woot-button>
      <woot-button
        v-if="showMessageSignatureButton"
        v-tooltip.top-end="signatureToggleTooltip"
        icon="signature"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        :title="signatureToggleTooltip"
        @click="toggleMessageSignature"
      />
      <woot-button
        v-if="hasWhatsappTemplates"
        v-tooltip.top-end="'Whatsapp Templates'"
        icon="whatsapp"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        :title="'Whatsapp Templates'"
        @click="$emit('selectWhatsappTemplate')"
      />
      <video-call-button
        v-if="(isAWebWidgetInbox || isAPIInbox) && !isOnPrivateNote"
        :conversation-id="conversationId"
      />
      <AIAssistanceButton
        :conversation-id="conversationId"
        :is-private-note="isOnPrivateNote"
        :message="message"
        @replace-text="replaceText"
      />
      <transition name="modal-fade">
        <div
          v-show="$refs.upload && $refs.upload.dropActive"
          class="modal-mask"
        >
          <fluent-icon icon="cloud-backup" />
          <h4 class="page-sub-title">
            {{ $t('CONVERSATION.REPLYBOX.DRAG_DROP') }}
          </h4>
        </div>
      </transition>
    </div>
    <div class="right-wrap">
      <woot-button
        size="small"
        class="bg-primary"
        :class-names="buttonClass"
        :is-disabled="isSendDisabled"
        @click="onSend"
      >
        {{ sendButtonText }}
      </woot-button>
    </div>

    <woot-modal
      :show.sync="toggleContactsModal"
      :on-close="hideContactsModal"
      size="modal-xl"
    >
      <div class="column content-box">
        <div class="row">
          <contacts-view
            :conversation-id="conversationId"
            :on-send="onSend"
            :message="message"
            :toggle-contact="toggleContact"
            @toggle-contacts-view="onToggleContactsModal"
          />
        </div>
      </div>
    </woot-modal>
    <woot-modal :show.sync="showLocationModal" :on-close="onClose">
      <div class="google-autocomplete-input">
        <h2>{{ $t('CONVERSATION.REPLYBOX.LOCATION') }}</h2>
        <vue-google-autocomplete
          id="map"
          ref="address"
          classname="form-control"
          class="google-autocomplete"
          :placeholder="$t('CONVERSATION.REPLYBOX.LOCATION')"
          types=""
          @placechanged="selectLocation"
        />
        <br />
      </div>
    </woot-modal>
  </div>
</template>

<script>
import FileUpload from 'vue-upload-component';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import * as ActiveStorage from 'activestorage';
import { hasPressedAltAndAKey } from 'shared/helpers/KeyboardHelpers';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import inboxMixin from 'shared/mixins/inboxMixin';
import { FEATURE_FLAGS } from 'dashboard/featureFlags';
import {
  ALLOWED_FILE_TYPES,
  ALLOWED_FILE_TYPES_FOR_TWILIO_WHATSAPP,
} from 'shared/constants/messages';
import VideoCallButton from '../VideoCallButton';
import AIAssistanceButton from '../AIAssistanceButton.vue';
import { REPLY_EDITOR_MODES } from './constants';
import { mapGetters } from 'vuex';
import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem';
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu';
import { mixin as clickaway } from 'vue-clickaway';
import ContactsView from '../../../routes/dashboard/contacts/components/ContactsView.vue';

export default {
  name: 'ReplyBottomPanel',
  components: {
    FileUpload,
    VideoCallButton,
    AIAssistanceButton,
    WootDropdownMenu,
    WootDropdownItem,
    VueGoogleAutocomplete,
    ContactsView,
  },
  mixins: [clickaway, eventListenerMixins, uiSettingsMixin, inboxMixin],
  props: {
    mode: {
      type: String,
      default: REPLY_EDITOR_MODES.REPLY,
    },
    onSend: {
      type: Function,
      default: () => {},
    },
    sendButtonText: {
      type: String,
      default: '',
    },
    recordingAudioDurationText: {
      type: String,
      default: '',
    },
    inbox: {
      type: Object,
      default: () => ({}),
    },
    showFileUpload: {
      type: Boolean,
      default: false,
    },
    showAudioRecorder: {
      type: Boolean,
      default: false,
    },
    onFileUpload: {
      type: Function,
      default: () => {},
    },
    showEmojiPicker: {
      type: Boolean,
      default: false,
    },
    toggleEmojiPicker: {
      type: Function,
      default: () => {},
    },
    toggleAudioRecorder: {
      type: Function,
      default: () => {},
    },
    toggleAudioRecorderPlayPause: {
      type: Function,
      default: () => {},
    },
    toggleLocation: {
      type: Function,
      default: () => {},
    },
    toggleContact: {
      type: Function,
      default: () => {},
    },
    isRecordingAudio: {
      type: Boolean,
      default: false,
    },
    recordingAudioState: {
      type: String,
      default: '',
    },
    isSendDisabled: {
      type: Boolean,
      default: false,
    },
    showEditorToggle: {
      type: Boolean,
      default: false,
    },
    isOnPrivateNote: {
      type: Boolean,
      default: false,
    },
    enableMultipleFileUpload: {
      type: Boolean,
      default: true,
    },
    hasWhatsappTemplates: {
      type: Boolean,
      default: false,
    },
    conversationId: {
      type: Number,
      required: true,
    },
    message: {
      type: String,
      default: '',
    },
    showLocationModal: {
      type: Boolean,
      default: false,
    },
    toggleContactsModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      mediaRules: {
        audio: {
          types: [
            'audio/aac',
            'audio/mp4',
            'audio/amr',
            'audio/mpeg',
            'audio/ogg; codecs=opus',
          ],
          maxSize: 16 * 1024 * 1024, // 16MB
          errorMessage: (filename, max) => {
            bus.$emit(
              'newToastMessage',
              this.$t('CONVERSATION.REPLYBOX.AUDIO_ERROR', {
                filename,
                max,
              })
            );
          },
        },
        image: {
          types: ['image/jpeg', 'image/png'],
          maxSize: 5 * 1024 * 1024, // 5MB
          errorMessage: (filename, max) => {
            bus.$emit(
              'newToastMessage',
              this.$t('CONVERSATION.REPLYBOX.IMAGE_ERROR', {
                filename,
                max,
              })
            );
          },
        },
        sticker: {
          types: ['image/webp'],
          maxSize: 100 * 1024, // 100KB
          errorMessage: (filename, max) => {
            bus.$emit(
              'newToastMessage',
              this.$t('CONVERSATION.REPLYBOX.STICKER_ERROR', {
                filename,
                max,
              })
            );
          },
        },
        video: {
          types: ['video/mp4', 'video/3gpp'],
          maxSize: 16 * 1024 * 1024, // 16MB
          errorMessage: (filename, max) => {
            bus.$emit(
              'newToastMessage',
              this.$t('CONVERSATION.REPLYBOX.VIDEO_ERROR', {
                filename,
                max,
              })
            );
          },
        },
        document: {
          types: ['*/*'],
          maxSize: 100 * 1024 * 1024, // 100MB
          errorMessage: (filename, max) => {
            bus.$emit(
              'newToastMessage',
              this.$t('CONVERSATION.REPLYBOX.DOCUMENT_ERROR', {
                filename,
                max,
              })
            );
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
    }),
    isNote() {
      return this.mode === REPLY_EDITOR_MODES.NOTE;
    },
    wrapClass() {
      return {
        'is-note-mode': this.isNote,
      };
    },
    buttonClass() {
      return {
        warning: this.isNote,
      };
    },
    showAttachButton() {
      return this.showFileUpload || this.isNote;
    },
    showAudioRecorderButton() {
      // Disable audio recorder for safari browser as recording is not supported
      const isSafari = /^((?!chrome|android|crios|fxios).)*safari/i.test(
        navigator.userAgent
      );

      return (
        this.isFeatureEnabledonAccount(
          this.accountId,
          FEATURE_FLAGS.VOICE_RECORDER
        ) &&
        this.showAudioRecorder &&
        !isSafari
      );
    },
    showAudioPlayStopButton() {
      return this.showAudioRecorder && this.isRecordingAudio;
    },
    allowedFileTypes() {
      if (this.isATwilioWhatsAppChannel) {
        return ALLOWED_FILE_TYPES_FOR_TWILIO_WHATSAPP;
      }
      return ALLOWED_FILE_TYPES;
    },
    audioRecorderPlayStopIcon() {
      switch (this.recordingAudioState) {
        // playing paused recording stopped inactive destroyed
        case 'playing':
          return 'microphone-pause';
        case 'paused':
          return 'microphone-play';
        case 'stopped':
          return 'microphone-play';
        default:
          return 'microphone-stop';
      }
    },
    showMessageSignatureButton() {
      return !this.isOnPrivateNote && this.isAnEmailChannel;
    },
    sendWithSignature() {
      const { send_with_signature: isEnabled } = this.uiSettings;
      return isEnabled;
    },
    signatureToggleTooltip() {
      return this.sendWithSignature
        ? this.$t('CONVERSATION.FOOTER.DISABLE_SIGN_TOOLTIP')
        : this.$t('CONVERSATION.FOOTER.ENABLE_SIGN_TOOLTIP');
    },
    filteredItems() {
      return this.items.filter(item =>
        item.toLowerCase().includes(this.inputValue.toLowerCase())
      );
    },
  },
  mounted() {
    ActiveStorage.start();
  },
  methods: {
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        const fileType = newFile.type;
        const fileSize = newFile.size;

        let isValid = false;

        for (const mediaType in this.mediaRules) {
          const rules = this.mediaRules[mediaType];
          if (rules.types.includes(fileType) || rules.types.includes('*/*')) {
            if (fileSize <= rules.maxSize) {
              isValid = true;
              break;
            } else {
              rules.errorMessage(newFile.name, rules.maxSize);
              prevent();
              return;
            }
          }
        }

        if (!isValid) {
          bus.$emit(
            'newToastMessage',
            this.$t('CONVERSATION.REPLYBOX.FILE_UNSUPPORTED')
          );
          prevent();
        }
      }
    },
    onUpload(file) {
      this.onFileUpload(file);
      this.show = false;
    },
    onToggleContactsModal() {
      this.toggleContactsModal = !this.toggleContactsModal;
      this.show = false;
    },
    showModalNewConversation() {
      this.toggleContactsModal = true;
      this.show = false;
    },
    hideContactsModal() {
      this.toggleContactsModal = false;
    },
    selectLocation(addressData) {
      this.toggleLocation(addressData);
      this.showLocationModal = false;
    },
    resetInput() {
      this.address = '';
      this.$refs.address.clear();
    },
    handleKeyEvents(e) {
      if (hasPressedAltAndAKey(e)) {
        this.$refs.upload.$children[1].$el.click();
      }
    },
    toggleOptionsStatus() {
      this.show = !this.show;
    },
    toggleMessageSignature() {
      this.updateUISettings({
        send_with_signature: !this.sendWithSignature,
      });
    },
    toggleLocationModal() {
      this.show = false;
      this.showLocationModal = !this.showLocationModal;
    },
    replaceText(text) {
      this.$emit('replace-text', text);
    },
    onClickAway() {
      if (this.show) this.show = false;
    },
    handleInput() {
      this.showResults = true;
    },
    selectItem(item) {
      this.inputValue = item;
      this.showResults = false;
    },
    onClose() {
      this.showLocationModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-box {
  display: flex;
  justify-content: space-between;
  padding: var(--space-slab) var(--space-normal);

  &.is-note-mode {
    background: var(--y-50);
  }
}

.left-wrap .button {
  margin-right: var(--space-small);
}

.left-wrap {
  align-items: center;
  display: flex;
}

.right-wrap {
  display: flex;
}

::v-deep .file-uploads {
  label {
    cursor: pointer;
  }
  &:hover .button {
    background: var(--s-100);
  }
}

.modal-mask {
  color: var(--s-600);
  background: var(--white-transparent);
  flex-direction: column;
}

.page-sub-title {
  color: var(--s-600);
}

.icon {
  font-size: 8rem;
}

.options-menu.dropdown-pane {
  left: var(--space-small);
  bottom: var(--space-larger);
  position: absolute;
  margin-right: unset;
  z-index: 100 !important;
  .button {
    margin-right: unset;
  }
}

.btn-user {
  background: var(--s-50) !important;
}

.btn-user .box {
  display: flex;
  align-items: center;
  gap: 3px;
}

.google-autocomplete {
  z-index: 9999;
}

.google-autocomplete-input {
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 20px 20px 0 20px;
}
.pac-container {
  z-index: 9999 !important;
}
</style>
