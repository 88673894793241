<template>
  <woot-modal :show.sync="show" :on-close="onCancel" :custom-z-index="-1">
    <div class="column content-box modal-new-conversation">
      <woot-modal-header
        :header-title="$t('NEW_CONVERSATION.TITLE')"
        :header-subtitle="
          contact.whatsapp_valid
            ? $t('CONVERSATION.HEADER.VALID_WHATSAPP')
            : null
        "
        :header-content="$t('NEW_CONVERSATION.DESC')"
      />
      <conversation-form
        :contact="contact"
        :contact-conversations="contactConversations"
        :on-submit="onSubmit"
        @success="onSuccess"
        @cancel="onCancel"
      />
    </div>
  </woot-modal>
</template>

<script>
import ConversationForm from './ConversationForm';
import { mapGetters } from 'vuex';

export default {
  components: {
    ConversationForm,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Object,
      default: () => ({}),
    },
    contactConversations: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
  },
  watch: {
    'contact.id'(id) {
      this.$store.dispatch('contacts/fetchContactableInbox', id);
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onSuccess() {
      this.$emit('success');
    },
    async onSubmit(contactItem) {
      const inbox = this.$store.getters['inboxes/getInbox'](
        contactItem?.inboxId
      );
      if (
        this.currentUser?.message_signature &&
        inbox?.channel_type !== 'Channel::Whatsapp' &&
        contactItem?.message?.content
      ) {
        contactItem.message.content = `${this.currentUser.message_signature} \n\n ${contactItem.message.content}`;
      }
      const data = await this.$store.dispatch(
        'contactConversations/create',
        contactItem
      );
      return data;
    },
  },
};
</script>
