import Onboard from '../../api/onboard';

export const actions = {
  async createOnboard({ commit }, body) {
    try {
      const response = await Onboard.create(body);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  actions,
};
