<template>
  <div class="multiselect-wrap--small">
    <multiselect
      class="no-margin"
      track-by="name"
      label="name"
      v-model="selectedOption"
      :placeholder="$t('CSAT_REPORTS.FILTERS.RESOLVED.PLACEHOLDER')"
      selected-label
      :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
      deselect-label=""
      :options="options"
      :show-labels="false"
      @select="handleInput"
    />
  </div>
</template>
<script>

export default {
  name: 'ResolvedFilters',
  data() {
    return {
      selectedOption: null,
    };
  },
  computed: {
    options(){
      return [
        { name: this.$t('CSAT_REPORTS.TABLE.VALUES_RESOLVED.TRUE') , id: 'true'},
        { name: this.$t('CSAT_REPORTS.TABLE.VALUES_RESOLVED.FALSE') , id: 'false'}
      ]
    }
  },
  methods: {
    handleInput(e) {
      this.$emit('problem-resolve-filter-selection', e);
    },
  },
};
</script>
