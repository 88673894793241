<template>
  <woot-modal :show.sync="show" :on-close="onCancel">
    <div class="column content-box">
      <woot-modal-header :header-title="$t('TICKET.EDIT.TITLE')" :header-content="$t('TICKET.EDIT.DESC')" />
      <form class="roww" @submit.prevent="updateTicket()">
        <div>
          <label :class="{ error: $v.text.$error }">
            {{ $t('TICKET.FORM.TEXT.LABEL') }}
            <input v-model.trim="text" type="text" :placeholder="$t('TICKET.FORM.TEXT.PLACEHOLDER')"
              @input="$v.text.$touch" />
          </label>
        </div>
        <div class="description">
          <label :class="{ error: $v.description.$error }">
            {{ $t('TICKET.FORM.DESCRIPTION.LABEL') }}
            <textarea rows="2" v-model.trim="description" :placeholder="$t('TICKET.FORM.DESCRIPTION.PLACEHOLDER')"
              @input="$v.description.$touch" />
          </label>
        </div>

        <div>
          <label :class="{ error: $v.assignedAgent.$error }">
            {{ $t('TICKET.FORM.ASSIGNED_AGENT.LABEL') }}
            <select v-model="assignedAgent">
              <option value="" disabled>{{ this.$t('TICKET.FORM.ASSIGNED_AGENT.PLACEHOLDER') }}</option>
              <option v-for="agent in agentList" :key="agent.id" :value="agent.id">
                {{ agent.name }}
              </option>
            </select>
          </label>
        </div>

        <div v-if="!isCrmPage">
          <label :class="{ error: $v.kanbanfunnelSelected.$error }">
            {{ $t('TICKET.FORM.KANBAN.LABEL') }}
            <select v-model="kanbanfunnelSelected">
              <option value="" disabled>{{ this.$t('TICKET.FORM.KANBAN.PLACEHOLDER') }}</option>
              <option v-for="kanban in listKanbanActive" :key="kanban.id" :value="kanban">
                {{ kanban.name }}
              </option>
            </select>
          </label>
        </div>

        <div v-if="kanbanfunnelSelected && !isCrmPage">
          <label :class="{ error: $v.columnSelected.$error }">
            {{ $t('TICKET.FORM.COLUMN.LABEL') }}
            <select v-model="columnSelected">
              <option value="" disabled>{{ this.$t('TICKET.FORM.COLUMN.PLACEHOLDER') }}</option>
              <option v-for="colum in kanbanfunnelSelected.columns" :key="colum.id" :value="colum.id">
                {{ colum.name }}
              </option>
            </select>
          </label>
        </div>

        <div v-if="isFunnelSale">
            <label>
              {{ $t('TICKET.FORM.SALE_VALUE.LABEL') }}
              <input
                v-money="money"
                type="text"
                class="input-margin-bottom"
                v-model="saleValue"
                :placeholder="$t('TICKET.FORM.SALE_VALUE.PLACEHOLDER')"
              />
            </label>
        </div>

        <div>
          <label :class="{ error: $v.prioritySelected.$error }">
            {{ $t('TICKET.FORM.PRIORITY.LABEL') }}
            <select v-model="prioritySelected">
              <option value="" disabled>{{ this.$t('TICKET.FORM.PRIORITY.PLACEHOLDER') }}</option>
              <option v-for="(priority, index) in priorityOptions" :key="index" :value="priority.value">
                {{ priority.name }}
              </option>
            </select>
          </label>
        </div>

        <div>
          <settings-section class="settings-select" :title="$t('TICKET.FORM.LABEL.LABEL')"
            :sub-title="$t('TICKET.FORM.LABEL.DESC')">
            <multiselect v-model="labelsSelected" :options="labels" track-by="id" label="title" :multiple="true"
              :close-on-select="false" :clear-on-select="false" :hide-selected="true" placeholder="Pick some"
              selected-label :select-label="$t('FORMS.MULTISELECT.CLICK_TO_SELECT')"
              :deselect-label="$t('FORMS.MULTISELECT.CLICK_TO_REMOVE')" />
          </settings-section>

        </div>

        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button :disabled="$v.$invalid || showLoading" :button-text="$t('TICKET.EDIT.SAVE')"
              :loading="showLoading" class="bg-primary" />
            <button class="button clear" @click.prevent="onCancel">
              {{ $t('CRM_MGMT.FORM.CANCEL') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </woot-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import { required, minLength } from 'vuelidate/lib/validators';
import SettingsSection from '../../../../components/SettingsSection.vue';

export default {
  name: 'ModalEditTicket',
  components: { SettingsSection },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    ticket: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      text: '',
      description: '',
      prioritySelected: '',
      kanbanfunnelSelected: {},
      columnSelected: '',
      labelsSelected: [],
      assignedAgent: '',
      showLoading: false,
      saleValue: null,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2
      }
    }
  },
  validations: {
    text: {
      required,
      minLength: minLength(2),
    },
    description: {},
    kanbanfunnelSelected: {
      required,
    },
    columnSelected: {
      required,
    },
    prioritySelected: {
      required,
    },
    assignedAgent: {
      required,
    }
  },
  computed: {
    ...mapGetters({
      labels: 'labels/getLabels',
      currentUser: 'getCurrentUser',
      kanbanList: 'getKanbanfunnels',
      agentList: 'agents/getAgents'
    }),

    isCrmPage() {
      return this.$route.name == 'crm';
    },

    isFunnelSale(){
      return this.kanbanfunnelSelected.enable_sales_funnel;
    },

    priorityOptions() {
      return [
        { name: this.$t('TICKET.FORM.PRIORITY.OPTIONS.LOW'), value: 'low' },
        { name: this.$t('TICKET.FORM.PRIORITY.OPTIONS.MEDIUM'), value: 'medium' },
        { name: this.$t('TICKET.FORM.PRIORITY.OPTIONS.HIGH'), value: 'high' }
      ];
    },

    listKanbanActive() {
      return this.kanbanList?.filter((kanban) => kanban.active);
    }
  },

  mounted() {
    this.setValuesDefault();
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onSuccess() {
      this.$emit('success');
    },

    setValuesDefault() {
      this.text = this.ticket.text;
      this.description = this.ticket.description;
      this.prioritySelected = this.ticket.priority;
      this.columnSelected = this.ticket?.columns_id;
      this.assignedAgent = this.ticket.user_id;
      this.labelsSelected = this.labels.filter((label) => this.ticket?.labels_ids.includes(label.id));

    if (this.ticket.sale_value) {
      this.saleValue = this.ticket.sale_value * 100;
    } else {
      this.saleValue = null;
    }

      const kanbanSelected = this.kanbanList?.find(kanban => kanban.id === this.ticket?.kanbanfunnel_id);
      this.kanbanfunnelSelected = kanbanSelected;
    },

    async updateTicket() {
      this.showLoading = true;

      if(!this.isFunnelSale){
        this.saleValue = null;
      }

      const numericSaleValue = this.saleValue 
        ? Number(this.saleValue.replace(/[^\d]/g, '')) / 100 
      : null;

      const payload = {
        user_id: this.assignedAgent,
        id: this.ticket.id,
        timestamp: this.ticket.timestamp,
        priority: this.prioritySelected,
        text: this.text,
        description: this.description,
        kanbanfunnel_id: this.kanbanfunnelSelected.id,
        columns_id: this.columnSelected,
        labels_ids: this.labelsSelected?.map(label => label.id),
        sale_value: numericSaleValue
      };

      await this.$store.dispatch('updateTicket', payload);
      this.showLoading = false;
      this.showAlert(this.$t('TICKET.EDIT.API.SUCCESS_MESSAGE'));
      
      
      
      if (this.isCrmPage) {
        this.$emit('updateTicket', payload);
        
        if(this.ticket.sale_value !== numericSaleValue){
          const data = {
            old: this.ticket.sale_value,
            new: numericSaleValue,
            ticket: payload
          }
          this.$store.dispatch('changeSaleValueTicket', data);
        }
      
      }
      this.onCancel();
    },

    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
  },

  watch: {
    'ticket.labels_ids': {
      handler(newLabelsIds) {
        this.labelsSelected = this.labels.filter((label) => newLabelsIds.includes(label.id));
      },
      deep: true,
      immediate: true,
    }
  }

};
</script>

<style scoped>
.form {
  padding: var(--space-normal) var(--space-large) var(--space-large);
}

.card-content {
  display: flex;
  gap: 2rem;
}
.input-margin-bottom {
  margin-bottom: 16px;
}

.description {
  min-width: 220px;
}
</style>