<template>
    <div v-if="tickets.length" class="container-ticket">
        <div class="card-ticket" v-for="ticket in tiketsByContacts" :key="ticket.id">
            <div>
                <div class="contact-name">{{ contact.name }}</div>
                <h4 class="title">
                    {{ truncatedTitle(`#${ticket.id} ${ticket.text}`) }}
                </h4>
                <div :class="['priority', ticket.priority]">
                    {{ priorityTranslate(ticket.priority) }}
                </div>
            </div>
            <div class="ticket--meta">
                <span class="timestamp">
                    <time-ago :last-activity-timestamp="ticket.timestamp" />
                </span>
                <div>
                    <woot-button
                        icon="more-vertical"
                        color-scheme="secondary"
                        variant="clear"
                        size="small"
                        @click.stop="openContextMenu(ticket.id)"
                    />
                </div>
            </div>
            <div v-if="showContextMenuId === ticket.id" class="menu-container">
                <menu-item
                    :option="{
                        icon: 'edit',
                        label: $t('CRM_MGMT.FORM.EDIT'),
                    }"
                    @click="toggleUpdateTicketModal(ticket)"
                    variant="icon"
                />
                <menu-item
                    :option="{
                        icon: 'delete',
                        label: $t('CRM_MGMT.FORM.DELETE'),
                    }"
                    @click="openDeletePopup(ticket)"
                    variant="icon"
                />
            </div>
        </div>

        <woot-delete-modal 
            :show.sync="showDeleteModal" 
            :on-close="closeDeletePopup"
            :on-confirm="confirmDeletion" 
            :title="$t('CRM_MGMT.DELETE.CONFIRM.TITLE')"
            :message="$t('CRM_MGMT.DELETE.CONFIRM.MESSAGE')" 
            :message-value="deleteMessage" 
            :confirm-text="deleteConfirmText"
            :reject-text="$t('CRM_MGMT.DELETE.CONFIRM.NO')" 
        />

        <modal-update-ticket 
            v-if="showUpdateModal"
            :show="showUpdateModal"
            :ticket="selectedTicketUpdate"
            @cancel="toggleUpdateTicketModal"
        />
    </div>
</template>

<script>
  import alertMixin from 'shared/mixins/alertMixin';
  import { mapGetters } from 'vuex';
  import TimeAgo from 'dashboard/components/ui/TimeAgo';
  import menuItem from '../../../../components/widgets/conversation/contextMenu/menuItem.vue';
  import ModalUpdateTicket from './ModalUpdateTicket.vue';

  export default {
    name: 'ListTickets',
    components: {
      TimeAgo,
      menuItem,
      ModalUpdateTicket
    },
    mixins: [alertMixin],
    props: {
      contact: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        showContextMenuId: null,
        showDeleteModal: false,
        showUpdateModal: false,
        selectedTicket: {},
        selectedTicketUpdate: {}
      };
    },
    computed: {
      ...mapGetters({
        tickets: 'getTickets',
        currentUser: 'getCurrentUser',
      }),

      deleteConfirmText() {
          return `${this.$t('CRM_MGMT.DELETE.CONFIRM.YES')} 
              ${this.truncatedTitle(`#${this.selectedTicket.id} 
              ${this.selectedTicket.text}`)}
          `;
      },
      deleteMessage() {
          return ` ${this.selectedTicket.text}?`;
      },

      tiketsByContacts(){
        const listTickets = this.tickets.filter((ticket) => ticket.contact_id == this.contact.id);
        if(this.currentUser?.type_role == 'administrator' || 
          this.currentUser?.permissions?.see_all_crm_tickets
        ){
          return listTickets;
        }
        return listTickets.filter(ticket => ticket?.user_id == this.currentUser.id);
      }

    },
    mounted() {
      this.fetchData();
      document.addEventListener('click', this.handleOutsideClick);
    },
    beforeDestroy() {
      document.removeEventListener('click', this.handleOutsideClick);
    },
    methods: {
      priorityTranslate(priority){
        const key = priority.toUpperCase();
        const priorityLabel = this.$t('TICKET.FORM.PRIORITY.LABEL_CARD');
        return priorityLabel + ' ' + this.$t(`TICKET.FORM.PRIORITY.OPTIONS.${key}`);
      },
      
      fetchData() {
        this.$store.dispatch('getTicketsByContactId', this.contact.id);
      },
      truncatedTitle(text) {
        const maxCharacters = 25;
        return text.length > maxCharacters ? text.slice(0, maxCharacters) + '...' : text;
      },
      openContextMenu(id) {
        if (this.showContextMenuId === id) {
          this.closeContextMenu();
        } else {
          this.showContextMenuId = id;
        }
      },
      closeContextMenu() {
        this.showContextMenuId = null;
      },
      handleOutsideClick(event) {
        const menuContainer = this.$el.querySelector('.menu-container');
        if (menuContainer && !menuContainer.contains(event.target)) {
          this.closeContextMenu();
        }
      },

        openDeletePopup(ticket){
            this.selectedTicket = ticket;
            this.showDeleteModal = true;
            this.closeContextMenu()
        },

        closeDeletePopup() {
        this.showDeleteModal = false;
        },

        confirmDeletion(){
            this.$store
            .dispatch('deleteTicket', this.selectedTicket.id)
            .then(() => {
            this.showAlert(this.$t('TICKET.DELETE.API.SUCCESS_MESSAGE'));
            this.closeDeletePopup();
            })
            .catch(error => {
            const errorMessage =
                error?.message || this.$t('TICKET.DELETE.API.ERROR_MESSAGE');
            this.showAlert(errorMessage);
            });
        },

        showAlert(message) {
          bus.$emit('newToastMessage', message);
        },

        toggleUpdateTicketModal(ticket){
          if(ticket){
            this.selectedTicketUpdate = ticket;
          }
          this.showUpdateModal = !this.showUpdateModal;
          this.closeContextMenu()
        },

    },
    watch: {
      $route: {
        handler() {
          this.fetchData();
        },
      },
    },
  };
</script>

<style scoped lang="css">
.container-ticket {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}
.card-ticket {
  display: flex;
  padding: 1rem;
  justify-content: space-between;
}

.card-ticket:hover {
  background: var(--color-background-light);
}

.ticket--meta {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
}

.timestamp {
  color: var(--dark-gray);
  font-size: var(--font-size-micro);
  font-weight: var(--font-weight-normal);
  line-height: var(--space-normal);
  margin-left: auto;
}

.contact-name {
  align-items: center;
  display: inline-flex;
  padding: var(--space-micro) 0;
  line-height: var(--space-slab);
  font-weight: var(--font-weight-medium);
  background: none;
  color: var(--s-600);
  font-size: var(--font-size-micro);
  margin: 0 var(--space-one);
}

.title {
  padding-top: var(--space-micro);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: var(--font-size-mini);
}

.priority {
  display: inline;
  color: #fff;
  font-size: var(--font-size-nano);
  padding: 3px 18px;
  border-radius: 3px;
  font-weight: 600;
  box-shadow: 0px 0px 5px -2px rgba(134, 134, 162, 1);
}

.low {
  background: linear-gradient(89deg, #66a7f8 0, #8ea7fb 100%);
}

.medium {
  background: linear-gradient(89deg, #79d3b9 0, #81d5de 100%);
}

.high {
  background: linear-gradient(89deg, #cd74d1 0, #db78a3 100%);
}

.very-high {
  background: linear-gradient(89deg, #f96b6b 0%, #f98f49 100%);
}

.menu-container {
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 10px;
  z-index: 100;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}

.menu-item:hover {
  background-color: #f0f0f0;
}
</style>
