<template>
  <woot-modal class="text-left" show :on-close="onClose">
    <div class="column content message-editor">
      <p>
        <b>
          {{ displayContent() }}
        </b>
      </p>
      <br />
      <textarea v-model="modifiedContent" class="textarea" rows="5" cols="50" />
      <woot-button
        color-scheme="alert"
        class="action-button"
        variant="smooth"
        :disabled="isSaveDisabled"
        @click="saveEdit"
      >
        {{ this.$t('CONVERSATION.EDIT_MODAL.SAVE') }}
      </woot-button>

      <woot-button
        variant="clear"
        class="action-button btn-primary"
        @click="onClose"
      >
        {{ this.$t('CONVERSATION.EDIT_MODAL.CANCEL') }}
      </woot-button>
    </div>
  </woot-modal>
</template>
<script>
export default {
  props: {
    message: {
      type: String,
      default: '',
    },
    currentUser: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      modifiedContent: '',
      titleContent: '',
      isSaveDisabled: false,
    };
  },
  watch: {
    modifiedContent(newVal) {
      this.isSaveDisabled = newVal === this.titleContent;
    },
  },
  mounted() {
    let updatedContent = this.message;

    const indexSignature = updatedContent.indexOf('\n\n');
    if (indexSignature !== -1) {
      updatedContent = updatedContent
        .slice(indexSignature + 1)
        .trim();
    }

    this.modifiedContent = updatedContent;
    this.titleContent = updatedContent;
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    displayContent() {
      if (this.titleContent.length > 30) {
        return this.titleContent.substring(0, 30) + '...';
      }
      return this.titleContent;
    },
    saveEdit() {
      this.$emit('save-edit', this.modifiedContent);
      this.onClose();
    },
  },
};
</script>

<style>
.message-editor {
  border: 1px solid var(--s-200);
}
</style>
