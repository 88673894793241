<template>
  <div class="column page-top-bar">
    <img v-if="headerImage" :src="headerImage" alt="No image" />
    <div class="title-container">
      <h2 class="page-sub-title">
        {{ headerTitle }}
      </h2>
      <woot-button
        v-if="infoButton"
        v-tooltip.top="infoButton"
        type="button"
        class="tag-information"
        icon="info"
        disabled
      />
    </div>
    <p v-if="headerContent" class="small-12 column wrap-content">
      {{ headerContent }}
      <span v-if="headerContentValue" class="content-value">
        {{ headerContentValue }}
      </span>
      <br />
      <span v-if="headerSubtitle" class="subtitle">
        {{ headerSubtitle }}
      </span>
    </p>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    headerTitle: {
      type: String,
      default: '',
    },
    headerContent: {
      type: String,
      default: '',
    },
    headerContentValue: {
      type: String,
      default: '',
    },
    headerImage: {
      type: String,
      default: '',
    },
    infoButton: {
      type: String,
      default: '',
    },
    headerSubtitle: {
      type: String,
      default: '',
    },
  },
};
</script>
<style scoped lang="scss">
.wrap-content {
  word-wrap: break-word;
  margin-top: var(--space-small);

  .subtitle {
    font-weight: var(--font-weight-medium);
    background-color: #01c6d8;
    padding: 2px 5px 0px;
    color: #ffffff;
    border-radius: var(--border-radius-small);
  }

  .content-value {
    font-weight: var(--font-weight-bold);
  }
}
.tag-information {
  background-color: #1a2638 !important;
  border-radius: 100%;
  margin-left: 10px;
  position: relative;
  width: 20px !important;
  height: 20px;
  opacity: 1 !important;
  cursor: default !important;
  color: var(--b-50) !important;
}
.title-container {
  display: flex;
  flex-direction: row;
}
</style>
