import types from '../mutation-types';
import GroupsApi from '../../api/group';

const state = {
    groupParticipants: []
};

export const getters = {
    getGroupParticipants(_state) {
      return _state.groupParticipants;
    },
    getGroupParticipantsMeta(_state) {
        return _state.groupParticipantsMeta;
      },
};

export const actions = {
    async fetchGroupParticipants({ commit }, payload){
        try {
            const response = await GroupsApi.getParticipants(payload)
            commit(types.SET_GROUP_PARTICIPANTS, response.data?.group_participants);
            commit(types.SET_GROUP_PARTICIPANTS_META, response.data?.meta);
        } catch (err) {
            throw new Error(err);
        }
    },
    async groupParticipantsConfig({commit},{url,jid,session_id,participants,type}){
        try{
            const payload = {
                session_id,
                type,
                participants
            }
            const response = await GroupsApi.manageParticipants(url,jid,payload)

            if(type === "demote" || type === "promote"){
                commit(types.SET_IS_ADMIN_GROUP_PARTICIPANTS,response.data[0])
            }

            if(type === "remove"){
                let valoresChave = new Set(response?.data.map(obj => obj['jid']));

                let result = state.groupParticipants.filter(obj => !valoresChave.has(obj['phone_number']));
                state.groupParticipants = result
                commit(types.SET_GROUP_PARTICIPANTS, result)
            }                        
            return response
        } catch (err){
            throw new Error(err);
        }
    }
}

export const mutations = {
    [types.SET_GROUP_PARTICIPANTS](state, posts) {
      state.groupParticipants = posts;
    },
    [types.SET_GROUP_PARTICIPANTS_META](state, posts) {
        state.groupParticipantsMeta = posts;
    },
    [types.SET_IS_ADMIN_GROUP_PARTICIPANTS](state,post){
        state.groupParticipants = state.groupParticipants.map(element => {
           if(element.phone_number === post.jid){
            return {
                ...element,
                is_admin: !element.is_admin
            };
        }
        return element;
        });
    },
    [types.DELETE_PARTICIPANTS_GROUP](state,post ){

    return state.groupParticipants;
    }
}

export default {
    actions,
    state,
    getters,
    mutations,
};