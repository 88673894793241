<template>
    <modal :show.sync="show" :on-close="onClose" size="medium">
      <div class="column content-box">
        <woot-modal-header
          :header-title="$t('CRM_MGMT.EDIT.TITLE')"

        />
        <form class="row" @submit.prevent="updateKanban()">
          <div class="medium-12 columns">
            <label :class="{ error: $v.name.$error }">
              {{ $t('CRM_MGMT.FORM.NAME.LABEL') }}
              <input
                v-model.trim="name"
                type="text"
                :placeholder="$t('CRM_MGMT.FORM.NAME.PLACEHOLDER')"
                @input="$v.name.$touch"
              />
            </label>
          </div>
  
          <div class="medium-12 columns">
            <label :class="{ error: $v.description.$error }">
              {{ $t('CRM_MGMT.FORM.DESCRIPTION.LABEL') }}
              <input
                type="text"
                v-model.trim="description"
                :placeholder="$t('CRM_MGMT.FORM.DESCRIPTION.PLACEHOLDER')"
                @input="$v.description.$touch"
              />
            </label>
          </div>
  
          <div class="medium-12 columns">
            <label>
              {{ $t('CRM_MGMT.FORM.COLUMN.TITLE') }}
            </label>
            <draggable v-model="columns" @end="updateColumnIndex">
              <div
                v-for="(column, index) in columns"
                :key="index"
                class="row column-item"
              >
                <i class="ion-android-menu drag-handle"></i>
                <input
                  v-model.trim="column.name"
                  type="text"
                  :placeholder="
                    $t('CRM_MGMT.FORM.COLUMN_NAME.PLACEHOLDER') + ' ' + (index + 1)
                  "
                />
                <woot-button
                  v-if="columns.length > 1"
                  v-tooltip.top="
                    $t('CRM_MGMT.FORM.COLUMN.REMOVE') + ' ' + (index + 1)
                  "
                  variant="smooth"
                  color-scheme="alert"
                  size="tiny"
                  icon="dismiss-circle"
                  class-names="grey-btn"
                  @click="removeColumn(index)"
                />
              </div>
            </draggable>
  
            <button
              class="button small success"
              @click="addColumn"
              type="button"
              v-if="columns.length < 10"
            >
              {{ $t('CRM_MGMT.FORM.COLUMN.ADD') }}
            </button>
          </div>

          <settings-section class="settings-select" :title="$t('CRM_MGMT.FORM.AGENTS.LABEL')"
            :sub-title="$t('CRM_MGMT.FORM.AGENTS.DESC')"
          >
            <multiselect v-model="selectedAgents" :options="agentList" track-by="id" label="name" :multiple="true"
              :close-on-select="false" :clear-on-select="false" :hide-selected="true" placeholder="Pick some" selected-label
              :select-label="$t('FORMS.MULTISELECT.CLICK_TO_SELECT')" :deselect-label="$t('FORMS.MULTISELECT.CLICK_TO_REMOVE')"
              @select="$v.selectedAgents.$touch" 
            />
          </settings-section>
  
          <div class="medium-12 columns status">
            <label>
              <input type="checkbox" v-model="active" />
              {{ $t('CRM_MGMT.FORM.ACTIVE.LABEL') }}
            </label>
          </div>

          <div class="medium-12 columns status">
            <label>
              <input type="checkbox" v-model="enableSalesFunnel" />
              {{ $t('CRM_MGMT.FORM.SALES_FUNNEL.LABEL') }}
            </label>
          </div>
  
          <div class="modal-footer">
            <div class="medium-12 columns">
              <woot-submit-button
                :disabled="$v.$invalid || showLoading"
                :button-text="$t('CRM_MGMT.FORM.EDIT')"
                :loading="showLoading"
                class="bg-primary"
              />
              <button class="button clear" @click.prevent="onClose">
                {{ $t('CRM_MGMT.FORM.CANCEL') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </modal>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  import { required, minLength } from 'vuelidate/lib/validators';
  import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
  import Modal from '../../../../components/Modal';
  import SettingsSection from '../../../../components/SettingsSection.vue';
  import alertMixin from 'shared/mixins/alertMixin';
  import draggable from 'vuedraggable';
  
  export default {
    components: {
      WootSubmitButton,
      Modal,
      draggable,
      SettingsSection
    },
    mixins: [alertMixin],
    props: {
      onClose: {
        type: Function,
        default: () => {},
      },
      kanban: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        name: '',
        description: '',
        columns: [],
        active: true,
        selectedAgents: [],
        showLoading: false,
        show: true,
        enableSalesFunnel: false
      };
    },
    validations: {
      name: {
        required,
        minLength: minLength(2),
      },
      description: {},
      columns: {
        $each: {
          name: {
            required,
            minLength: minLength(2),
          },
        },
      },
      selectedAgents: {}
    },
    computed: {
      ...mapGetters({
        agentList: 'agents/getAgents'
      }),
    },
    created() {
      this.setKanban();
    },
    methods: {
      setKanban() {
        this.name = this.kanban.name;
        this.description = this.kanban.description;
        this.columns = this.kanban.columns.map((column, index) => ({
          name: column.name,
          index,
          id:column.id
        }));
        this.active = this.kanban.active;
        this.enableSalesFunnel = this.kanban.enable_sales_funnel;
        this.selectedAgents = this.agentList.filter((agent) => this.kanban.agents_ids.includes(agent.id));
      },
      addColumn() {
        if (this.columns.length < 10) {
          this.columns.push({ name: '' });
        }
      },
      removeColumn(index) {
        if (this.columns.length > 1) {
          this.columns.splice(index, 1);
        }
      },
      updateColumnIndex() {
        this.columns.forEach((column, index) => {
          column.index = index;
        });
      },
      async updateKanban() {
        this.showLoading = true;

        const agents_ids = this.selectedAgents.map((agent) => agent.id);
        const payload = {
          id: this.kanban.id,
          agents_ids,
          name: this.name,
          description: this.description,
          columns: this.columns.map((column, index) => ({
            name: column.name,
            index,
            id:column.id
          })),
          active: this.active,
          enable_sales_funnel: this.enableSalesFunnel
        };
  
        await this.$store.dispatch('updateKanbanfunnel', payload);
        this.showLoading = false;
        this.showAlert(this.$t('CRM_MGMT.EDIT.API.SUCCESS_MESSAGE'));
        this.onClose();
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  .column-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  
    input {
      flex: 1;
      margin-right: 10px;
      margin-bottom: 0;
    }
  
    .remove-column-button {
      flex-shrink: 0;
    }
  }
  
  .status {
    margin-top: 1rem;
  }
  
  .modal-container .modal-footer .button {
    margin-left: 0;
  }
  
  .drag-handle {
    cursor: grab;
    margin-right: 10px;
    font-size: 20px;
  }
  .settings-select {
      width: 100%;
    }
  </style>
  