import SettingsContent from '../Wrapper';
import Index from './Index.vue';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/crm'),
      component: SettingsContent,
      props: {
        headerTitle: 'CRM_MGMT.HEADER',
        icon: 'crm',
        showNewButton: false,
      },
      children: [
        {
          path: '/',
          name: 'crm_settings',
          roles: ['administrator', 'agent'],
          component: Index,
        },
      ],
    },
  ],
};
