/* global axios */

import ApiClient from './ApiClient';

class IntegrationsAPI extends ApiClient {
  constructor() {
    super('integrations/apps', { accountScoped: true });
  }

  connectSlack(code) {
    return axios.post(`${this.baseUrl()}/integrations/slack`, {
      code: code,
    });
  }

  createKnowledgeBase(knowledgeBaseData) {
    return axios.post(`${this.baseUrl()}/integrations/knowledge_bases`, knowledgeBaseData);
  }

  updateKnowledgeBase(knowledgeBaseData) {
    return axios.patch(`${this.baseUrl()}/integrations/knowledge_bases/${knowledgeBaseData.id}`, knowledgeBaseData);
  }

  deleteKnowledgeBase(knowledgeBaseId) {
    return axios.delete(`${this.baseUrl()}/integrations/knowledge_bases/${knowledgeBaseId}`);
  }

  delete(integrationId) {
    return axios.delete(`${this.baseUrl()}/integrations/${integrationId}`);
  }

  createHook(hookData) {
    return axios.post(`${this.baseUrl()}/integrations/hooks`, hookData);
  }

  deleteHook(hookId) {
    return axios.delete(`${this.baseUrl()}/integrations/hooks/${hookId}`);
  }
}

export default new IntegrationsAPI();
