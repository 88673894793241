<template>
    <div @mouseenter="handleMouseEnter" @mouseleave="hadleMouseLeaver">
        <slot>
        
       </slot>
    </div>

</template>
<script>
import { VeCheckBox } from 'vue-easytable';

export default {
    components:{VeCheckBox},
    props: {
        mouseenter: {
            type: Function,
            default: () => { }
        },
        mouseleave: {
            type: Function,
            default: () => { }
        },
       
    },
        methods: {
            handleMouseEnter() {
                this.mouseenter()
            },
            hadleMouseLeaver() {
                this.mouseleave()
            },
        }
    
}
</script>

<style></style>