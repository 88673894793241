import Posts from '../../api/posts';
import types from '../mutation-types';

export const state = {
  posts: [],
};

export const getters = {
  getPosts(_state) {
    return _state.posts;
  },
};

export const actions = {
  async getPosts({ commit }) {
    try {
      const response = await Posts.getAll();
      commit(types.SET_POSTS, response.data);
    } catch (err) {
      console.log(err);
    }
  },
}

export const mutations = {
  [types.SET_POSTS](state, posts) {
    state.posts = posts.filter(post => post.active).reverse();
  },
}

export default {
  actions,
  state,
  getters,
  mutations,
};
