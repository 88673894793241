<template>
  <button
    :class="classColor"
    @click="handleClick"
  >
    {{ text }}
  </button>
</template>
<script>
import { mapGetters } from 'vuex';
import Thumbnail from '../../widgets/Thumbnail';
import wootConstants from 'dashboard/constants/globals';

const { AVAILABILITY_STATUS_KEYS } = wootConstants;


export default {
  name: "StatusDetails",
  components: {
    Thumbnail,
  },

  data(){
    return {
      text: "",
      classColor: "",
      availabilityStatusKeys: ['online', 'busy', 'offline'],
      statusList: {}
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      currentUserAvailability: 'getCurrentUserAvailability',
    }),

    statusTranslation(){
      return this.$t('PROFILE_SETTINGS.FORM.AVAILABILITY.STATUSES_LIST');
    },
  },
  watch: {
    statusTranslation(newValue){
     this.statusList = Object.keys(this.statusList).map((key, index) => {
        return {
          [key]: {
            ...this.statusList[key],
            status: newValue[index] 
          }
        }
      }).reduce((result, current) => {
        return {
          ...result,
          ...current
        };
      }, {});
      this.setColorStatus();
    },
    currentUserAvailability(){
      this.setColorStatus();
    }
  },
  mounted(){
    this.fetchStatusList();
    this.setColorStatus();
  },
  methods: {
    fetchStatusList(){ 
      this.statusList = this.availabilityStatusKeys.reduce(
        (status, key,index) => ({
          ...status,
          [key]: { 
            status: this.statusTranslation[index],
            classColor: `status-user ${key === 'offline' ? 'of' : key === 'online' ? 'on' : 'busy'}`
          },
        }),
        {}
      );
    },
    setColorStatus(){
      this.classColor = this.statusList[this.currentUserAvailability].classColor;
      this.text =  this.statusList[this.currentUserAvailability].status;
    },
    handleClick() {
      this.$emit('toggle-menu');
    },
  }
};
</script>

<style scoped>
  .status-user {
    background: var(--g-400);
    padding: 4px 5px;
    width: 110px;
    border-radius: 5px;
    color: var(--s-50);
    font-size: 12px;
    cursor: pointer;
    font-weight: bold;
  }

  .on {
    background: var(--g-400);
  }

  .of {
    background: var(--s-700);
  }

  .busy {
    background: var(--y-500);
  }
</style>

