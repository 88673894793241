<template>
  <div class="countdown-container">
    <div class="countdown-circle">
      <svg class="progress-ring" width="44" height="44">
        <circle
          class="progress-ring__circle"
          :stroke="circleColor"
          stroke-width="2"
          fill="transparent"
          r="20"
          cx="22"
          cy="22"
          :stroke-dasharray="circumference + ' ' + circumference"
          :stroke-dashoffset="progress / 100 * circumference"
        />
      </svg>
      <div class="content">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    progress: {
      type: Number,
      default: 0
    },
    initializeControlTiming:{
      type:Boolean,
      default: false
    }
  },
  data() {
    return {
      circumference: 2 * Math.PI * 20,
    };
  },

  computed: {
    circleColor() {
      if (!this.initializeControlTiming) {
        return "transparent";
      }

      if (this.progress <= 25) return "#01C6D8";
      if (this.progress <= 50) return "#018FA6";
      if (this.progress <= 75) return "#014C75";
      
      return "#002136";
    },
  },
};
</script>

<style scoped>
.countdown-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.countdown-circle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.5s ease;
  margin-top: 25px
}

.content {
    bottom: 34%;
    left: 4.5%;
    /* bottom: 15px; */
    width: 40px;
    height: 40px;
    position: absolute;
    z-index: 1;
}
.content input[type='checkbox']{
    position: relative;
    bottom: 1%;
    left: 1.5%;

}

.progress-ring__circle {
  transition: stroke-dashoffset 0.5s linear;
  transform: rotate(-90deg);
  transform-origin: center;
}
</style>
