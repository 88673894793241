<template>
  <div>
    <div class="contact--profile">
      <div class="contact--info">
        <div class="contact-info--header">
          <thumbnail v-if="showAvatar" :src="contact.thumbnail" size="56px" :username="contact.name"
            :status="contact.availability_status" />
          <woot-button v-if="showCloseButton" :icon="closeIconName" class="clear secondary close-button--rtl"
            @click="onPanelToggle" />
        </div>

        <div class="contact--details">
          <div v-if="showAvatar || isTicket" class="contact--name-wrap">
            <h3 class="sub-block-title contact--name">
              {{ contact.name }}
            </h3>
            <fluent-icon v-if="contact.created_at && !isTicket" v-tooltip="`${$t('CONTACT_PANEL.CREATED_AT_LABEL')} ${dynamicTime(
              contact.created_at
            )}`
              " icon="info" size="14" />
            <a :href="contactProfileLink" class="fs-default" target="_blank" rel="noopener nofollow noreferrer">
              <woot-button size="tiny" icon="open" variant="clear" color-scheme="secondary" />
            </a>
          </div>

          <p v-if="additionalAttributes.description" class="contact--bio">
            {{ additionalAttributes.description }}
          </p>
          <social-icons :social-profiles="socialProfiles" />
          <div v-if="hasDescription">
            <div :class="className" v-html="descriptionFormatter" />
            <div v-if="isLong" class="description-size" @click="handleDescription">
              {{
                showMore
                  ? $t('CONVERSATION_SIDEBAR.SHOW_LESS')
                  : $t('CONVERSATION_SIDEBAR.SHOW_MORE')
              }}
            </div>
          </div>
          <div v-if="!isGroup" class="contact--metadata">
            <contact-info-row :href="contact.email ? `mailto:${contact.email}` : ''" :value="contact.email" icon="mail"
              emoji="✉️" :title="$t('CONTACT_PANEL.EMAIL_ADDRESS')" show-copy />
            <contact-info-row :href="contact.phone_number ? `tel:${contact.phone_number}` : ''"
              :value="contact.phone_number" :icon="contact.whatsapp_valid ? 'whatsapp' : 'call'" emoji="📞"
              :title="$t('CONTACT_PANEL.PHONE_NUMBER')" show-copy />
            <contact-info-row v-if="contact.identifier" :value="contact.identifier" icon="contact-identify" emoji="🪪"
              :title="$t('CONTACT_PANEL.IDENTIFIER')" />
            <contact-info-row :value="additionalAttributes.company_name" icon="building-bank" emoji="🏢"
              :title="$t('CONTACT_PANEL.COMPANY')" />
            <contact-info-row v-if="location || additionalAttributes.location"
              :value="location || additionalAttributes.location" icon="map" emoji="🌍"
              :title="$t('CONTACT_PANEL.LOCATION')" />
          </div>
        </div>
        <div v-if="!isTicket" class="contact-actions">
          <woot-button v-tooltip="$t('CONTACT_PANEL.NEW_MESSAGE')" title="$t('CONTACT_PANEL.NEW_MESSAGE')"
            class="new-message bg-primary" icon="chat" size="small" @click="handleConversations" />
          <woot-button v-tooltip="$t('EDIT_CONTACT.BUTTON_LABEL')" title="$t('EDIT_CONTACT.BUTTON_LABEL')"
            class="edit-contact" icon="edit" variant="smooth" size="small" @click="toggleEditModal" />
          <woot-button v-tooltip="$t('CONTACT_PANEL.MERGE_CONTACT')" title="$t('CONTACT_PANEL.MERGE_CONTACT')"
            class="merge-contact" icon="merge" variant="smooth" size="small" color-scheme="secondary"
            :disabled="uiFlags.isMerging" @click="openMergeModal" />
          <woot-button v-if="conversationId" v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.TIP_FORMAT_ICON')"
            class="send-contact" icon="send" emoji="🖊️" color-scheme="secondary" variant="smooth" size="small"
            @click="sendContact" />

          <woot-button v-if="permissionDeleteContact && !isGroup" v-tooltip="$t('DELETE_CONTACT.BUTTON_LABEL')"
            title="$t('DELETE_CONTACT.BUTTON_LABEL')" class="delete-contact" icon="delete" variant="smooth" size="small"
            color-scheme="alert" :disabled="uiFlags.isDeleting" @click="toggleDeleteModal" />

          <woot-button icon="add" v-if="permissionCreateTicket" variant="smooth" v-tooltip="$t('TICKET.CREATE.TITLE')"
            size="small" @click="toggleNewTicketModal" />

          <woot-button v-if="isGroup" v-tooltip="$t('GROUP_PARTICIPANT.TITLE')" title="$t('GROUP_PARTICIPANT.TITLE')"
            icon="people" variant="smooth" size="small" class="participants-group" color-scheme="secondary"
            @click="toggleParticipantsGroups" />
          <woot-button v-if="isGroup" v-tooltip="$t('GROUP_PARTICIPANT.ADD_TITLE')"
            title="$t('GROUP_PARTICIPANT.ADD_TITLE')" icon="add" variant="smooth" size="small" color-scheme="secondary"
            @click="toggleAddNewParticipantGroup" />
        </div>

        <div v-if="isTicket" class="contact-actions">
          <woot-button v-tooltip="$t('CONTACT_PANEL.NEW_MESSAGE')" title="$t('CONTACT_PANEL.NEW_MESSAGE')"
            class="new-message bg-primary" icon="chat" size="small" @click="handleConversations" />

          <woot-button v-tooltip="$t('CRM_MGMT.FORM.EDIT')" class="edit-contact" icon="edit" variant="smooth"
            size="small" @click="toggleUpdateTicketModal" />

          <woot-button v-tooltip="$t('TICKET.DELETE.LABEL')" class="delete-contact" icon="delete" variant="smooth"
            size="small" color-scheme="alert" :disabled="uiFlags.isDeleting" @click="toggleDeleteTicketModal" />
        </div>

        <div v-if="isTicket" class="option-ticket">
          <div class="task-item-body card-select">
            <div class="task-priority" :class="ticket.priority" v-if="showTaskPriority" @click="changePriority">
              {{ this.$t('TICKET.FORM.PRIORITY.LABEL_CARD') }}
              {{ priorityTranslate }}
            </div>
            <v-select v-if="showTaskPriorityDropdown" v-model="ticket.priority" ref="vueDropdown"
              :options="priorityOptions.map(option => ({ label: option.name, value: option.value }))"
              @search:blur="setNewPriority" :clearable="false" :closeOnSelect="true" :reduce="option => option.value"
              class="custom-v-select">
            </v-select>
          </div>
        </div>


      </div>
    </div>
    <div v-if="isTicket">
      <accordion-item :title="$t('TICKET.FORM.LABEL.LABEL')"
        :is-open="isContactSidebarItemOpen('is_previous_tickets_open')" @click="value => toggleSidebarUIState('is_previous_tickets_open', value)
          ">
        <woot-button v-if="labelsSelected.length < 1" icon="add" variant="smooth" size="small"
          @click="toggleLabelDropdown">
          {{ this.$t('TICKET.FORM.LABEL.ADD') }}
        </woot-button>
        <div>
          <div>
            <multiselect v-if="showLabelDropdown" v-model="selectedLabels" :options="labels" track-by="id" label="title"
              :multiple="true" :close-on-select="false" :clear-on-select="false" :hide-selected="true"
              placeholder="Pick some" selected-label :select-label="$t('FORMS.MULTISELECT.CLICK_TO_SELECT')"
              :deselect-label="$t('FORMS.MULTISELECT.CLICK_TO_REMOVE')" @input="setNewLabels" />
            <woot-button v-if="showLabelDropdown" size="tiny" color-scheme="primary" icon="save" @click="saveLabels">
              {{ this.$t('TICKET.FORM.TEXT.SAVE') }}
            </woot-button>
          </div>
          <div class="flex" @click="toggleLabelDropdown">
            <woot-label v-for="label in filteredLabels" :title="label.title" :color="label.color" :key="label.id"
              variant="smooth" class="cursor-pointer" small />
          </div>
          <woot-button v-if="validateLabels >= 22" :title="$t('CONVERSATION.CARD.SHOW_LABELS')" class="show-more--button"
            color-scheme="secondary" variant="hollow" icon='chevron-right' size="tiny" @click="toggleUpdateTicketModal" />
        </div>
      </accordion-item>
    </div>

    <edit-contact v-if="showEditModal" :show="showEditModal" :contact="contact" @cancel="toggleEditModal" />
        <new-conversation v-if="contact.id" :show="showConversationModal" :contact-conversations="contactConversations"
          :contact="contact" @success="closeConversationModal" @cancel="toggleConversationModal" />
        <contact-merge-modal v-if="showMergeModal" :primary-contact="contact" :show="showMergeModal"
          @close="toggleMergeModal" />
          <woot-delete-modal v-if="showDeleteModal" :show.sync="showDeleteModal" :on-close="closeDelete"
        :on-confirm="confirmDeletion" :title="$t('DELETE_CONTACT.CONFIRM.TITLE')"
        :message="$t('DELETE_CONTACT.CONFIRM.MESSAGE')" :message-value="confirmDeleteMessage"
        :confirm-text="$t('DELETE_CONTACT.CONFIRM.YES')" :reject-text="$t('DELETE_CONTACT.CONFIRM.NO')" />
      <woot-delete-modal v-if="showDeleteTicketModal" :show.sync="showDeleteTicketModal" :on-close="closeDeleteTicket"
        :on-confirm="confirmDeletionTicket" :title="$t('DELETE_CONTACT.CONFIRM.TITLE')"
        :message="$t('DELETE_CONTACT.CONFIRM.MESSAGE')" :message-value="confirmDeleteMessageText"
        :confirm-text="$t('DELETE_CONTACT.CONFIRM.YES')" :reject-text="$t('DELETE_CONTACT.CONFIRM.NO')" />
      <modal-participants-group v-if="showGroupParticipants" :data="groupParticipants"
        :conversation-info="conversationAttributes" @close="toggleParticipantsGroups" />
      <modal-add-new-participant v-if="showAddNewParticipant" :data="groupParticipants"
        :conversation-info="conversationAttributes" @close="toggleAddNewParticipantGroup" />

      <modal-new-ticket v-if="showNewTicket" :show="showNewTicket" :contact="contact" @cancel="toggleNewTicketModal" />

      <modal-update-ticket v-if="showUpdateTicket" :ticket="ticket" :show="showUpdateTicket"
        @cancel="toggleUpdateTicketModal" @updateTicket="updateTicket" />
  </div>
</template>
<script>
import { mixin as clickaway } from 'vue-clickaway';
import timeMixin from 'dashboard/mixins/time';
import ContactInfoRow from './ContactInfoRow';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import SocialIcons from './SocialIcons';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import wootConstants from 'dashboard/constants/globals';
import EditContact from './EditContact';
import NewConversation from './NewConversation';
import { ExceptionWithMessage } from 'shared/helpers/CustomErrors';
import ContactMergeModal from 'dashboard/modules/contact/ContactMergeModal';
import alertMixin from 'shared/mixins/alertMixin';
import adminMixin from '../../../../mixins/isAdmin';
import { mapGetters } from 'vuex';
import { getCountryFlag } from 'dashboard/helper/flag';
import ModalParticipantsGroup from './ModalParticipantsGroup.vue';
import ModalAddNewParticipant from './ModalAddNewParticipant.vue';
import ModalNewTicket from './ModalNewTicket.vue';
import ModalUpdateTicket from './ModalUpdateTicket.vue';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import AccordionItem from 'dashboard/components/Accordion/AccordionItem';

export default {
  components: {
    ContactInfoRow,
    EditContact,
    Thumbnail,
    SocialIcons,
    NewConversation,
    ContactMergeModal,
    ModalParticipantsGroup,
    ModalAddNewParticipant,
    ModalNewTicket,
    vSelect,
    ModalUpdateTicket,
    AccordionItem
  },
  mixins: [alertMixin, adminMixin, clickaway, timeMixin, messageFormatterMixin, uiSettingsMixin],
  props: {
    conversation: {
      type: Object,
      default: () => ({}),
    },
    contact: {
      type: Object,
      default: () => ({}),
    },
    channelType: {
      type: String,
      default: '',
    },
    showAvatar: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    closeIconName: {
      type: String,
      default: 'chevron-right',
    },
    conversationId: {
      type: Number,
      default: undefined,
    },
    onSend: {
      type: Function,
      default: () => { },
    },
    message: {
      type: String,
      default: '',
    },
    toggleContact: {
      type: Function,
      default: () => { },
    },
    ticketSelected: {
      type: Object,
      default: () => ({}),
    },
    isTicket: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      showEditModal: false,
      showConversationModal: false,
      showMergeModal: false,
      showDeleteModal: false,
      showDeleteTicketModal: false,
      showMore: false,
      showGroupParticipants: false,
      showAddNewParticipant: false,
      showNewTicket: false,
      acceptChannelTypes: ['Channel::Api'],
      setPage: 1,
      showTaskPriorityDropdown: false,
      showTaskPriority: true,
      showUpdateTicket: false,
      ticket: this.ticketSelected,
      showLabelDropdown: false,
      selectedLabels: []
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
      inboxes: 'inboxes/getInboxes',
      currentUser: 'getCurrentUser',
      getAccount: 'accounts/getAccount',
      currentAccountId: 'getCurrentAccountId',
      groupParticipants: 'getGroupParticipants',
      groupParticipantsMeta: 'getGroupParticipantsMeta',
      labels: 'labels/getLabels',
    }),

    permissionCreateTicket() {
      const account = this.getAccount(this.currentAccountId);
      if (!account?.enable_crm) {
        return false;
      }
      return true;
    },

    labelsSelected() {
      if (!this.isTicket || !this.ticket?.labels_ids.length) return [];
      const labels = this.labels?.filter((label) => this.ticket?.labels_ids.includes(label.id));
      this.selectedLabels = labels;
      return labels
    },

    filteredLabels() {
      let totalCharacters = 0;

      return this.labelsSelected.filter((label) => {
        const titleLength = label.title.length;

        if (titleLength >= 22) return false;

        if (totalCharacters + titleLength <= 22) {
          totalCharacters += titleLength;
          return true;
        }

        return false;
      });
    },

    validateLabels() {
      const selectedLabels = this.labelsSelected
      const totalCharacters = selectedLabels.reduce((acc, label) => {
        return acc + (label.title ? label.title.length : 0);
      }, 0);
      return totalCharacters;
    },

    priorityOptions() {
      return [
        { name: this.$t('TICKET.FORM.PRIORITY.OPTIONS.LOW'), value: 'low' },
        { name: this.$t('TICKET.FORM.PRIORITY.OPTIONS.MEDIUM'), value: 'medium' },
        { name: this.$t('TICKET.FORM.PRIORITY.OPTIONS.HIGH'), value: 'high' }
      ];
    },

    priorityTranslate() {
      if (!this.isTicket) {
        return null
      }
      const key = this.ticket.priority.toUpperCase();
      return this.$t(`TICKET.FORM.PRIORITY.OPTIONS.${key}`)
    },

    className() {
      return this.showMore ? 'description-complete' : 'description';
    },
    isLong() {
      return this.conversation?.group?.description.length > 450;
    },
    descriptionFormatter() {
      if (this.conversation?.group?.description) {
        const formattedText = this.whatsappFormatter(
          this.conversation?.group?.description
        );
        return this.formatMessage(formattedText);
      }
      return '';
    },
    isGroup() {
      if (this?.conversation?.group) {
        return true;
      }
      return false;
    },
    hasDescription() {
      if (this?.conversation?.group?.description) {
        return true;
      }
      return false;
    },
    conversationAttributes() {
      return this.conversation;
    },
    contactConversations() {
      const currentId = this.currentUser?.id;
      const currentConversations = this.$store.getters[
        'contactConversations/getContactConversation'
      ](
        this.contact?.id,
        currentId,
        this.isLimitedAgent,
        this.isPermissionSeeAllTeamConversation
      );
      return currentConversations;
    },
    inbox() {
      if (this.inboxes.length === 1) return this.inboxes[0];

      return false;
    },
    currentInboxId() {
      if (this.inbox) return this.inbox.id;

      return this.$route.params.inboxId;
    },
    contactProfileLink() {
      return `/app/accounts/${this.$route.params.accountId}/contacts/${this.contact.id}`;
    },
    additionalAttributes() {
      return this.contact.additional_attributes || {};
    },
    openConversations() {
      if (this.currentInboxId) {
        return this.contactConversations.filter(
          conversation =>
            conversation.inbox_id === this.currentInboxId &&
            conversation.status !== 'resolved' &&
            conversation?.meta?.channel !== 'Channel::Internal'
        );
      }

      return this.contactConversations.filter(
        conversation => conversation.status !== 'resolved'
      );
    },
    location() {
      const {
        country = '',
        city = '',
        country_code: countryCode,
      } = this.additionalAttributes;
      const cityAndCountry = [city, country].filter(item => !!item).join(', ');

      if (!cityAndCountry) {
        return '';
      }
      return this.findCountryFlag(countryCode, cityAndCountry);
    },
    socialProfiles() {
      const {
        social_profiles: socialProfiles,
        screen_name: twitterScreenName,
      } = this.additionalAttributes;

      return { twitter: twitterScreenName, ...(socialProfiles || {}) };
    },
    // Delete Modal
    confirmDeleteMessage() {
      return ` ${this.contact.name}?`;
    },

    confirmDeleteMessageText() {
      return `#${this.ticket.id} ${this.ticket.text}?`;
    },

    permissionDeleteContact() {
      return this.currentUser.permissions.delete_contact;
    },
  },

  watch: {
    $route: {
      handler() {
        this.fetchDataGroup();
      },
    },
  },
  beforeMount() {
    this.fetchData();
    this.fetchDataGroup();
  },
  methods: {

    setNewLabels(newLabels) {
      this.selectedLabels = newLabels;
    },

    toggleLabelDropdown() {
      this.showLabelDropdown = !this.showLabelDropdown;
    },


    async saveLabels() {
      const labelIds = this.selectedLabels.map(label => label.id);
      this.ticket.labels_ids = labelIds;
      await this.$store.dispatch('updateTicket', this.ticket);
      this.toggleLabelDropdown();
    },

    toggleUpdateTicketModal() {
      this.showUpdateTicket = !this.showUpdateTicket;
    },
    updateTicket(updatedTicket) {
      this.ticket = { ...this.ticket, ...updatedTicket };
      this.$emit('updateTicket', this.ticket);
    },
    changePriority() {
      this.showTaskPriorityDropdown = !this.showTaskPriorityDropdown;
      this.showTaskPriority = !this.showTaskPriority;
      this.$nextTick(() => {
        const input = this.$refs.vueDropdown.$el.querySelector("input");
        input.focus();
      });
    },
    async setNewPriority(e) {
      this.showTaskPriorityDropdown = !this.showTaskPriorityDropdown;
      this.showTaskPriority = !this.showTaskPriority;
      await this.$store.dispatch('updateTicket', this.ticket);
    },

    async fetchData() {
      await this.$store.dispatch('contactConversations/get', this.contact.id);
      await this.$store.dispatch('fetchKanbanfunnels');
    },

    async fetchDataGroup() {
      if (this.conversation?.group) {
        const group = this.conversation?.group;
        const payload = {
          groupId: group.id,
          accountId: group.account_id,
          page: 1,
        };
        await this.$store.dispatch('fetchGroupParticipants', payload);
      }
    },

    whatsappFormatter(text) {
      const {
        ITALIC_PATTERN,
        ITALIC_CHARACTER,
        BOLD_PATTERN,
        BOLD_CHARACTER,
        ITALIC_BOLD_PATTERN,
        ITALIC_BOLD_CHARACTER,
        ITALIC_BOLD_CHARACTER_REVERSE,
      } = wootConstants.WHATSAPP_FORMATTER_REVERSE;
      text = text.replace(
        ITALIC_BOLD_PATTERN,
        `${ITALIC_BOLD_CHARACTER}$1${ITALIC_BOLD_CHARACTER_REVERSE}`
      );
      text = text.replace(BOLD_PATTERN, `${BOLD_CHARACTER}$1${BOLD_CHARACTER}`);
      text = text.replace(
        ITALIC_PATTERN,
        `${ITALIC_CHARACTER}$1${ITALIC_CHARACTER}`
      );
      return text;
    },
    closeConversationModal() {
      this.toggleConversationModal();

      this.$emit('toggle-contacts-view');
    },
    toggleAddNewParticipantGroup() {
      this.showAddNewParticipant = !this.showAddNewParticipant;
    },
    toggleParticipantsGroups() {
      this.showGroupParticipants = !this.showGroupParticipants;
    },
    toggleMergeModal() {
      this.showMergeModal = !this.showMergeModal;
    },
    toggleEditModal() {
      this.showEditModal = !this.showEditModal;
    },
    toggleNewTicketModal() {
      this.showNewTicket = !this.showNewTicket;
    },
    onPanelToggle() {
      this.$emit('toggle-panel');
    },
    isSingleNonOfficialWhatsappInbox() {
      return (
        this.inbox &&
        this.inbox?.is_whatsapp &&
        this.acceptChannelTypes.includes(this.inbox?.channel_type)
      );
    },
    handleDescription() {
      this.showMore = !this.showMore;
    },
    handleConversations() {
      if (this.isSingleNonOfficialWhatsappInbox()) {
        switch (this.openConversations.length) {
          case 0:
            return this.createConversation({
              inboxId: this.inbox.id,
              contactId: this.contact.id,
              assigneeId: this.currentUser.id,
            });
          case 1:
            return this.showAlert(
              this.$t('CONTACT_PANEL.ALREADY_OPEN_CONVERSATION')
            );
          default:
            return this.showAlert(
              this.$t('CONTACT_PANEL.ALREADY_OPEN_CONVERSATIONS')
            );
        }
      } else {
        this.toggleConversationModal();
        return undefined;
      }
    },
    toggleConversationModal() {
      this.showConversationModal = !this.showConversationModal;
    },
    toggleDeleteModal() {
      this.showDeleteModal = !this.showDeleteModal;
    },

    toggleDeleteTicketModal() {
      this.showDeleteTicketModal = !this.showDeleteTicketModal;
    },
    toggleContactsModal() {
      this.$emit('toggle-contacts-view');
    },
    sendContact() {
      this.toggleContact(this.contact);
      this.onSend();
      this.$emit('toggle-contacts-view');
      this.closeDelete();
    },
    confirmDeletion() {
      this.deleteContact(this.contact);
      this.closeDelete();
    },
    confirmDeletionTicket() {
      this.$store
        .dispatch('deleteTicket', this.ticket.id)
        .then(() => {
          this.showAlert(this.$t('TICKET.DELETE.API.SUCCESS_MESSAGE'));
          this.$emit('close-modal-ticket', true);
        })
        .catch(error => {
          const errorMessage = error?.message || this.$t('TICKET.DELETE.API.ERROR_MESSAGE');
          this.showAlert(errorMessage);
        });
    },

    closeDeleteTicket() {
      this.showDeleteTicketModal = false
      this.showConversationModal = false;
      this.showEditModal = false;

    },

    closeDelete() {
      this.showDeleteModal = false;
      this.showConversationModal = false;
      this.showEditModal = false;
    },
    findCountryFlag(countryCode, cityAndCountry) {
      try {
        const countryFlag = countryCode ? getCountryFlag(countryCode) : '🌎';
        return `${cityAndCountry} ${countryFlag}`;
      } catch (error) {
        return '';
      }
    },
    async createConversation(payload) {
      try {
        const data = await this.onSubmit(payload);
        this.toggleContactsModal();

        this.$router.push(
          `/app/accounts/${data.account_id}/conversations/${data.id}`
        );
      } catch (error) {
        if (error instanceof ExceptionWithMessage) {
          this.showAlert(error.data);
        } else {
          this.showAlert(this.$t('NEW_CONVERSATION.FORM.ERROR_MESSAGE'));
        }
      }
    },
    async onSubmit(contactItem) {
      const data = await this.$store.dispatch(
        'contactConversations/create',
        contactItem
      );
      return data;
    },
    async deleteContact({ id }) {
      try {
        await this.$store.dispatch('contacts/delete', id);
        this.$emit('panel-close');
        this.showAlert(this.$t('DELETE_CONTACT.API.SUCCESS_MESSAGE'));
        if (this.$route.name !== 'contacts_dashboard') {
          this.$router.push({ name: 'contacts_dashboard' });
        }
      } catch (error) {
        this.showAlert(
          error.message
            ? error.message
            : this.$t('DELETE_CONTACT.API.ERROR_MESSAGE')
        );
      }
    },
    openMergeModal() {
      this.toggleMergeModal();
    },
  },
};
</script>

<style scoped lang="scss">
.option-ticket {
  margin-top: 2rem;
}

.card-select {
  width: 70%;
}

.description {
  max-height: 29vh;
  overflow-y: hidden;
}

.description-complete {
  max-height: 100vh;
}

.description-size {
  color: #07c4dc;
  cursor: pointer;
  width: fit-content;
}

.description-size:hover {
  text-decoration: underline;
}

.contact--profile {
  position: relative;
  align-items: flex-start;
  padding: var(--space-normal);
}

.contact--details {
  margin-top: var(--space-small);
  width: 100%;
}

.contact--info {
  text-align: left;
}

.contact-info--header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.contact--name-wrap {
  display: flex;
  align-items: center;
  margin-bottom: var(--space-small);
}

.contact--name {
  text-transform: capitalize;
  white-space: normal;
  margin: 0 var(--space-smaller) 0 var(--space-smaller);

  a {
    color: var(--color-body);
  }
}

.contact--metadata {
  margin-bottom: var(--space-slab);
}

.contact-actions {
  margin-top: var(--space-small);
}

.contact-actions {
  display: flex;
  align-items: center;
  width: 100%;

  .new-message,
  .edit-contact,
  .merge-contact,
  .send-contact,
  .participants-group,
  .delete-contact {
    margin-right: var(--space-small);
  }
}

.merge-summary--card {
  padding: var(--space-normal);
}

.contact--bio {
  word-wrap: break-word;
}

.button--contact-menu {
  position: absolute;
  right: var(--space-normal);
  top: 0;
}
</style>
