
<template>
  <div>
    <h2 class="form-title">
      {{ $t("INTEGRATION_APPS.LIST.KNOWLEDGE_BASE.TITLE") }}
    </h2>
    <woot-button
      color-scheme="success"
      icon="add-circle"
      @click="openCreateModal"
    >
      {{ $t('INTEGRATION_APPS.ADD_BUTTON') }}
    </woot-button>
    <div v-if="stateKnowledgeBase">
      <table class="woot-table">
        <thead>
          <th v-for="knowledgeBaseHeader in knowledgeBaseHeaders" :key="knowledgeBaseHeader.id">
            {{ knowledgeBaseHeader }}
          </th>
        </thead>
        <tbody>
          <tr v-for="knowledgeBase in stateKnowledgeBase" :key="knowledgeBase.id">
            <td :key="knowledgeBase.id" class="hook-item">
              {{ knowledgeBase.title }}
            </td>
            <td class="hook-item">
              {{ formattedBaseAIText(knowledgeBase.baseAI) }}
            </td>
            <td class="button-wrapper">
              <woot-button
                v-tooltip.top="$t('INTEGRATION_APPS.LIST.DELETE.BUTTON_TEXT')"
                variant="smooth"
                color-scheme="alert"
                size="tiny"
                icon="edit"
                class-names="success"
                @click="openEditModal(knowledgeBase)"
              />
              <woot-button
                v-tooltip.top="$t('INTEGRATION_APPS.LIST.DELETE.BUTTON_TEXT')"
                variant="smooth"
                color-scheme="alert"
                size="tiny"
                icon="dismiss-circle"
                class-names="grey-btn"
                @click="openDeleteModal(knowledgeBase)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p v-else class="no-items-error-message">
      {{ $t('INTEGRATION_APPS.LIST.KNOWLEDGE_BASE.EMPTY') }}
    </p>
    <woot-modal :show.sync="showCreateModal" :on-close="closeCreateModal" :persistent="true" size="medium">
      <knowledge-base-add
        class="custom-modal-size"
        :hook-id="hook.id"
        :base-ai-used-characters="hook.total_baseai_characters"
        @on-close="closeCreateModal"
      >
      </knowledge-base-add>
    </woot-modal>

    <woot-modal :show.sync="showEditModal" :on-close="closeEditModal" :persistent="true" size="medium">
      <knowledge-base-edit
        class="custom-modal-size"
        :knowledge-base="knowledgeBaseSelected"
        :base-ai-used-characters="hook.total_baseai_characters"
        @on-close="closeEditModal"
      >
      </knowledge-base-edit>
    </woot-modal>

    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="this.$t('INTEGRATION_APPS.DELETE.TITLE.INBOX')"
      :message="this.$t('INTEGRATION_APPS.DELETE.MESSAGE.INBOX')"
      :confirm-text="this.$t('INTEGRATION_APPS.DELETE.CONFIRM_BUTTON_TEXT.INBOX')"
      :reject-text="this.$t('INTEGRATION_APPS.DELETE.CANCEL_BUTTON_TEXT')"
    />
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import KnowledgeBaseEdit from "./KnowledgeBaseEdit.vue";
import KnowledgeBaseAdd from "./KnowledgeBaseAdd.vue";
export default {
  components: {
    KnowledgeBaseEdit,
    KnowledgeBaseAdd,
  },
  mixins: [alertMixin],
  props: {
    hook: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      title: "",
      message: "",
      max: 10000,
      showForm: false,
      originalBaseAI: {},
      changedValue: null,
      updateKnowledge: {},
      knowledgeBaseHeaders: [
        "Title",
        "BaseAI",
      ],
      knowledgeBaseSelected: null,
      showEditModal: false,
      showCreateModal: false,
      remainingCharacters: 0,
      showDeleteConfirmationPopup: false,
    };
  },
  computed: {
    payload() {
      return {
        title: this.title || "quote",
        baseAI: this.message,
        ai_input_type: "text",
        integrationId: this.inboxId,
      };
    },
    remainingCharactersInitial() {
      return Math.max(this.max - this.charactersUsed - this.message.length, 0);
    },
    stateKnowledgeBase() {
      return this.$store.getters["integrations/getKnowledgeBaseData"](this.hook.id);
    },
    isChanged() {
      return item => this.originalBaseAI[item.id] !== item.baseAI;
    },
  },
  methods: {
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    formattedBaseAIText(baseAI) {
      if (baseAI.length > 30) {
        return baseAI.substring(0, 30) + "...";
      } else {
        return baseAI;
      }
    },
    openCreateModal() {
      this.showCreateModal = true;
    },
    closeCreateModal() {
      this.showCreateModal = false;
    },
    openEditModal(selected) {
      this.knowledgeBaseSelected = selected;
      this.showEditModal = true;
    },
    openDeleteModal(selected) {
      this.knowledgeBaseSelected = selected;
      this.showDeleteConfirmationPopup = true;
    },
    closeEditModal() {
      this.showEditModal = false;
    },
    onSendListMessage(payload) {
      this.$emit("on-submit", payload);
      this.closeEditModal();
    },
    submitAndClose() {
      this.$emit("on-submit", this.payload);
      this.resetForm()
      this.updateData();
      this.updateTotalCharacters();
    },
    resetForm() {
      this.title = '';
      this.message = '';
      this.showForm = false;
    },
    updateKnowledgeBaseData() {
      let updateKnowledge = {
        baseAI: this.changedValue,
        id: this.inboxId,
      }
      return updateKnowledge;
    },
    handleChanges(item) {
      this.changedValue = item.baseAI;
    },
    openForm() {
      this.showForm = true;
    },
    async confirmDeletion() {
      try {
        await this.$store.dispatch('integrations/deleteKnowledgeBase', this.knowledgeBaseSelected);
        this.alertMessage = this.$t(
          'INTEGRATION_APPS.DELETE.API.SUCCESS_MESSAGE'
        );
        this.closeDeletePopup();
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        this.alertMessage =
          errorMessage || this.$t('INTEGRATION_APPS.DELETE.API.ERROR_MESSAGE');
      } finally {
        this.showAlert(this.alertMessage);
      }
    },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
}

.form-title {
  margin-top: 1rem;
}

.form-container {
  width: 100%;
}

.small-input {
  width: 35%;
}

.total-characters {
  font-size: 1.5rem;
}

.buttons {
  margin-top: 1rem;
}

.fixed-size {
  width: 90%;
  /* adjust as needed */
}

.ul-display {
  width: 95%;
  padding: 1rem;
  margin: 2rem;
}

.list-container {
  margin-top: 2rem;
  margin-bottom: 3rem;
}
</style>
