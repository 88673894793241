<style lang="scss" scoped>
.tour-preview {
  width: 100%;
  height: 100%;
  background: #fff;
  transition: all 0.2s;
  .step {
    height: 100%;
  }
  .teaser {
     //height: 560px;
    img {
      width: 100%;
      height: 100%;
      //object-fit: scale-down;
    }
  }
  .content {
    // padding: 32px 40px 40px 40px;
    .title {
      font-family: inherit;
      font-size: 24px;
      font-weight: 700;
      line-height: 40px;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
    .description {
      color: #6e6e6e;
      font-family: inherit;
      font-size: 15px;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 0px;
      margin-top: 0px;
    }
    .footer {
      margin-top: auto;
      display: flex;
      flex-wrap: wrap;
      .footer-dots {
        margin: auto 0;
        display: flex;
        flex-wrap: wrap;
        .dot {
          border-radius: 50%;
          width: 6px;
          height: 6px;
          margin-right: 7px;
        }
      }
      .footer-link {
        color: #6e6e6e;
        margin: auto 15px auto auto;
        cursor: pointer;
        transition: 0.2s opacity;
        user-select: none;
        &:hover {
          opacity: 0.7;
        }
      }
      .footer-btn {
        color: #fff;
        padding: 10px 15px;
        cursor: pointer;
        display: inline-block;
        transition: 0.2s opacity;
        user-select: none;
        &:hover {
          opacity: 0.7;
        }
        &.ml-auto {
          margin-left: auto;
        }
      }
    }
  }
}
.tour-popover {
  position: fixed;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.4);
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  display: flex;
  cursor: pointer;
}
</style>

<style lang="scss">
.tour-blurred {
  filter: blur(4px);
}
</style>


<template>
  <div v-if="open">
    <div v-if="scaled" @click="scale" class="tour-popover"></div>
    <div
      class="tour-preview"
      :style="{ 'border-radius': theme.radius }"
      :class="{ 'is-scaled': scaled }"
    >
      <template v-for="(step, key) in steps">
        <div v-if="currentStep === key" class="step" :key="key">
          <div
            class="teaser"
            :style="{
              'border-top-left-radius': theme.radius,
              'border-top-right-radius': theme.radius,
            }"
          >
            <img
              v-if="typeFile == 'img-gif'"
              :src="step.preview"
              :alt="step.title"
              :style="{
                'border-top-left-radius': theme.radius,
                'border-top-right-radius': theme.radius,
              }"
            />
            <video
              v-else
              :src="step.preview"
              controls
              playsInline
              class="modal-video skip-context-menu"
            />
          </div>
          <div class="content">
            <p class="title" :style="{ color: theme.color }">
              {{ step.title }}
            </p>
            <p class="description" v-html="step.description"></p>
            <slot v-if="scaled" :name="`step-${key + 1}`"></slot>
            <div class="footer">
              <div class="footer-dots">
                <div
                  v-for="dot in stepCount"
                  :key="dot"
                  class="dot"
                  :style="{
                    background:
                      dot - 1 === currentStep ? theme.color : '#e6eaee',
                  }"
                ></div>
              </div>
              <div v-if="currentStep > 0" class="footer-link" @click="prev()">
                {{ step.prev_cta ? step.prev_cta : text.prev_cta }}
              </div>
              <div
                class="footer-btn"
                :class="{ 'ml-auto': currentStep === 0 }"
                @click="next()"
                :style="{
                  background: theme.color,
                  'border-radius': theme.radius,
                }"
              >
                {{
                  currentStep !== steps.length - 1
                    ? step.next_cta
                      ? step.next_cta
                      : text.next_cta
                    : text.restart_cta
                }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TourComponent',
  props: {
    steps: {
      type: Array,
      default: () => {
        return [];
      },
    },

    text: {
      type: Object,
      default: () => {
        return {
          next_cta: '',
          prev_cta: '',
          restart_cta: '',
        };
      },
    },

    theme: {
      type: Object,
      default: () => {
        return {
          color: '#01c6d8',
          radius: '2px',
        };
      },
    },

    blurEl: {
      required: false,
      type: String,
      default: '.can-tour-blur',
    },

    debug: {
      type: Boolean,
      default: false,
    },

    storage: {
      type: String,
      default: 'vue-tour-viewed',
    },

    startLarge: {
      type: Boolean,
      default: false,
    },
    typeFile: {
      type: String,
      default: 'img-gif',
    },
  },

  data: () => ({
    currentStep: 0,
    open: true,
    scaled: false,
  }),

  methods: {
    next() {
      if (this.currentStep == this.steps.length - 1) {
        this.$emit('close');
        return;
      }
      if (this.currentStep < this.steps.length - 1) {
        this.currentStep++;
      } else {
        this.currentStep = 0;
      }
    },

    prev() {
      if (this.currentStep > 0) this.currentStep--;
      else this.currentStep = this.steps.length - 1;
    },

    close() {
      this.open = false;
      document.querySelector(this.blurEl).classList.remove('tour-blurred');
      localStorage.setItem('vue-tour-viewed', true);
    },

    scale() {
      this.scaled = !this.scaled;
      if (this.scaled)
        document.querySelector(this.blurEl).classList.add('tour-blurred');
      else document.querySelector(this.blurEl).classList.remove('tour-blurred');
    },

    getDesc(text) {
      let trim_count = 150;

      return text.length > trim_count && !this.scaled
        ? text.replace(/(<([^>]+)>)/gi, '').substring(0, trim_count) + '...'
        : text;
    },
  },

  computed: {
    stepCount() {
      return this.steps.length;
    },
  },
};
</script>