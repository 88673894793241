var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.open)?_c('div',[(_vm.scaled)?_c('div',{staticClass:"tour-popover",on:{"click":_vm.scale}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"tour-preview",class:{ 'is-scaled': _vm.scaled },style:({ 'border-radius': _vm.theme.radius })},[_vm._l((_vm.steps),function(step,key){return [(_vm.currentStep === key)?_c('div',{key:key,staticClass:"step"},[_c('div',{staticClass:"teaser",style:({
            'border-top-left-radius': _vm.theme.radius,
            'border-top-right-radius': _vm.theme.radius,
          })},[(_vm.typeFile == 'img-gif')?_c('img',{style:({
              'border-top-left-radius': _vm.theme.radius,
              'border-top-right-radius': _vm.theme.radius,
            }),attrs:{"src":step.preview,"alt":step.title}}):_c('video',{staticClass:"modal-video skip-context-menu",attrs:{"src":step.preview,"controls":"","playsInline":""}})]),_vm._v(" "),_c('div',{staticClass:"content"},[_c('p',{staticClass:"title",style:({ color: _vm.theme.color })},[_vm._v("\n            "+_vm._s(step.title)+"\n          ")]),_vm._v(" "),_c('p',{staticClass:"description",domProps:{"innerHTML":_vm._s(step.description)}}),_vm._v(" "),(_vm.scaled)?_vm._t(("step-" + (key + 1))):_vm._e(),_vm._v(" "),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"footer-dots"},_vm._l((_vm.stepCount),function(dot){return _c('div',{key:dot,staticClass:"dot",style:({
                  background:
                    dot - 1 === _vm.currentStep ? _vm.theme.color : '#e6eaee',
                })})}),0),_vm._v(" "),(_vm.currentStep > 0)?_c('div',{staticClass:"footer-link",on:{"click":function($event){return _vm.prev()}}},[_vm._v("\n              "+_vm._s(step.prev_cta ? step.prev_cta : _vm.text.prev_cta)+"\n            ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"footer-btn",class:{ 'ml-auto': _vm.currentStep === 0 },style:({
                background: _vm.theme.color,
                'border-radius': _vm.theme.radius,
              }),on:{"click":function($event){return _vm.next()}}},[_vm._v("\n              "+_vm._s(_vm.currentStep !== _vm.steps.length - 1
                  ? step.next_cta
                    ? step.next_cta
                    : _vm.text.next_cta
                  : _vm.text.restart_cta)+"\n            ")])])],2)]):_vm._e()]})],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }