<template>
  <div v-if="hasSecondaryMenu" class="main-nav secondary-menu">
    <account-context @toggle-accounts="toggleAccountModal" />
    <woot-button
      v-if="verifyButtonContactsView"
      class="new-conversation new-conversation-button"
      type="button"
      size="small"
      @click="toggleContactModal"
    >
      {{ $t('SIDEBAR.NEW_CONVERSATION_BUTTON') }}
    </woot-button>
    <transition-group name="menu-list" tag="ul" class="menu vertical">
      <secondary-nav-item
        v-for="menuItem in accessibleMenuItems"
        :key="menuItem.toState"
        :menu-item="menuItem"
      />
      <secondary-nav-item
        v-for="menuItem in additionalSecondaryMenuItems[menuConfig.parentNav]"
        :key="menuItem.key"
        :menu-item="menuItem"
        @add-label="showAddLabelPopup"
      />
    </transition-group>
  </div>
</template>
<script>
import { frontendURL } from '../../../helper/URLHelper';
import SecondaryNavItem from './SecondaryNavItem.vue';
import AccountContext from './AccountContext.vue';
import { mapGetters } from 'vuex';
import { FEATURE_FLAGS } from '../../../featureFlags';

export default {
  components: {
    AccountContext,
    SecondaryNavItem,
  },
  props: {
    accountId: {
      type: Number,
      default: 0,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    inboxes: {
      type: Array,
      default: () => [],
    },
    teams: {
      type: Array,
      default: () => [],
    },
    customViews: {
      type: Array,
      default: () => [],
    },
    menuConfig: {
      type: Object,
      default: () => {},
    },
    currentRole: {
      type: String,
      default: '',
    },
    isOnChatwootCloud: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
      currentUser: 'getCurrentUser',
      currentAccountId: 'getCurrentAccountId',
      getAccount: 'accounts/getAccount'
    }),
    hasSecondaryMenu() {
      return this.menuConfig.menuItems && this.menuConfig.menuItems.length;
    },
    contactCustomViews() {
      return this.customViews.filter(view => view.filter_type === 'contact');
    },
    accessibleMenuItems() {
      if (!this.currentRole) {
        return [];
      }
      const menuItemsFilteredByRole = this.menuConfig.menuItems.filter(
        menuItem =>
          window.roleWiseRoutes[this.currentRole].indexOf(
            menuItem.toStateName
          ) > -1
      );

      const wallet_contacts_menu = menuItemsFilteredByRole.find(
        item => item.label === 'WALLET_CONTACTS'
      );
      if (wallet_contacts_menu && !this.currentUser.wallet.status) {
        menuItemsFilteredByRole.pop();
      }

      const account = this.getAccount(this.currentAccountId);
      const menuList = menuItemsFilteredByRole.filter(item => {
        if(item.toStateName == 'crm_settings' && !account.enable_crm){
          return false;          
        }

        if (item.showOnlyOnCloud) {
          return this.isOnChatwootCloud;
        }
        return true;
      });

      
      if (this.currentUser.type_role == 'supervisor') {
        return this.supervisorPermissions(menuList);
      }
      return menuList;
    },
    inboxSection() {
      return {
        icon: 'folder',
        label: 'INBOXES',
        hasSubMenu: true,
        newLink: this.showNewLink(FEATURE_FLAGS.INBOX_MANAGEMENT),
        newLinkTag: 'NEW_INBOX',
        key: 'inbox',
        toState: frontendURL(`accounts/${this.accountId}/settings/inboxes/new`),
        toStateName: 'settings_inbox_new',
        newLinkRouteName: 'settings_inbox_new',
        children: this.inboxes
          .map(inbox => ({
            id: inbox.id,
            label: inbox.name,
            truncateLabel: true,
            toState: frontendURL(
              `accounts/${this.accountId}/inbox/${inbox.id}`
            ),
            type: inbox.channel_type,
            phoneNumber: inbox.phone_number,
            reauthorizationRequired: inbox.reauthorization_required,
            isWhatsapp: inbox.is_whatsapp,
            connected: inbox.connected,
          }))
          .sort((a, b) =>
            a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1
          ),
      };
    },
    labelSection() {
      return {
        icon: 'number-symbol',
        label: 'LABELS',
        hasSubMenu: true,
        newLink: this.showNewLink(FEATURE_FLAGS.TEAM_MANAGEMENT),
        newLinkTag: 'NEW_LABEL',
        key: 'label',
        toState: frontendURL(`accounts/${this.accountId}/settings/labels`),
        toStateName: 'labels_list',
        showModalForNewItem: true,
        modalName: 'AddLabel',
        children: this.labels.map(label => ({
          id: label.id,
          label: label.title,
          color: label.color,
          truncateLabel: true,
          toState: frontendURL(
            `accounts/${this.accountId}/label/${label.title}`
          ),
        })),
      };
    },
    contactLabelSection() {
      return {
        icon: 'number-symbol',
        label: 'TAGGED_WITH',
        hasSubMenu: true,
        key: 'label',
        newLink: this.showNewLink(FEATURE_FLAGS.TEAM_MANAGEMENT),
        newLinkTag: 'NEW_LABEL',
        toState: frontendURL(`accounts/${this.accountId}/settings/labels`),
        toStateName: 'labels_list',
        showModalForNewItem: true,
        modalName: 'AddLabel',
        children: this.labels.map(label => ({
          id: label.id,
          label: label.title,
          color: label.color,
          truncateLabel: true,
          toState: frontendURL(
            `accounts/${this.accountId}/labels/${label.title}/contacts`
          ),
        })),
      };
    },
    teamSection() {
      return {
        icon: 'people-team',
        label: 'TEAMS',
        hasSubMenu: true,
        newLink: this.showNewLink(FEATURE_FLAGS.TEAM_MANAGEMENT),
        newLinkTag: 'NEW_TEAM',
        key: 'team',
        toState: frontendURL(`accounts/${this.accountId}/settings/teams/new`),
        toStateName: 'settings_teams_new',
        newLinkRouteName: 'settings_teams_new',
        children: this.teams.map(team => ({
          id: team.id,
          label: team.name,
          truncateLabel: true,
          toState: frontendURL(`accounts/${this.accountId}/team/${team.id}`),
        })),
      };
    },
    foldersSection() {
      return {
        icon: 'folder',
        label: 'CUSTOM_VIEWS_FOLDER',
        hasSubMenu: true,
        key: 'custom_view',
        children: this.customViews
          .filter(view => view.filter_type === 'conversation')
          .map(view => ({
            id: view.id,
            label: view.name,
            truncateLabel: true,
            toState: frontendURL(
              `accounts/${this.accountId}/custom_view/${view.id}`
            ),
          })),
      };
    },
    contactSegmentsSection() {
      return {
        icon: 'folder',
        label: 'CUSTOM_VIEWS_SEGMENTS',
        hasSubMenu: true,
        key: 'custom_view',
        children: this.customViews
          .filter(view => view.filter_type === 'contact')
          .map(view => ({
            id: view.id,
            label: view.name,
            truncateLabel: true,
            toState: frontendURL(
              `accounts/${this.accountId}/contacts/custom_view/${view.id}`
            ),
          })),
      };
    },
    additionalSecondaryMenuItems() {
      let conversationMenuItems = [this.inboxSection, this.labelSection];
      let contactMenuItems = [this.contactLabelSection];
      if (this.teams.length) {
        conversationMenuItems = [this.teamSection, ...conversationMenuItems];
      }
      if (this.customViews.length) {
        conversationMenuItems = [this.foldersSection, ...conversationMenuItems];
      }
      if (this.contactCustomViews.length) {
        contactMenuItems = [this.contactSegmentsSection, ...contactMenuItems];
      }
      return {
        conversations: conversationMenuItems,
        contacts: contactMenuItems,
      };
    },
    verifyButtonContactsView() {
      const routes = [
        'home',
        'inbox_dashboard',
        'inbox_conversation',
        'conversation_mentions',
        'conversation_unattended',
        'label_conversations',
        'team_conversations',
      ];
      return routes.includes(this.$route.name);
    },
  },
  methods: {
    supervisorPermissions(list) {
      const account = this.getAccount(this.currentAccountId);
      const user = this.currentUser.permissions;
      const listPermissions = list.filter(page => {
        switch (page.toStateName) {
          case 'agent_list':
            return user.agent_settings;
          case 'settings_teams_list':
            return user.team_settings;
          case 'settings_inbox_list':
            return user.inboxes;
          case 'labels_list':
            return user.markers_settings;
          case 'attributes_list':
            return user.custom_attributes_settings;
          case 'automation_list':
            return user.automations_settings;
          case 'macros_wrapper':
            return user.macros_settings;
          case 'settings_integrations':
            return user.integrations;
          case 'settings_applications':
            return user.applications;
          case 'canned_list':
            return user.shortcuts_settings;
          case 'auditlogs_list':
            return user.audit_records;
          case 'crm_settings':
            return user.crm_settings && account?.enable_crm;
          default:
            return page;
        }
      });
      return listPermissions;
    },
    showAddLabelPopup() {
      this.$emit('add-label');
    },
    toggleAccountModal() {
      this.$emit('toggle-accounts');
    },
    toggleContactModal() {
      this.$emit('contacts-view');
    },
    onCancel() {
      this.$emit('close');
    },
    showNewLink(featureFlag) {
      return this.isFeatureEnabledonAccount(this.accountId, featureFlag);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/woot';

.secondary-menu {
  display: flex;
  flex-direction: column;
  background: var(--white);
  border-right: 1px solid var(--s-50);
  height: 100%;
  width: 20rem;
  flex-shrink: 0;
  overflow-y: hidden;
  position: unset;

  &:hover {
    overflow-y: hidden;
  }

  .new-conversation-button {
    border-radius: var(--border-radius-normal);
    margin: var(--space-small);
    background: var(--g-20) !important;
    &:hover {
      background: var(--g-30) !important;
    }
  }

  .menu {
    padding: var(--space-small);
    overflow-y: auto;
  }
}
</style>
