/* eslint arrow-body-style: 0 */
import KanbanView from './components/KanbanView.vue';
import { frontendURL } from '../../../helper/URLHelper';
import ListFunnelView from './components/ListFunnelView.vue';

export const routes = [
  {
    path: frontendURL('accounts/:accountId/crm'),
    name: 'crm',
    roles: ['administrator', 'agent'],
    component: KanbanView,
  },
  // {
  //   path: frontendURL('accounts/:accountId/crm/funnel'),
  //   name: 'funnel',
  //   roles: ['administrator', 'agent'],
  //   component: ListFunnelView,
  // },
];
