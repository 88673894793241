import ApiClient from './ApiClient';

class GroupsApi extends ApiClient {
    constructor() {
        super('', { accountScoped: true });
    }

    async getParticipants({ groupId, page = 1, per_page = 10}) {
        return await axios.get(`${this.url}group_participants?group_id=${groupId}&page=${page}&per_page=${per_page}`);
    }
    manageParticipants(url,jid,payload){
        let requestURL = `${url}api/v1/sessions/group-management/${jid}`
        return axios.put(requestURL,payload)
    }

}

export default new GroupsApi();