import types from '../mutation-types';
import kanbanfunnelApi from '../../api/kanbanfunnel';

const state = {
    kanbanfunnels: [],
};

export const getters = {
    getKanbanfunnels(_state) {
      return _state.kanbanfunnels;
    },
};

export const actions = {
    async fetchKanbanfunnels({ commit }){
        try {
            const response = await kanbanfunnelApi.getKanbanfunnels();
            commit(types.SET_KANBANFUNNELS, response.data);
            return response.data
        } catch (err) {
            throw new Error(err);
        }
    },

    async createKanbanfunnel({ commit }, payload){
        try {
            const response = await kanbanfunnelApi.createKanbanfunnels(payload)
            commit(types.ADD_KANBANFUNNEL, response.data);
            return response;
        } catch (err) {
            throw new Error(err);
        }
    },

    async deleteKanbanfunnel({ commit }, payload){
        try {
            await kanbanfunnelApi.deleteKanbanfunnels(payload)
            commit(types.REMOVE_KANBANFUNNEL, payload);
        } catch (err) {
            throw new Error(err);
        }
    },

    async updateKanbanfunnel({ commit }, payload){
        try {
            await kanbanfunnelApi.updateKanbanfunnels(payload?.id, payload);
            commit(types.UPDATE_KANBANFUNNEL, payload);
        } catch (err) {
            throw new Error(err);
        }
    },


}

export const mutations = {
    [types.SET_KANBANFUNNELS](state, data) {
      state.kanbanfunnels = data;
    },

    [types.ADD_KANBANFUNNEL](state, kanbanfunnel) {
        state.kanbanfunnels.push(kanbanfunnel);
    },

    [types.REMOVE_KANBANFUNNEL](state, kanbanfunnelId) {
        state.kanbanfunnels = state.kanbanfunnels.filter(kf => kf.id !== kanbanfunnelId);
    },

    [types.UPDATE_KANBANFUNNEL](state, updatedKanbanfunnel) {
        const index = state.kanbanfunnels.findIndex(
          kanbanfunnel => kanbanfunnel.id === updatedKanbanfunnel.id
        );
        if (index !== -1) {
          state.kanbanfunnels.splice(index, 1, updatedKanbanfunnel);
        }
    }      
}

export default {
    actions,
    state,
    getters,
    mutations,
};