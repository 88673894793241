<template>
  <note-list
    :is-fetching="uiFlags.isFetching"
    :notes="notes"
    @add="onAdd"
    @delete="onDelete"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import NoteList from './components/NoteList';

export default {
  components: {
    NoteList,
  },
  props: {
    contactId: {
      type: Number,
      required: true,
    },
    isTicket: {
      type: Boolean,
      default: false
    },
    ticket: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({ uiFlags: 'contactNotes/getUIFlags' }),
    notes(){
      const listNotes = this.$store.getters['contactNotes/getAllNotesByContact'](this.contactId);
      if(!this.isTicket){
        return listNotes.filter((note) => note.ticket_id == null);
      }
      return listNotes.filter((note) => note?.ticket_id == this.ticket?.id);
    },
  },
  mounted() {
    this.fetchContactNotes();
  },
  methods: {
    fetchContactNotes() {
      const { contactId } = this;
      if (contactId) this.$store.dispatch('contactNotes/get', { contactId });
    },
    onAdd(content) {
      const { contactId, isTicket, ticket } = this;
      const payload = {
        content, 
        contactId
      }

      if(isTicket){
        payload.ticket_id = ticket.id;
      }
      this.$store.dispatch('contactNotes/create', payload);
    },
    onDelete(noteId) {
      const { contactId } = this;
      this.$store.dispatch('contactNotes/delete', { noteId, contactId });
    },
  },
};
</script>
