<template>
  <form @submit.prevent="createKnowledgeBase()">
    <div class="form-container">
      <!-- Title -->
      <label>
        {{ $t("INTEGRATION_APPS.LIST.KNOWLEDGE_BASE.INPUT_TITLE") }}
        <input type="text" v-model="title" :placeholder="$t('INTEGRATION_APPS.LIST.KNOWLEDGE_BASE.INPUT_TITLE')"
          class="small-input" />
      <label>
      <!-- TextArea -->
      </label>
        {{ $t("INTEGRATION_APPS.LIST.KNOWLEDGE_BASE.INPUT_QUOTE") }}
        <textarea
          v-model="baseAI"
          :placeholder="$t('INTEGRATION_APPS.LIST.KNOWLEDGE_BASE.INPUT_QUOTE')"
          :maxlength="remainingCharacters"
          rows="5"
        />
        <p id="messageHelp" class="h2 total-characters" >
          {{ `${baseAI.length} / ${remainingCharacters}` }}
          {{ $t("INTEGRATION_APPS.LIST.KNOWLEDGE_BASE.CHARACTERS_LEFT") }}
        </p>
      </label>
      <!-- Actions -->
      <div class="buttons">
        <button :disabled="!baseAI" class="button bg-primary primary mt-2">
          <span class="button__content">
            {{ $t("INTEGRATION_APPS.ADD.FORM.CREATE") }}
          </span>
        </button>
        <button class="button clear btn-primary" @click.prevent="onClose">
          {{ $t('INTEGRATION_APPS.ADD.FORM.CANCEL') }}
        </button>
      </div>
    </div>
  </form>
</template>
<script>
import alertMixin from 'shared/mixins/alertMixin';

export default {
  props: {
    baseAiUsedCharacters: {
      type: Number,
      required: true
    },
    hookId: {
      type: Number,
      required: true
    }
  },
  mixins: [alertMixin],
  data() {
    return {
      title: "",
      baseAI: "",
      maxCharacters: 10000,
      remainingCharacters: 0,
    }
  },
  mounted() {
    this.calculateRemainingCharacters();
  },
  methods: {
    onClose() {
      this.$emit("on-close");
    },
    calculateRemainingCharacters() {
      this.remainingCharacters = this.maxCharacters - this.baseAiUsedCharacters;
    },
    
    async createKnowledgeBase() {
      try {
        await this.$store.dispatch('integrations/createKnowledgeBase', {
          title: this.title,
          baseAI: this.baseAI,
          ai_input_type: "text",
          integrationId: this.hookId,
        }).then(
          () => {
            this.alertMessage = this.$t("INTEGRATION_APPS.ADD.API.SUCCESS_MESSAGE");
            this.onClose();
          }
        );
      } catch (error) {
        throw error;
      }
    },
  },
}
</script>